import { ActivatedRoute } from '@angular/router';
import { Query, Store } from '@datorama/akita';
import { map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AbstractIdentifiable } from '../../entity-record/state/abstract-entity';
import { EntityListResponse } from './entity.list-response';
import { AbstractEntityCollectionState } from './abstract-entity-collection.state';
import { AbstractEntityCollectionDataProvider } from '../collection-data-provider/abstract-entity-collection.data-provider';
import { AbstractEntityStateService, EntityStateServiceOptions } from '../../entity-record/state/abstract-entity.state-service';
import { AbstractEntityState } from '../../entity-record/state/abstract-entity.state';
import { OnscreenMessagingService } from '@pattern-library/onscreen-messaging/onscreen-messaging.service';
import { LoggingService } from '@logging/logging.service';
import { deepClone } from '@utils/object-utils';
import { Directive, OnDestroy } from '@angular/core';

@Directive()
export abstract class AbstractEntityCollectionStateService<
    TEntity extends AbstractIdentifiable,
    TEntityCollectionState extends AbstractEntityCollectionState<TEntity>
  >
  extends AbstractEntityStateService<TEntity, AbstractEntityState<TEntity>>
  implements OnDestroy
{
  private readonly collection: Store<TEntityCollectionState>;
  private readonly collectionQuery: Query<TEntityCollectionState>;

  protected constructor(
    protected storeName: string,
    protected dataProvider: AbstractEntityCollectionDataProvider<TEntity>,
    protected route: ActivatedRoute,
    protected messaging: OnscreenMessagingService,
    protected logger: LoggingService,
    protected options: EntityStateServiceOptions = { autoFetch: true, childEntity: true, runValidatorsOnFormBind: false }
  ) {
    super(storeName, dataProvider, route, messaging, logger, undefined, options);

    this.collection = new Store<TEntityCollectionState>({}, { name: storeName });
    this.collectionQuery = new Query<TEntityCollectionState>(this.collection);

    if (options.autoFetch) {
      this.fetchEntities();
    }
  }

  edit(entity: TEntity) {
    this.entity = entity;
    this.toggleEditing(true);
  }

  private get collectionState(): TEntityCollectionState {
    return this.collectionQuery.getValue();
  }

  private set collectionState(state: TEntityCollectionState) {
    this.collection.update(deepClone(state));
  }

  protected get entities(): TEntity[] | undefined {
    return this.collectionState.entities;
  }

  protected set entities(entities: TEntity[] | undefined) {
    this.collectionState = { ...this.collectionState, entities };
  }

  fetchEntities = (params?: any): void => {
    this.setInFlight();
    this.dataProvider.readMany$(params).subscribe((response: EntityListResponse<TEntity>) => {
      const entityState = { ...this.collectionQuery.getValue(), entities: response, inFlight: false };
      this.collection.update(entityState);
      this.onFetch(response);
    });
  };

  get entities$(): Observable<TEntity[]> {
    return this.collectionQuery.select((state) => state.entities).pipe(map((entities) => (!!entities ? entities : []) as TEntity[]));
  }

  get inFlight$(): Observable<boolean> {
    return this.collectionQuery.select((state) => state.inFlight);
  }

  revertEntitySnapshot() {
    this.entity = this.emptyEntity;
    this.dataProvider.childId = undefined;
  }

  createEntitySnapshot() {}

  persist$(): Observable<any> {
    return super.persist$().pipe(
      tap(() => {
        /**
         * when an entity is added or updated, sync the collection
         */
        const entity = this.entity as TEntity;
        const entities = [...(this.entities || []).filter((e) => e.id !== entity.id)];
        entities.push(entity);
        this.entities = entities;
        this.revertEntitySnapshot();
      })
    );
  }

  setInFlight() {
    this.collection.update((state) => {
      return { ...state, inFlight: true };
    });
  }

  clearInFlight() {
    this.collection.update((state) => {
      return { ...state, inFlight: false };
    });
  }

  ngOnDestroy() {
    this.collection.destroy();
    super.ngOnDestroy();
  }
}
