import { Injectable, InjectionToken, Injector } from '@angular/core';

import Rollbar from 'rollbar';

import { Log } from '@logging/log';
import { environment } from '@env/environment';
import { UserIdentity } from '@auth-n/state/user-identity';

// see https://rollbar.com/blog/error-handling-with-angular-8-tips-and-best-practices/

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

const rollbarConfig: Rollbar.Configuration = {
  accessToken: 'f526fc6ce7f54772b71221aed9b82866',
  environment: environment.environment,
  captureUncaught: true,
  captureUnhandledRejections: true,
  autoInstrument: { log: false },
  scrubTelemetryInputs: true,
  checkIgnore: function (isUncaught, args, payload) {
    if (args.length && typeof args[1] === 'string' && (args[1] as string)?.includes('Object Not Found Matching Id')) {
      return true;
    }

    return false;
  }
};

export function rollbarFactory() {
  return new Rollbar(rollbarConfig);
}

@Injectable({ providedIn: 'root' })
export class CintraRollbarService {
  constructor(private injector: Injector) {}

  setIdentity(user: UserIdentity) {
    const rollbar = this.injector.get(RollbarService);

    const cfg: Rollbar.Configuration = {
      payload: {
        person: {
          id: user?.id,
          email: user?.email
        }
      }
    };
    rollbar.configure({ ...rollbarConfig, ...cfg });
  }

  error(error: Error, logExtras: Partial<Log>) {
    const rollbar = this.injector.get(RollbarService);
    rollbar.error(error, logExtras);
  }

  log(message: string, logExtras: Partial<Log>) {
    const rollbar = this.injector.get(RollbarService);
    rollbar.log(message, logExtras);
  }
}
