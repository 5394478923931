import { Directive, ElementRef, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[appAutoFocus]'
})
export class AutoFocusDirective implements AfterViewInit {
  constructor(private element: ElementRef) {}
  ngAfterViewInit(): void {
    setTimeout(() => {
      const el = this.element.nativeElement as Element;
      const inputs = el.querySelectorAll('input, textarea, select');
      if (inputs.length) (inputs[0] as HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement).focus();
    }, 200);
  }
}
