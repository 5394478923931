import { Injectable, OnDestroy } from '@angular/core';
import { createStore, select, setProps, withProps } from '@ngneat/elf';
import { Observable } from 'rxjs';
import { map, skipWhile } from 'rxjs/operators';
import { Step, Stepper, StepperState } from './stepper-state';

/**
 * State-management of the active {@link Stepper}
 */
@Injectable()
export class StepperStateService implements OnDestroy {
  private readonly store = createStore({ name: 'StepStateService' }, withProps({} as StepperState));

  steps$: Observable<Step[]> = this.store.pipe(
    select((state) => state.stepper),
    skipWhile((steps) => steps === undefined),
    map((stepper) => Array.from(stepper.values()))
  );

  activeStep$ = this.store.pipe(
    select((state) => state.activeStep),
    skipWhile((activeStep) => activeStep === undefined)
  );

  isFirstStep$: Observable<boolean> = this.store.pipe(select((state) => this.isFirstStep(state)));

  isLastStep$: Observable<boolean> = this.store.pipe(select((state) => this.isLastStep(state)));

  activeStepIndex$: Observable<number> = this.store.pipe(select((state) => this.getActiveStepIndex(state)));

  stepCount$: Observable<number> = this.store.pipe(select((state) => state.keys.length));

  setStepper(stepper: Stepper, activeStep: Step) {
    this.store.update(setProps({ stepper, activeStep, activeStepKey: activeStep.identifier, keys: Array.from(stepper.keys()) }));
  }

  private get stepper(): Stepper {
    return this.store.getValue().stepper;
  }

  private get keys(): string[] {
    return this.store.getValue().keys;
  }

  private get activeStepKey(): string {
    return this.store.getValue().activeStepKey;
  }

  updateActiveStep(activeStepKey: string) {
    const activeStep = this.stepper.get(activeStepKey);
    this.store.update(setProps({ activeStepKey, activeStep }));
  }

  private indexOf = (key: string): number => this.keys?.findIndex((k) => k === key);

  nextStep = (): boolean => this.goStep(1);
  previousStep = (): boolean => this.goStep(-1);

  getStep = (key: string): Step => this.stepper.get(key);

  goStep = (increment: number) => {
    const activeKey = this.activeStepKey;
    const keys = this.keys;
    const nextIndex = this.indexOf(activeKey) + increment;
    const activeStepKey = keys[nextIndex];

    if (typeof activeStepKey === typeof undefined) {
      return false;
    }

    this.updateActiveStep(activeStepKey);

    return true;
  };

  isCurrentStep = (key: string) => this.indexOf(key) == this.indexOf(this.activeStepKey);

  isVisitedStep = (key: string) => this.indexOf(key) <= this.indexOf(this.activeStepKey);

  atFirstStep = () => this.indexOf(this.activeStepKey) == 0;

  private isFirstStep(state: StepperState): boolean {
    return this.getActiveStepIndex(state) === 0;
  }

  private isLastStep(state: StepperState): boolean {
    return this.getActiveStepIndex(state) === state.stepper.size - 1;
  }

  private getActiveStepIndex(state: StepperState): number {
    const keys = Array.from(state.stepper.keys());
    return keys.findIndex((k) => k === state.activeStepKey);
  }

  ngOnDestroy(): void {
    this.store.destroy();
  }
}
