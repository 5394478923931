import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { BehaviorSubject } from 'rxjs';

import { formatBytes } from '@utils/number-utils';

export type FileInfo = { file: File; fileSize: string };

@Component({
  selector: 'app-select-local-files',
  templateUrl: './local-file-selector.component.html',
  styleUrls: ['./local-file-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LocalFileSelectorComponent {
  fileInfos: FileInfo[] = undefined;
  private subject = new BehaviorSubject<FileInfo[] | undefined>(this.fileInfos);
  filesInfos$ = this.subject.asObservable();

  @Input() prompt = 'Drop your completed Excel template file here, or';
  @Input() acceptedFileExtensions = '.xlsx';

  dropped(files: NgxFileDropEntry[]) {
    for (const droppedFile of files) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          this.fileInfos = [];
          this.fileInfos.push({
            file,
            fileSize: formatBytes(file.size)
          });
          this.subject.next(this.fileInfos);
        });
      }
    }
  }

  public fileOver(event) {}

  public fileLeave(event) {}
}
