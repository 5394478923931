import { AbstractEntityState } from '@entity-framework/entity-record/state/abstract-entity.state';
import { AbstractEntity } from '@entity-framework/entity-record/state/abstract-entity';

export interface OrganisationDetails extends AbstractEntity {
  server?: string;
  databaseName?: string;
  sqlLogin?: string;
  password?: string;
  cintraCloudFlavour?: string;
  smsProvider?: SmsProvider;
  isPrivate: boolean;
}

export interface Organisation extends OrganisationDetails {
  ossTeamEmail?: string;
  messagingEnabled: boolean;
  cintraIqAuroraEnabled: boolean;
  groupsEnabled: boolean;
  groupsMessagingEnabled: boolean;
  groupsEmployeeAutoAllocationEnabled: boolean;
}

export class OrganisationDetailsState extends AbstractEntityState<OrganisationDetails> {}

export class OrganisationState extends AbstractEntityState<Organisation> {}

export type CintraCloudFlavour = 'Vanilla' | 'Trace';
export const cintraCloudFlavours: CintraCloudFlavour[] = ['Vanilla', 'Trace'];

export enum SmsProvider {
  Default = 'Default',
  UKBased = 'UK Based'
}

export enum ServiceType {
  SaaS = 'SaaS',
  Source = 'Source',
  CoManaged = 'Co-Managed'
}
