import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { filter, map } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { createStore, select, setProps, withProps } from '@ngneat/elf';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActionArea } from '@design/buttons/action-config';
import { BurgerBar, emptyAppLayout, RightSidebar } from './app-layout';
import { ChangePasswordDialogComponent } from '@auth-n/change-password/change-password-dialog.component';
import { ChangeDetailsDialogComponent } from '@security/users/change-details/change-details-dialog.component';
import { ActiveSecurityContextStateService } from '@security/active-security/active-security-context.state-service';
import { AuthenticationService } from '@auth-n/authentication.service';
import { HolidayApproverSummaryStateService } from 'src/app/employee-hub/employee-leave/leave-approvals';
import { EquifaxDisclosureAcknowledgementDataProvider } from '@app/equifax/equifax-disclosure-acknowledgement-data-provider';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class AppLayoutStateService implements OnDestroy {
  private readonly store = createStore({ name: 'app-layout' }, withProps(emptyAppLayout));

  accountMenu$ = this.store.pipe(select((state) => state.accountMenu));
  burgerBar$ = this.store.pipe(select((state) => state.burgerBar));
  rightSidebar$ = this.store.pipe(select((state) => state.rightSidebar));
  holidayApproverSummary$ = this.store.pipe(select((state) => state.holidayApproverSummary));
  hasAcknowledgedEquifaxDisclosure$ = this.store.pipe(select((state) => state.hasAcknowledgedEquifaxDisclosure));

  constructor(
    private dialog: MatDialog,
    private authenticationService: AuthenticationService,
    private activeSecurity: ActiveSecurityContextStateService,
    private holidayApproverSummaryStateService: HolidayApproverSummaryStateService,
    private equifaxDisclosureAcknowledgementDataProvider: EquifaxDisclosureAcknowledgementDataProvider
  ) {
    activeSecurity.activeUser$.pipe(untilDestroyed(this)).subscribe(() => {
      this.setAccountMenu();
    });

    this.setHolidayApproverSummary();
    this.setHasAcknowledgedEquifaxDisclosure();
  }

  private setHolidayApproverSummary() {
    // required by both the burger bar and the employee hub top nav...
    this.activeSecurity.activeMembership$
      .pipe(
        untilDestroyed(this),
        filter((m) => !!m),
        map((m) => m.organisationId),
        distinctUntilChanged()
      )
      .subscribe((m) => {
        if (this.activeSecurity.canAccessHolidays()) {
          this.holidayApproverSummaryStateService.fetchEntity();
        } else {
          this.holidayApproverSummaryStateService.entity = emptyAppLayout.holidayApproverSummary;
        }
      });
    this.holidayApproverSummaryStateService.entity$
      .pipe(untilDestroyed(this))
      .subscribe((holidayApproverSummary) => this.store.update(setProps({ holidayApproverSummary })));
  }

  private getAccountMenuActions(): ActionArea {
    const actions = [
      {
        label: 'Change Password',
        execute: () => this.dialog.open(ChangePasswordDialogComponent)
      },
      {
        label: 'Change Phone Number',
        execute: () => this.dialog.open(ChangeDetailsDialogComponent)
      },
      { label: 'Sign Out', execute: () => this.authenticationService.signOutAndRedirect() }
    ];

    return {
      actions
    };
  }

  private setAccountMenu() {
    const accountMenu = this.getAccountMenuActions();
    this.store.update(setProps({ accountMenu }));
  }

  private setHasAcknowledgedEquifaxDisclosure() {
    this.activeSecurity.activeMembership$
      .pipe(
        untilDestroyed(this),
        filter((m) => !!m),
        map((m) => m.organisationId),
        distinctUntilChanged()
      )
      .subscribe((m) => {
        this.equifaxDisclosureAcknowledgementDataProvider.hasAcknowledgedEquifaxDisclosure$().subscribe((response) => {
          this.store.update(setProps({ hasAcknowledgedEquifaxDisclosure: response }));
        });
      });
  }

  updateBurgerBar(updates: Partial<BurgerBar>) {
    const { burgerBar } = this.store.getValue();
    this.store.update(setProps({ burgerBar: { ...burgerBar, ...updates } }));
  }

  updateRightSidebar(updates: Partial<RightSidebar>) {
    const { rightSidebar } = this.store.getValue();
    this.store.update(setProps({ rightSidebar: { ...rightSidebar, ...updates } }));
  }

  getBurgerBar(): BurgerBar {
    return this.store.getValue().burgerBar;
  }

  getRightSidebar(): RightSidebar {
    return this.store.getValue().rightSidebar;
  }

  ngOnDestroy(): void {
    this.store.destroy();
  }
}
