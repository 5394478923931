import { EntityDataset } from '@entity-framework/datasets/entity-dataset';
import { CostCode } from '@shared-models';
import { sortByKey } from '../utils/sort-by-key';

export class CostCodeDataset extends EntityDataset {
  constructor(costCodes: CostCode[]) {
    costCodes.sort(sortByKey);
    super(costCodes);
  }
  getLabel(item: CostCode): string {
    return item.key;
  }
}
