import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PayrollIdentifier } from '../models';

export interface RecalculationResponse {
  calcInProgress: boolean;
}

@Injectable()
export abstract class RecalculationDataProvider {
  abstract recalculate$(payrollId: PayrollIdentifier): Observable<RecalculationResponse>;
}
