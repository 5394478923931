import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { ActiveAuthenticationStateService } from '@auth-n/state/active-authentication.state-service';
import { LoggingService } from '@logging/logging.service';
import { Observable } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import { ActiveSecurityContextStateService } from '../active-security/active-security-context.state-service';
import { OrganisationPermission } from '@security/users/models/user';

export interface anyOrganisationPermissionsData {
  anyOrganisationPermissions: OrganisationPermission[];
  forbidOnFail: boolean;
}
export const getAnyOrganisationPermissions = (...allowedPermissions: OrganisationPermission[]): anyOrganisationPermissionsData => ({
  anyOrganisationPermissions: allowedPermissions,
  forbidOnFail: true
});

/**
 * Route guard to ensure there's a valid logged-in user having the required permission to access this route.
 */
@Injectable({ providedIn: 'root' })
export class OrganisationAuthorisedRouteGuard {
  constructor(
    private router: Router,
    private authenticationState: ActiveAuthenticationStateService,
    private securityStateService: ActiveSecurityContextStateService,
    private activeOrganisationService: ActiveSecurityContextStateService,
    private logger: LoggingService
  ) {}

  /**
   * Implementation of {@link https://angular.io/api/router/CanActivate|CanActivate}
   * This function will not resolve until the user's role have been successfully fetched post-login.
   *
   * The required role should be passed in as {@link https://angular.io/api/router/Data|Data} config,
   * with {@link anyOrganisationPermissionsData}, otherwise an error will be thrown.
   */
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    this.logger.trace('AUTHORISED ORGANISATION ROUTE GUARD: authorising: ', next);
    return this.securityStateService.fetchingActiveUser$.pipe(
      tap((fetchingActiveUser) => this.logger.trace('AUTHORISED ORGANISATION ROUTE GUARD: fetchingActiveUser?', fetchingActiveUser)),
      filter((fetchingActiveUser) => fetchingActiveUser === false),
      take(1),
      map(() => {
        this.logger.trace('AUTHORISED ROUTE GUARD: isAuthenticated?', this.authenticationState.isAuthenticated);

        if (!this.authenticationState.isAuthenticated) return false;

        const { anyOrganisationPermissions, forbidOnFail } = next.data as anyOrganisationPermissionsData;
        if (!anyOrganisationPermissions)
          throw new Error(
            `Route Guard activated without specifying the required permission(s). Add the 'anyOrganisationPermissions' key and permission to the route's config.`
          );
        const hasNoOrgPermissions = this.activeOrganisationService.hasNoOrganisationPermissions();
        const authorised = this.activeOrganisationService.hasOrganisationAuthorityToOneOf(anyOrganisationPermissions);

        this.logger.trace('AUTHORISED ORGANISATION ROUTE GUARD: authorised to?', {
          anyOrganisationPermissions,
          authorised,
          hasNoOrgPermissions
        });

        if (hasNoOrgPermissions) {
          return this.router.parseUrl('no-permissions');
        } else if (!authorised) {
          if (forbidOnFail) return this.router.parseUrl('forbidden');
          else return false;
        } else {
          return true;
        }
      })
    );
  }
}
