import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CintraProduct } from './cintra-product';
import { LoggingService } from '@logging/logging.service';
import { NavigationRoutingService } from '@utils/navigation-routing.service';
import { CintraThemePalette } from '@design/theme/cintra-theme-palette';
import { map } from 'rxjs/operators';

/**
 * added to product site's root module's root route data, to indicate as such
 */
export interface CintraProductRouteData {
  cintraProduct: CintraProduct;
}

@Injectable({
  providedIn: 'root'
})
export class ActiveCintraProductService {
  activeProduct$ = new BehaviorSubject<CintraProduct>('Unset');
  activeTheme$ = this.activeProduct$.pipe(map((ap) => this.getTheme(ap)));

  constructor(navigationRoutingService: NavigationRoutingService, logger: LoggingService) {
    navigationRoutingService.navigationEvents$.subscribe((event) => {
      const data = navigationRoutingService.getRouteData() as CintraProductRouteData;
      if (!data.cintraProduct || this.activeProduct$.value === data.cintraProduct) return;

      logger.trace('PRODUCT NAVIGATION: to: ', data.cintraProduct);
      this.activeProduct$.next(data.cintraProduct);
    });
  }

  static set cachedActiveProduct(activeProduct: CintraProduct | null) {
    localStorage.setItem('activeProduct', (activeProduct || '').toString());
  }

  static get cachedActiveProduct(): CintraProduct | null {
    const activeProduct = localStorage.getItem('activeProduct') as CintraProduct;
    localStorage.removeItem('activeProduct');
    return activeProduct || null;
  }

  get activeTheme(): CintraThemePalette {
    return this.getTheme(this.activeProduct$.value);
  }

  private getTheme = (activeProduct: CintraProduct): CintraThemePalette => {
    switch (activeProduct) {
      case 'AdminHub':
        return 'system-purple';
      case 'Payroll':
        return 'primary';
      case 'EmployeeHub':
        return 'employee-hub';
      case 'Bureau':
        return 'bureau-blue';
      default:
        return 'primary';
    }
  };
}
