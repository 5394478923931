import { Injectable, Optional } from '@angular/core';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Subject } from 'rxjs';
import { scan, map, distinctUntilChanged } from 'rxjs/operators';
import { FullscreenSpinnerComponent } from '@design/spinners/fullscreen-spinner/fullscreen-spinner/fullscreen-spinner.component';

@Injectable({
  providedIn: 'root'
})
export class FullScreenSpinnerService {
  private overlayRef = this.overlay.create({
    hasBackdrop: true,
    backdropClass: 'fullscreen-spinner-backdrop',
    panelClass: 'fullscreen-spinner-panel',
    disposeOnNavigation: true,
    positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically()
  });

  private spin$: Subject<number> = new Subject();

  constructor(private overlay: Overlay) {
    this.spin$
      .pipe(
        scan((acc, next) => {
          if (!next) return 0;
          return acc + next >= 0 ? acc + next : 0;
        }, 0),
        map((val) => val > 0),
        distinctUntilChanged()
      )
      .subscribe((res) => {
        if (res) {
          this.overlayRef.attach(new ComponentPortal(FullscreenSpinnerComponent));
        } else if (this.overlayRef.hasAttached()) {
          this.overlayRef.detach();
        }
      });
  }

  show = () => this.spin$.next(1);

  hide = () => this.spin$.next(-1);

  reset = () => this.spin$.next(0);
}
