import { Injectable } from '@angular/core';

declare global {
  function hj(messageType: 'event', eventName: string): void;
  function hj(messageType: 'identify', userId: string, userAttributes: { [key: string]: any }): void;
}

@Injectable()
export class HotjarService {
  private hotjarFunction$: Promise<typeof hj> | null = null;

  public createEvent(eventName: string): void {
    this.getHotjarFunction().then((hj) => {
      hj('event', eventName);
    });
  }

  public identify(userAttributes: { [key: string]: any }, userId: string | null = null): void {
    this.getHotjarFunction().then((hj) => {
      hj('identify', userId, userAttributes);
    });
  }

  private getHotjarFunction(): Promise<typeof hj> {
    if (this.hotjarFunction$) {
      return this.hotjarFunction$;
    }

    if (window.hj) {
      this.hotjarFunction$ = Promise.resolve(window.hj);
      return this.hotjarFunction$;
    }

    if (window.document.readyState === 'complete') {
      // If this event has fired AND the 3rd-party script isn't available (see IF-
      // condition BEFORE this one), it means that the 3rd-party script either
      // failed on the network or was BLOCKED by an ad-blocker. As such, we have to
      // fall-back to using a mock API.
      return (this.hotjarFunction$ = Promise.resolve(() => {
        console.warn('Hotjar API not available.');
      }));
    }

    // ASSERT: If we made it this far, the document has not completed loading (but it
    // may be in an "interactive" models which is when I believe that the Angular app
    // gets bootstrapped). As such, we need bind to the LOAD event to wait for our
    // third-party scripts to load (or fail to load, or be blocked).
    this.hotjarFunction$ = new Promise<typeof hj>((resolve) => {
      window.addEventListener('load', () => {
        // At this point, the 3rd-party library is either available or
        // it's not - there's no further loading to do. If it's not
        // present on the global scope, we're going to fall-back to using
        // a mock API.
        resolve(
          window.hj ||
            (() => {
              console.warn('Hotjar API not available.');
            })
        );
      });
    });

    return this.hotjarFunction$;
  }
}

@Injectable()
export class MockHotjarService {
  public createEvent(_: string): void {}
  public identify(_: { [key: string]: any }): void {}
}
