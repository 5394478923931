/**
 * Wraps a discrete collection into a generic representation that can be used in generic ui-components like drop lists, and adds an
 * extra level - grouping - that can be represented as optgroup elements e.g. an Employer > Payroll select
 */
import { AbstractEntity } from '../entity-record/state/abstract-entity';
import { EntityDataset } from './entity-dataset';
import { AbstractDataset } from './abstract-dataset';

/**
 * A parent list item in an {@link AbstractGroupedDataset} which has a child dataset property.
 */
export interface GroupedDataSetItem extends AbstractEntity {
  childDataset: EntityDataset;
}

/**
 * Represents a generic hierarchical list of entities that own a child property which is also a list of entities.
 * Married to the {@link GroupedDatasetSelectComponent}.
 */
export abstract class AbstractGroupedDataset<TItem extends GroupedDataSetItem> extends AbstractDataset<TItem, TItem> {
  abstract getChildItems(item: TItem): AbstractEntity[];

  constructor(public items: TItem[]) {
    super(items);
    items.forEach((item) => {
      item.childDataset = new EntityDataset(this.getChildItems(item));
    });
  }

  getLabel(item: TItem): string {
    return item.name;
  }

  getValue(item: TItem): TItem {
    return item;
  }
}
