<div id="modal-wrap">
  <header *ngIf="showHeader">
    <ng-content select="[header-content]"></ng-content>
  </header>

  <div id="modal-content" (scroll)="onScroll($event)" [ngClass]="{ 'has-header': showHeader, 'has-footer': showFooter }">
    <ng-content select="[body-content]"></ng-content>
  </div>

  <footer *ngIf="showFooter" class="row g-0">
    <div class="col">
      <ng-content select="[footer-content-left]"></ng-content>
    </div>

    <div class="col d-flex justify-content-end">
      <ng-content select="[footer-content-right]"></ng-content>
    </div>
  </footer>
</div>
