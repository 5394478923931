import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '@env/environment';
import { LoggingService } from '@logging/logging.service';
import { PayrollPeriodDetails } from '../../models';

@Injectable({ providedIn: 'root' })
export class PayrollPeriodDataProvider {
  constructor(protected httpClient: HttpClient, protected logger: LoggingService) {}

  currentPayrollPeriod$(payrollId: number) {
    const endpoint = `${environment.apiPaths.payrolls}/${payrollId}/current-period`;
    return this.httpClient.get<PayrollPeriodDetails>(endpoint);
  }

  getAllPayrollPeriods$(payrollId: number) {
    const endpoint = `${environment.apiPaths.payrolls}/${payrollId}/periods`;
    return this.httpClient.get<PayrollPeriodDetails[]>(endpoint);
  }
}
