@if (!!personCalendarEvents) {
  <td tid="person-name">
    <app-body-sm semiBold>{{ getPersonName() }}</app-body-sm>
  </td>
  @for (day of calendarMonthDays$ | async; track day.dayOfMonth) {
    <td [ngClass]="getDayCss(day)" (click)="clickDay(day)">
      @if (!day?.hasEvent && actionMenuItems?.length) {
        <div class="menu-target" [matMenuTriggerFor]="menu"></div>

        <mat-menu #menu="matMenu" class="app-action-list-panel">
          @for (item of actionMenuItems; track item.label) {
            <button
              mat-menu-item
              tid="{{ item.tid }}"
              [ngClass]="{ 'button-disabled': item.disabled }"
              (click)="!item.disabled && item.execute(getData(day))"
            >
              <span class="d-flex align-items-center">
                <img alt="action-icon" src="{{ item.iconImageSrc }}" />
                <span>{{ item.label }}</span>
              </span>
            </button>
          }
        </mat-menu>
      }
      <app-calendar-day-icon *ngIf="day?.hasEvent" [day]="day" [isPersonRow]="true"></app-calendar-day-icon>
    </td>
  }
}
