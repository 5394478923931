import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

import { ActiveSecurityContextStateService } from '../active-security/active-security-context.state-service';
import { OrganisationPermission } from '@security/users/models/user';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: '[ifOrganisationAuthorisedTo]' })
export class IfOrganisationAuthorisedToDirective implements OnInit {
  @Input('ifOrganisationAuthorisedTo') permission!: OrganisationPermission;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private securityState: ActiveSecurityContextStateService
  ) {}

  ngOnInit(): void {
    this.checkAuthority(this.securityState.hasOrganisationAuthorityTo(this.permission));
  }

  private checkAuthority(authorised: boolean): void {
    this.viewContainer.clear();
    if (authorised) this.viewContainer.createEmbeddedView(this.templateRef);
  }
}
