import { Params, Router } from '@angular/router';
import { PayrollPeriodIdentifier } from '@app/shared/models/payroll-period-identifier';
import { isComponentRedirectionParam } from '@entity-framework/entity-grid/state';
import { presetKeyParam } from './payroll-route-params';

export const payrollRoutes = {
  reports: 'payrolls/reports',
  payrollHub: (payrollId: number) => `payrolls/${payrollId}/period/current`,
  selector: 'payrolls/selector',
  navigateToPayrollPeriod: (router: Router, params: PayrollPeriodIdentifier): void => {
    const { payrollId, payrollPeriodId } = params;

    router.navigate(['payrolls', payrollId, 'period', payrollPeriodId ?? 'current'], {
      queryParamsHandling: 'merge'
    });
  },
  navigateToPayrollReports: (router: Router, params: PayrollPeriodIdentifier): void => {
    const { payrollId, payrollPeriodId } = params;

    router.navigate(['payrolls', payrollId, 'period', payrollPeriodId ?? 'current', 'reports']);
  },
  navigateToPayrollLiveReports: (router: Router, params: PayrollPeriodIdentifier): void => {
    const { payrollId, payrollPeriodId } = params;

    router.navigate(['payrolls', payrollId, 'period', payrollPeriodId ?? 'current', 'live-reports']);
  },
  navigateToHubPreset: (params: { router: Router; payrollId: number; payrollPeriodId: number; presetKey: string }): void => {
    const { router, payrollId, payrollPeriodId, presetKey } = params;
    const queryParams: Params = {};
    queryParams[presetKeyParam] = presetKey;
    queryParams[isComponentRedirectionParam] = 1;
    router.navigate(['payrolls', payrollId, 'period', payrollPeriodId ?? 'current'], { queryParams }).then();
  },
  navigateOneOffPaymentsModal: (router: Router, params: { payrollId: number; payrollPeriodId: number; heading: string }): void => {
    const { payrollId, payrollPeriodId, heading } = params;
    const queryParams: Params = { payrollPeriodId, heading };
    router.navigate(['', { outlets: { modal: ['payroll', 'one-off-payments', payrollId] } }], {
      queryParamsHandling: 'merge',
      queryParams
    });
  }
};
