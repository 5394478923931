import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { IconColor } from '@design/buttons/icon/icon-config';
import { Ink } from '@design/typography/ink';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

export type PresetToggleOptions = {
  buttonClass?: string;
  iconName?: string;
  iconColor?: IconColor;
  fontColor: Ink;
};

@UntilDestroy()
@Component({
  selector: 'app-preset-button',
  templateUrl: './preset-button.component.html',
  styleUrls: ['./preset-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PresetButtonComponent implements OnInit {
  defaultOptions: PresetToggleOptions = { iconName: null, iconColor: null, fontColor: 'primary-action' };
  highlightedOptions: PresetToggleOptions = {
    buttonClass: 'highlighted',
    iconName: null,
    fontColor: 'primary-action'
  };

  selectedOptions: PresetToggleOptions = {
    buttonClass: 'selected',
    iconName: 'check_circle',
    iconColor: 'white',
    fontColor: 'ink-white'
  };

  private optionsSubject = new BehaviorSubject(this.defaultOptions);
  private selectedSubject = new BehaviorSubject(false);

  options$ = this.optionsSubject.asObservable();

  @Input() set selected(value: boolean) {
    this.selectedSubject.next(value);
  }

  get selected(): boolean {
    return this.selectedSubject.value;
  }

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() buttonClick = new EventEmitter<void>();

  selected$ = this.selectedSubject.asObservable();

  constructor() {}

  ngOnInit(): void {
    this.selected$.pipe(untilDestroyed(this)).subscribe((selected) => {
      if (selected) this.select();
      else this.diminish();
    });
  }

  highlight = () => {
    if (this.selected) return;
    this.optionsSubject.next(this.highlightedOptions);
  };

  diminish = () => {
    if (this.selected) return;
    this.optionsSubject.next(this.defaultOptions);
  };

  select = () => this.optionsSubject.next(this.selectedOptions);

  onClick = () => this.buttonClick.next();
}
