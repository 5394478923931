import { Type } from '@angular/core';
import { ActionArea, RouteAction } from '@design/buttons/action-config';
import { CintraProduct } from '../cintra-product';
import { HolidayApproverSummary } from 'src/app/employee-hub/employee-leave/leave-approvals';

/**
 * Active models of the application layout
 */
export interface AppLayout {
  burgerBar: BurgerBar;
  rightSidebar: RightSidebar;
  accountMenu: ActionArea;
  cintraProduct: CintraProduct;
  holidayApproverSummary?: HolidayApproverSummary;
  hasAcknowledgedEquifaxDisclosure?: boolean | null;
}

/**
 * Active models of the burger bar menu
 */
export interface BurgerBar {
  visible: boolean;
  activeItem?: RouteAction;
}

/**
 * Active models of the right sidebar
 */
export interface RightSidebar {
  visible: boolean;
  activeComponentType?: Type<any>;
}

/**
 * Default models of the app layout
 */
export const emptyAppLayout: AppLayout = {
  burgerBar: { visible: false },
  rightSidebar: { visible: false },
  accountMenu: { actions: [] },
  cintraProduct: 'Payroll',
  holidayApproverSummary: {
    isHolidayApprover: false,
    isHolidaySelfApprover: false,
    openHolidayRequestCount: 0,
    openAbsenceRequestCount: 0,
    id: 0
  }
};
