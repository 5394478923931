export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const formatNumber = (value: number, decimals: number): string => {
  const divisible = parseInt('1'.padEnd(decimals + 1, '0'), 10);

  return (Math.round(value * divisible) / divisible).toFixed(decimals);
};

export const formatNumberWithSeparators = (value: number, decimalPlaces: number): string => {
  const formatter = new Intl.NumberFormat('en-GB', {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces
  });

  return formatter.format(value); /* e.g. 2,500.12 */
};

/**
 * Rounds the value to the nearest value that gives the specified number of decimal places.
 */
export const roundAccurately = (value: number, decimalPlaces: number) =>
  Number(Math.round(Number(value + 'e' + decimalPlaces)) + 'e' + decimalPlaces * -1);

export function formatLargeNumber(value: number): string {
  if (value >= 1_000_000) {
    return (value / 1_000_000).toFixed(1).replace(/\.0$/, '') + 'M';
  }
  if (value >= 1_000) {
    return (value / 1_000).toFixed(1).replace(/\.0$/, '') + 'K';
  }
  return value.toString();
}
