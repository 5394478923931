/**
 * Conventional implementation of {@link AbstractDataset} - a list of {@link AbstractEntity}
 */
import { AbstractDataset } from './abstract-dataset';
import { AbstractEntity } from '../entity-record/state/abstract-entity';

export class EntityDataset extends AbstractDataset<AbstractEntity, any> {
  getLabel(item: AbstractEntity): string {
    return item.name;
  }

  getValue(item: AbstractEntity): AbstractEntity {
    return item.id;
  }
}
