import { Inject, Injectable } from '@angular/core';
import { ActivationEnd, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, take } from 'rxjs';
import {
  AdminHubFeature,
  BureauFeature,
  EmployeeHubFeature,
  HolidayApprovalsSection,
  PayrollSettingsSection,
  PayrollsFeature
} from './app-features';
import { PayrollFeatureRouteData } from './cintra-payroll-routing.module';
import { AdminHubNavigationService } from '@app/app-layout/header/product-navigation/admin-hub-navigation/admin-hub-navigation.service';
import { BureauNavigationService } from '@app/app-layout/header/product-navigation/bureau-navigation/bureau-navigation.service';
import { CintraPayNavigationService } from '@app/app-layout/header/product-navigation/cintra-pay-navigation/cintra-pay-navigation.service';
import { EmployeeHubNavigationService } from '@app/app-layout/header/product-navigation/employee-hub-navigation/employee-hub-navigation.service';
import { FeatureFlagService, FeatureFlagsServiceInjectionToken } from '@feature-flags/feature-flag.service';
import { ActiveSecurityContextStateService } from '@security/active-security/active-security-context.state-service';
import { AppLayoutStateService } from '@app/app-layout/app-layout.state-service';
import { combineLatest } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ActiveFeatureService {
  activeFeature$ = new BehaviorSubject<PayrollsFeature | EmployeeHubFeature | BureauFeature | AdminHubFeature>(undefined);
  activeSection$ = new BehaviorSubject<PayrollSettingsSection | HolidayApprovalsSection>(undefined);

  navigationListDetails$ = combineLatest([
    this.appLayoutService.holidayApproverSummary$,
    this.featureFlagService.getAllValues$().pipe(take(1))
  ]);

  constructor(
    private router: Router,
    private adminHubNavigationService: AdminHubNavigationService,
    private bureauNavigationService: BureauNavigationService,
    private cintraPayNavigationService: CintraPayNavigationService,
    private employeeHubNavigationService: EmployeeHubNavigationService,
    private activeSecurity: ActiveSecurityContextStateService,
    private appLayoutService: AppLayoutStateService,
    @Inject(FeatureFlagsServiceInjectionToken) protected featureFlagService: FeatureFlagService
  ) {
    this.navigationListDetails$.subscribe(([approverSummary, featureFlags]) => {
      let features = this.adminHubNavigationService.fetchAdminHubNavigation();
      features = features.concat(this.bureauNavigationService.fetchBureauNavigation(featureFlags));
      features = features.concat(
        this.cintraPayNavigationService.fetchCintraPayNavigation(featureFlags, true, this.activeSecurity.activeMembership)
      );
      features = features.concat(this.employeeHubNavigationService.fetchEmployeeHubNavigation(approverSummary));

      let searchRoute = this.router.url.substring(1).split('?')[0];

      if (this.hasNumber(searchRoute)) {
        searchRoute = searchRoute.split('/')[0];
      }

      let startFeature = features.find((f) => f.route?.indexOf(searchRoute) > -1);

      if (startFeature == undefined)
        startFeature = features.find((f) => f.sectionLinks?.find((sl) => sl.route.indexOf(searchRoute) > -1) !== undefined);

      if (startFeature) this.activeFeature$.next(startFeature.feature);
    });

    this.router.events.subscribe((event) => {
      if (event instanceof ActivationEnd) {
        const featureData = event.snapshot.data as PayrollFeatureRouteData;
        if (featureData.feature && this.activeFeature$.value !== featureData.feature) {
          this.activeFeature$.next(featureData.feature);
        }

        if (featureData.section && this.activeSection$.value !== featureData.section) {
          this.activeSection$.next(featureData.section);
        }
      }
    });
  }

  hasNumber(myString) {
    return /\d/.test(myString);
  }
}
