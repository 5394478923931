import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, inject, Input, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AbstractCalendarEventsComponent } from './abstract-calendar-events.component';
import { MonthCalendarComponentStateService } from './state/month-calendar-component-state.service';
import { CalendarMonthWeeks, EventStatus } from './state/calendar';
import { ScreenSizeService } from '@design/layout/screen-size.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-month-events-calendar',
  templateUrl: './month-events-calendar.component.html',
  styleUrls: ['./month-events-calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MonthCalendarComponentStateService]
})
export class MonthEventsCalendarComponent extends AbstractCalendarEventsComponent {
  @Input() disableDropShadow = false;

  calendarMonthWeek$ = <BehaviorSubject<CalendarMonthWeeks>>this.componentStateService.calendarMonthEvents$;
  screenSizeService = inject(ScreenSizeService);
  dayContainerWidth$ = new BehaviorSubject(20);

  constructor(private changeDetectorRef: ChangeDetectorRef) {
    super();
  }

  @ViewChild('wrapper')
  set wrapper(wrapper: ElementRef) {
    this.screenSizeService.width$.pipe(untilDestroyed(this)).subscribe((w) => {
      this.dayContainerWidth$.next(this.getGraphicWidth(wrapper.nativeElement));
      this.changeDetectorRef.detectChanges();
    });
  }

  private getGraphicWidth = (element: HTMLDivElement): number => {
    const width = ((element.clientWidth || 1) - 26) / 7;
    if (width < 0) return 40;
    if (width > 40) return 40;
    return width;
  };
  protected readonly EventStatus = EventStatus;
}
