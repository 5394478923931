<a *ngIf="routeTo" [routerLink]="routeTo">
  <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
</a>

<ng-container *ngIf="!routeTo">
  <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
</ng-container>

<ng-template #contentTpl>
  <div class="info-tile">
    <ng-content></ng-content>
  </div>
</ng-template>
