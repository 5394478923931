import { Injectable } from '@angular/core';
import { AnalyticsBrowser, Context } from '@segment/analytics-next';
import { Analytics } from '@segment/analytics-next';
import { environment } from '@env/environment';
import { ActiveUser } from '@security/active-security/active-user';
import { Member } from '@security/users/models/member';
import { AppHostService } from './app-host.service';

// see: https://developers.heap.io/reference/client-side-apis-overview
interface Heap {
  track: (event: string, properties?: Object) => void;
  identify: (identity: string) => void;
  resetIdentity: () => void;
  addUserProperties: (properties: Object) => void;
  addEventProperties: (properties: Object) => void;
  removeEventProperty: (property: string) => void;
  clearEventProperties: () => void;
  appid: string;
  userId: string;
  identity: string | null;
  config: any;
  loaded: boolean;
}

declare var window: { heap: Heap; analytics: Analytics };

@Injectable()
export class SegmentAnalyticsService {
  private analytics?: Analytics;
  private context?: Context;

  constructor(private appHostService: AppHostService) {}

  loadAnalytics = (): Promise<Analytics | undefined> => {
    return new Promise<Analytics>((resolve) => {
      AnalyticsBrowser.load({
        writeKey: environment.segmentKey
      })
        .then((response: [Analytics, Context]) => {
          this.analytics = response[0];
          this.context = response[1];

          this.analytics.ready().then((res) => resolve(this.analytics));
        })
        .catch((e) => {
          console.error('Error loading segment analytics');
          resolve(undefined);
        });
    });
  };

  identify = (activeUser: ActiveUser) => {
    if (!this.analytics) {
      console.error('Analytics identify - Analytics was not loaded');
      return;
    }

    const applicationPermissions = activeUser.userRoles.filter((ur) => ur.role.scope == 'Application').map((ur) => ur.role.permissions);
    const isCintraEmployee =
      applicationPermissions.length > 0
        ? applicationPermissions
            .reduce((a, b) => a.concat(b))
            .map((p) => p.id)
            .includes('IsCintraEmployee')
        : false;
    const userHeapRoles = activeUser.userRoles
      .filter((ur) => !isCintraEmployee || ur.role.scope == 'Application')
      .map((ur) => ur.role.name);
    const roles = [...new Set(userHeapRoles)].join(', ');

    this.analytics
      .identify(activeUser.cognitoId, {
        name: `${activeUser.forename} ${activeUser.surname}`,
        email: activeUser.email,
        roles: roles
      })
      .then();
  };

  addMemberProperties = (activeMember: Member) => {
    this.clearMemberProperties();

    const properties = {
      'Member Organisation Id': activeMember.organisationId,
      'Member Organisation Name': activeMember.organisationName,
      'Member Cintra Cloud Flavour': activeMember.cintraCloudFlavour,
      'Member Messaging Enabled': activeMember.messagingEnabled.toString(),
      'Member Groups Enabled': activeMember.groupsEnabled.toString(),
      'Member Groups Messaging Enabled': activeMember.groupsMessagingEnabled.toString(),
      'App Host': this.appHostService.appHost
    };

    if (window.heap) {
      window.heap.addEventProperties(properties);
    }
  };

  clearMemberProperties = () => {
    if (window.heap) {
      window.heap.clearEventProperties();
    }
  };

  track = (event: string, payload: any) => {
    if (!this.analytics) {
      console.error('Analytics track - Analytics was not loaded');
      return;
    }
    this.analytics.track(event, payload).then();
  };

  logout = () => {
    this.clearMemberProperties();
    if (!this.analytics) {
      console.error('Analytics reset - Analytics was not loaded');
      return;
    }
    this.analytics.reset();
  };
}

@Injectable()
export class MockSegmentAnalyticsService {
  loadAnalytics = (): Promise<Analytics | undefined> => {
    return new Promise<Analytics>((resolve) => {
      resolve(undefined);
    });
  };

  identify = (activeUser: ActiveUser) => {};

  addMemberProperties = (activeMember: Member) => {};

  clearMemberProperties = () => {};

  track = (event: string, payload: any) => {};

  logout = () => {};
}
