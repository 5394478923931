import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CodeDialogOptions } from './code-dialog-options';

/**
 * A useful modal dialog in which to ensure a user sees a message.
 * See {@link OnscreenMessagingService#openCodeDialog} for usage
 */
@Component({
  templateUrl: './code-dialog.component.html',
  styleUrls: ['./code-dialog.component.scss']
})
export class CodeDialogComponent {
  constructor(public dialogRef: MatDialogRef<CodeDialogComponent>, @Inject(MAT_DIALOG_DATA) public dialogData: CodeDialogOptions) {}

  close = () => this.dialogRef.close();
}
