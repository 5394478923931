import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit } from '@angular/core';
import { IconBackgroundType, IconColor, IconConfig, IconSize } from '@design/buttons/icon/icon-config';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconComponent implements OnInit {
  @Input() size?: IconSize; // short-hand over config
  @Input() color?: IconColor; // short-hand over config
  @Input() background?: IconBackgroundType;
  @Input() hoverColor?: IconColor; // short-hand over config
  @Input() outlined?: boolean; // short-hand over config
  @Input() config?: IconConfig;
  @Input() clickable?: boolean;
  @Input() useSymbol?: boolean; // short-hand over config
  @Input() spaceTop?: number;

  constructor(private elementRef: ElementRef) {}

  ngOnInit() {
    const classList = this.elementRef.nativeElement.classList;
    classList.add(this.iconSize);
    if (this.withBackground) classList.add('with-background');
  }

  private get iconColor(): IconColor {
    return this.color ?? this.config?.color ?? 'white';
  }

  private get iconHoverColor(): string {
    return 'hover-' + (this.hoverColor ?? this.config?.hoverColor ?? 'black');
  }

  get withBackground(): boolean {
    return this.config?.backgroundType !== undefined;
  }

  get backgroundUrl(): string {
    return `framework/design-system/assets/images/${this.config.backgroundType}.svg`;
  }

  get iconSize(): IconSize {
    return this.size ?? this.config?.size ?? 'small';
  }

  get iconOutlined(): string {
    return this.outlined !== undefined || this.config?.outlined === true
      ? this.useSymbol || this.config?.useIconSymbol === true
        ? 'material-symbols-outlined'
        : 'material-icons-outlined'
      : '';
  }

  get styleTags(): string {
    if (this.spaceTop) {
      return 'top:' + this.spaceTop + 'rem;';
    }
    return '';
  }

  getBackgroundColorClass() {
    if (this.background) {
      return 'background-' + this.background;
    }
    return '';
  }

  get ngClass(): string[] {
    return [
      this.iconSize,
      this.iconColor,
      this.iconHoverColor,
      this.iconOutlined,
      this.clickable != undefined && this.clickable ? 'clickable' : '',
      this.withBackground ? 'icon' : ''
    ];
  }
}
