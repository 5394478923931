import { Injectable } from '@angular/core';
import { distinctUntilChanged, map } from 'rxjs';
import { BreakpointObserver } from '@angular/cdk/layout';

@Injectable({
  providedIn: 'root'
})
export class CssBreakpointsService {
  // must be kept in sync with _breakpoints.scss
  smallScreen = '(max-width: 649px)';
  mediumScreen = '(min-width: 650px) and (max-width: 1023px)';
  largeScreen = '(min-width: 1024px)';

  breakpoints$ = this.breakpointObserver.observe([this.smallScreen, this.mediumScreen, this.largeScreen]).pipe(
    map(() => ({
      isSmallScreen: this.breakpointObserver.isMatched(this.smallScreen),
      isMediumScreen: this.breakpointObserver.isMatched(this.mediumScreen),
      isLargeScreen: this.breakpointObserver.isMatched(this.largeScreen)
    })),
    distinctUntilChanged()
  );

  constructor(public breakpointObserver: BreakpointObserver) {}
}
