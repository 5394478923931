<ng-container *ngIf="visibleActions$ | async as visibleActions">
  <div class="button-wrapper" *ngIf="visibleActions.length > 0">
    @if (showButton) {
      @if (buttonIcon) {
        <app-button
          [matMenuTriggerFor]="menu"
          [iconLeft]="iconLeft"
          [iconColor]="iconColor"
          [iconSize]="iconSize"
          [theme]="buttonTheme"
          [icon]="buttonIcon"
          tabindex="0"
        ></app-button>
      } @else {
        <app-button [matMenuTriggerFor]="menu" [theme]="buttonTheme" tabindex="0">{{ buttonText }}</app-button>
      }
    } @else {
      <div class="menu-target" [matMenuTriggerFor]="menu"></div>
    }
  </div>
  <mat-menu #menu="matMenu" class="app-action-list-panel">
    <ng-container *ngFor="let item of visibleActions">
      <button
        mat-menu-item
        tid="{{ item.tid }}"
        [ngClass]="{ 'button-disabled': item.disabled }"
        (click)="!item.disabled && item.execute(this.getData())"
      >
        @if (item.iconImageSrc) {
          <span class="d-flex align-items-center">
            <img alt="action-icon" [src]="item.iconImageSrc" />
            <span>{{ item.label }}</span>
          </span>
        } @else {
          <span>{{ item.label }}</span>
        }
      </button>
    </ng-container>
  </mat-menu>
</ng-container>
