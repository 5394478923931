<div *ngIf="withBackground" class="icon-container with-background" [ngClass]="[iconSize]">
  <div>
    <img alt="icon background" [src]="backgroundUrl" />
  </div>
  <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
</div>

<ng-container *ngIf="!withBackground">
  <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
</ng-container>

<ng-template #contentTpl>
  <div class="{{ getBackgroundColorClass() }} background" [ngClass]="{ 'use-top-space': spaceTop }">
    <mat-icon [ngClass]="ngClass" [style]="styleTags" [svgIcon]="config?.customIcon">
      <ng-content></ng-content>
    </mat-icon>
  </div>
</ng-template>
