import { inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { BehaviorSubject, map } from 'rxjs';

export interface ScreenSize {
  width: number;
  height: number;
}

@Injectable({
  providedIn: 'root'
})
export class ScreenSizeService {
  private document = inject(DOCUMENT);
  private window = this.document.defaultView;

  screenSize$ = new BehaviorSubject<ScreenSize>(this.getScreenSize());

  width$ = this.screenSize$.pipe(map((s) => s.width));

  constructor() {
    this.window.addEventListener('resize', () => this.screenSize$.next(this.getScreenSize()));
  }

  private getScreenSize(): ScreenSize {
    return {
      width: this.window.innerWidth,
      height: this.window.innerHeight
    };
  }
}
