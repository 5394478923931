<app-banner [showClose]="false" bannerType="basic" [bannerName]="config.tid" [bannerType]="config.bannerType ?? 'basic'">
  <div class="content-wrap">
    <div class="content-left">
      @switch (config.icon) {
        @case ('warning') {
          <app-warning-icon></app-warning-icon>
        }
        @case ('info') {
          <app-icon class="info-icon" color="primary-action">info</app-icon>
        }
      }
      <div class="text-left">
        <app-body-md [bold]="config.textLeftWeight === 'bold' || config.textLeftWeight === undefined ? true : undefined">{{
          config.textLeft
        }}</app-body-md>
        <div class="left-offset-wrapper" *ngIf="config.postTextLeftIcon">
          <app-icon
            class="info-icon"
            color="primary-action"
            hoverColor="primary-action"
            size="small"
            matTooltip="{{ config.postTextLeftTooltipText }}"
          >
            info
          </app-icon>
        </div>
      </div>
    </div>
    <div class="content-right">
      <div matTooltip="{{ config.buttonTooltip }}" matTooltipDisabled="{{ !!config.buttonTooltipDisabled }}">
        <app-button
          *ngIf="!!config.showButton"
          tid="{{ config.tid }}-button"
          theme="primary-action"
          type="button"
          [disabled]="!!config.buttonDisabled"
          (click)="callClick($event)"
        >
          {{ config.buttonText }}
        </app-button>
      </div>
    </div>
  </div>
</app-banner>
