import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { AlertDialogComponent } from './alert-dialog/alert-dialog.component';
import { MaterialModule } from '../material.module';
import { DesignSystemModule } from '@design/design-system.module';
import { DynamicModule } from 'ng-dynamic-component';
import { CodeDialogComponent } from './code-dialog/code-dialog.component';
import { CustomSnackBarComponent } from '@pattern-library/onscreen-messaging/custom-snack-bar/custom-snack-bar.component';

@NgModule({
  declarations: [ConfirmationDialogComponent, AlertDialogComponent, CodeDialogComponent, CustomSnackBarComponent],
  imports: [MaterialModule, CommonModule, DesignSystemModule, DynamicModule],
  exports: [ConfirmationDialogComponent, AlertDialogComponent, CodeDialogComponent, CustomSnackBarComponent]
})
export class OnscreenMessagingModule {}

export { ConfirmationDialogComponent, AlertDialogComponent, CodeDialogComponent, CustomSnackBarComponent };
export * from './onscreen-messaging.service';
export * from './alert-dialog/alert-dialog-options';
