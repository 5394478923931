import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LoggingService } from '@logging/logging.service';
import { AbstractEntityDataProvider } from '@entity-framework/entity-record/entity-data-provider/abstract-entity.data-provider';
import { HolidayApproverSummary } from './holiday-approver-summary';
import { holidayApiRoutes } from '../../holiday-api-routes';

@Injectable({ providedIn: 'root' })
export class HolidayApproverSummaryDataProvider extends AbstractEntityDataProvider<HolidayApproverSummary> {
  protected apiRoot = `${holidayApiRoutes.holidayApprovals}/approver-summary`;

  constructor(
    protected httpClient: HttpClient,
    protected logger: LoggingService
  ) {
    super(httpClient, logger);
  }
}
