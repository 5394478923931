import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MaterialModule } from '@pattern-library/material.module';
import { InputFieldComponent } from './input-field/input-field.component';

const declarations = [InputFieldComponent];

@NgModule({
  declarations,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MaterialModule],
  exports: [...declarations]
})
export class LegacyFormsModule {}
