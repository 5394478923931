<div mat-dialog-content>
  <app-h4>Select Organisation</app-h4>
  <p>
    Select the organisation you wish to view.
    <br />
    <br />
    Note: you can change organisation at any time using the organisation menu in the app header.
  </p>
  <form [formGroup]="formGroup" tid="organisation-selector-form">
    <app-dataset-search-ahead
      tid="organisation"
      label="Organisation"
      formControlName="member"
      [dataset]="membershipDataset"
      propertyToMatch="organisationName"
      noResultsText="No Organisations found"
      required
    ></app-dataset-search-ahead>
  </form>
</div>
<div mat-dialog-actions align="end">
  <app-button tid="confirm-button" theme="primary-action" (click)="confirm()">Confirm</app-button>
</div>
