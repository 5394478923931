import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Ink } from '@design/typography/ink';

@Component({
  selector: 'app-body-ml',
  template: '<span [ngClass]="ngClass"><ng-content></ng-content></span>',
  styleUrls: ['./body-medium-large.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BodyMediumLargeComponent {
  @Input() bold?;
  @Input() semiBold?;
  @Input() ink: Ink = 'ink-85';

  get ngClass(): string[] {
    return [this.bold !== undefined ? 'bold' : '', this.semiBold === '' || this.semiBold === true ? 'semi-bold' : '', this.ink];
  }
}
