import { ChangeDetectionStrategy, Component, forwardRef, Input, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatOptionSelectionChange } from '@angular/material/core';
import { AbstractDatasetComponent } from '../abstract-dataset.component';

export type ChipsSelectLayout = 'standard' | 'wider';

@Component({
  selector: 'app-dataset-select-chips',
  templateUrl: './dataset-select-chips.component.html',
  styleUrls: ['./dataset-select-chips.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatasetSelectChipsComponent),
      multi: true
    }
  ]
})
export class DatasetSelectChipsComponent<TItem, TValue> extends AbstractDatasetComponent<TItem, TValue> {
  @Input() layout: ChipsSelectLayout = 'standard';
  @Input() selectLabel?: string;
  @ViewChild('matSelect') matSelect;

  select = (event: MatOptionSelectionChange, item: TItem) => {
    // called twice, but only once as a user input...uurrgghh
    if (event.isUserInput) {
      this.toggleItem(item);
      // hack to clear mat-select - found no clean way in the mat-select or forms apis
      (this.matSelect.options as any)._results.forEach((opt) => (opt._selected = false));
    }
  };
}
