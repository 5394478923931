import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';

import { AbstractFormComponent } from '@design/forms/abstract-form-component';

export type InputType = 'text' | 'number' | 'text-area';
@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent extends AbstractFormComponent implements OnInit, AfterViewInit {
  @Input() type: InputType = 'text';
  @Input() hint = '';
  @Input() minLength = 2;
  @Input() maxLength = 100;
  @Input() minValue?: number;
  @Input() maxValue?: number;
  @Input() decimalPlaces = 0;
  @Input() step?: number;
  @Input() trimOnBlur = false;
  @Input() rows = 5;
  @Input() hasBeenInteractedWith = false;
  @Input() invalidRegexMessage = 'Incorrect format';

  ngOnInit(): void {
    super.ngOnInit();

    if (this.type !== 'number') {
      if (this.minLength > 0) this.otherValidators.push(Validators.minLength(this.minLength));
      if (this.maxLength > 0) this.otherValidators.push(Validators.maxLength(this.maxLength));
    } else {
      // check if min / max value has been provided, and also check they are valid numbers before applying validators
      if (this.minValue != null && !isNaN(this.minValue)) {
        this.otherValidators.push(Validators.min(Number(this.minValue)));
      }

      if (this.maxValue != null && !isNaN(this.maxValue)) {
        this.otherValidators.push(Validators.max(Number(this.maxValue)));
      }
    }

    this.trySetNumberValue(this.formControl.value);
  }

  onBlur() {
    this.trySetNumberValue((this.formControl.value ?? '').trim());

    if (!this.trimOnBlur) return;

    const value = this.formControl.value as string;
    if (value) this.formControl.setValue(value.trim());
  }

  private trySetNumberValue(value) {
    if (this.type !== 'number' || isNaN(value)) return;
    this.formControl.setValue(Number(value).toFixed(this.decimalPlaces), { emitEvent: false });
  }
}
