import { Injectable, Type } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export type BannerContentType = Type<any>;

export type BannerType = 'amber' | 'warning' | 'basic';

@Injectable({ providedIn: 'root' })
export class BannerService {
  private banners = new Map<string, BannerContentType>();

  banners$ = new BehaviorSubject(this.banners);

  show = (name: string, contentType: BannerContentType) => {
    this.banners.set(name, contentType);
    this.banners$.next(this.banners);
  };

  hide = (name: string) => {
    this.banners.delete(name);
    this.banners$.next(this.banners);
  };
}
