import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DatasetSelectComponent } from '@design/forms/dataset-select/dataset-select.component';
import { GroupedDataSetItem } from '@entity-framework/datasets/abstract-grouped-dataset';

@Component({
  selector: 'app-grouped-dataset-select',
  templateUrl: './grouped-dataset-select.component.html',
  styleUrls: ['../_mat-select-overrides.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GroupedDatasetSelectComponent extends DatasetSelectComponent<GroupedDataSetItem, GroupedDataSetItem> {}
