<div class="feature-menu-item" tid="feature-menu-item" [ngClass]="{ active: isActiveRoute$ | async }">
  <a *ngIf="featureLink.openInNewTab" [href]="featureLink.route" target="_blank" tabindex="0">{{ featureLink.label }}</a>
  <a
    *ngIf="!featureLink.openInNewTab"
    (click)="navigateFeature()"
    tabindex="0"
    (mouseenter)="sectionMenuActive = true"
    (mouseleave)="sectionMenuActive = false"
    ><span>{{ featureLink.label }}</span></a
  >

  <app-badge *ngIf="featureLink.counter > 0 && (isActiveRoute$ | async) === false">{{ featureLink.counter }}</app-badge>
  <mat-icon *ngIf="featureLink.sectionLinks?.length" class="trigger-icon">{{
    sectionMenuActive ? 'arrow_drop_up' : 'arrow_drop_down'
  }}</mat-icon>
  <div
    *ngIf="featureLink.sectionLinks?.length"
    class="section-menu"
    (mouseenter)="sectionMenuActive = true"
    (mouseleave)="sectionMenuActive = false"
  >
    <div class="menu-container">
      <ul>
        <li *ngFor="let sectionLink of featureLink.sectionLinks">
          <a tabindex="0" (click)="navigateSection(sectionLink.route)" tid="section-link"
            ><span>{{ sectionLink.label }}</span></a
          ><app-badge *ngIf="sectionLink.counter > 0 && (isActiveRoute$ | async) === false">{{ sectionLink.counter }}</app-badge>
        </li>
      </ul>
    </div>
  </div>
</div>
