import { FeatureFlagType } from '@feature-flags/feature-flag.service';
import { ActiveSecurityContextStateService } from '@security/active-security/active-security-context.state-service';
import { checkFeatureFlag } from '@utils/check-feature-flag';
import { HashMap } from '@utils/hash-map';
import { ActionConfig } from './action-config';

export function isActionVisible(payload: {
  activeSecurity: ActiveSecurityContextStateService;
  action: ActionConfig;
  featureFlags: HashMap<FeatureFlagType>;
  data?: any;
}): boolean {
  const { activeSecurity, action, featureFlags, data } = payload;

  let visible = action.featureFlag ? checkFeatureFlag(!!featureFlags[action.featureFlag], action.featureFlagBooleanValue ?? 'on') : true;

  if (visible && action.anyOrganisationPermissions) {
    visible = activeSecurity.hasOrganisationAuthorityToOneOf(action.anyOrganisationPermissions);
  }

  if (visible && action.allOrganisationPermissions) {
    visible = activeSecurity.hasOrganisationAuthorityToAllOf(action.allOrganisationPermissions);
  }

  if (visible && action.anyApplicationPermissions) {
    visible = activeSecurity.hasApplicationAuthorityToOneOf(action.anyApplicationPermissions);
  }

  if (visible && action.allApplicationPermissions) {
    visible = activeSecurity.hasApplicationAuthorityToAllOf(action.allApplicationPermissions);
  }

  if (visible && action.anyBureauPermissions) {
    visible =
      activeSecurity.hasBureauProfileAuthorityToOneOf(action.anyBureauPermissions) ||
      activeSecurity.hasApplicationAuthorityTo('EditBureaux');
  }

  if (visible && action.allBureauPermissions) {
    visible = activeSecurity.hasBureauProfileAuthorityToAllOf(action.allBureauPermissions);
  }

  if (visible && action.excludeOrganisationPermissions) {
    visible = !activeSecurity.hasOrganisationAuthorityToOneOf(action.excludeOrganisationPermissions);
  }

  visible = visible && (action.enabledAtOrganisation === undefined || action.enabledAtOrganisation);

  visible = visible && (!action.canExecute || (data && action.canExecute(data)));

  visible = visible && action.visible !== false;

  return visible;
}
