import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from './material.module';

/**
 * Module encapsulating reusable UI and Layout ui-components.
 */
@NgModule({
  exports: [CommonModule, ReactiveFormsModule, FormsModule, MaterialModule]
})
export class PatternLibraryModule {}
