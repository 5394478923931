import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { FeatureFlagKey, FeatureFlagsServiceInjectionToken } from '@feature-flags/feature-flag.service';
import { createStore, select, setProps, withProps } from '@ngneat/elf';
import { environment } from '@env/environment';

interface ElfsightPopupComponentState {
  visible: boolean;
  widgetId: string;
}
@Component({
  selector: 'app-elfsight-popup',
  templateUrl: './elfsight-popup.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ElfsightPopupComponent {
  private featureFlagService = inject(FeatureFlagsServiceInjectionToken);
  isProd = environment.environment === 'production';

  private readonly store = createStore(
    { name: 'ElfsightPopupComponentState' },
    withProps<ElfsightPopupComponentState>({
      visible: undefined,
      widgetId: undefined
    })
  );

  state$ = this.store.pipe(select((state) => state));

  @Input() set campaignVisibilityFlag(key: FeatureFlagKey) {
    this.featureFlagService.getValue(key).then((visible) => {
      this.store.update(
        setProps({
          visible: visible as boolean
        })
      );
    });
  }

  @Input() set campaignWidgetFlag(key: FeatureFlagKey) {
    this.featureFlagService.getValue(key).then((widgetId) => {
      this.store.update(
        setProps({
          widgetId: widgetId as string
        })
      );
    });
  }
}
