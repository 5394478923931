<app-button elevated [uppercaseText]="false" (click)="onClick()">
  <div class="button-content">
    <app-body-sm>{{ text }}</app-body-sm>
    <div *ngIf="tooltipItems.length === 0" class="number-circle" [ngClass]="ngClass">{{number}}</div>
    <app-popover *ngIf="tooltipItems.length > 0" [onHover]="true" [preferDisplayAbove]="true">
      <div popover-source class="number-circle" [ngClass]="ngClass">{{number}}</div>
      <ul>
        <li *ngFor="let tooltipItem of tooltipItems">{{ tooltipItem }}</li>
      </ul>
    </app-popover>
  </div>
</app-button>
