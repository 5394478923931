import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '@env/environment';
import { ClutchStatusDataProvider } from '../shared/clutch-status.data-provider';

@Injectable({ providedIn: 'root' })
export class PayrollLockedDataProvider extends ClutchStatusDataProvider {
  constructor(private httpClient: HttpClient) {
    super();
  }

  payrollLocked$(payrollId: number): Observable<boolean> {
    return this.httpClient.get<boolean>(`${environment.apiPaths.payrolls}/${payrollId}/clutch-status`);
  }
}
