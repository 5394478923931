import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { OnscreenMessagingService } from '@pattern-library/onscreen-messaging/onscreen-messaging.service';
import { MyAccountDataProvider } from './my-account.data-provider';
import { LoggingService } from '@logging/logging.service';
import { MyAccountStateService } from './my-account.state.service';

@Component({
  selector: 'app-change-details',
  templateUrl: './change-details-dialog.component.html',
  styleUrls: ['./change-details-dialog.component.scss'],
  providers: [MyAccountDataProvider, MyAccountStateService]
})
export class ChangeDetailsDialogComponent implements OnInit {
  formGroup$ = this.stateService.formGroup$;

  constructor(
    private dialogRef: MatDialogRef<ChangeDetailsDialogComponent>,
    private stateService: MyAccountStateService,
    private messagingService: OnscreenMessagingService,
    private logger: LoggingService
  ) {}

  ngOnInit() {
    this.logger.trace('ChangeDetailsDialogComponent: binding form to entity models');
    this.stateService.setupForm();
  }

  changeDetailsSubmit() {
    if (!this.stateService.formValid()) return;
    this.stateService.persist$().subscribe(this.close);
  }

  close = () => this.dialogRef.close();
}
