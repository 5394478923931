import { Directive, HostBinding, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appPreventClickPropagation]'
})
export class PreventClickPropagationDirective {
  @Input() appPreventClickPropagation? = true;

  @HostBinding('attr.preventClickPropagation') get preventClickPropagation() {
    return this.appPreventClickPropagation;
  }

  @HostListener('click', ['$event'])
  onClick(event: any): void {
    if (this.appPreventClickPropagation === false) return;
    event.stopPropagation();
  }
}
