import { Component, EventEmitter, HostBinding, Inject, Input, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ActionConfig } from '@design/buttons/action-config';
import { NonStandardModalOptions } from '../non-standard-modal-options';

/**
 * Layout template for a component targeting the standard modal material dialog
 * LOCKED FOR EDIT - EXTEND ONLY INTO SPECIALISED SUB-TYPES
 */
@Component({
  selector: 'app-non-standard-modal-template',
  templateUrl: './non-standard-modal-template.component.html',
  styleUrls: ['./non-standard-modal-template.component.scss']
})
export class NonStandardModalTemplateComponent {
  @Input() rightActions: ActionConfig[] = [];
  @Input() leftActions: ActionConfig[] = [];

  @Input() showHeader = true;
  @Input() showFooter = true;
  @HostBinding('class.extra-footer-padding') @Input() extraFooterPadding = false;

  @Output() scrollEvent = new EventEmitter<any>();

  constructor(@Inject(MAT_DIALOG_DATA) private modalOptions: NonStandardModalOptions) {}

  @HostBinding('class.stepper-modal') stepperClass = this.modalOptions.modalType === 'Stepper';
  @HostBinding('class.edit-modal') editClass = this.modalOptions.modalType === 'Edit';
  @HostBinding('class.message-modal') messageClass = this.modalOptions.modalType === 'Message';
  @HostBinding('class.standard-modal') standardClass = this.modalOptions.modalType === 'Standard';
  @HostBinding('class.question-modal') questionClass = this.modalOptions.modalType === 'Question';
  @HostBinding('class.narrow-modal') narrowClass = this.modalOptions.modalType === 'Narrow';

  onScroll(event: any) {
    this.scrollEvent.emit(event);
  }
}
