<div class="widget">
  <div class="title" *ngIf="title">
    <app-body-sm semiBold>{{ title }}</app-body-sm>
  </div>
  <div class="content-wrap">
    <div class="content">
      <ng-content></ng-content>
    </div>
    <div class="footer">
      <ng-content select="[footer]"></ng-content>
    </div>
  </div>
</div>
