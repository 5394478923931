import { ActivatedRoute, convertToParamMap, Params, ResolveFn, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { inject } from '@angular/core';

export function getIntParam$(route: ActivatedRoute, paramName: string): Observable<number> {
  return route.paramMap.pipe(
    map((x) => {
      const paramValue = x.get(paramName);
      return paramValue ? parseInt(paramValue, 10) : null;
    })
  );
}

export function getIntParam(route: ActivatedRoute, paramName: string, defaultValue: any = null): number {
  const paramValue = route.snapshot.paramMap.get(paramName);
  return paramValue ? parseInt(paramValue, 10) : defaultValue;
}

export function getIntModalParam(params: Params, paramName: string): number {
  const paramValue = convertToParamMap(params).get(paramName);
  return paramValue ? parseInt(paramValue, 10) : null;
}

export function getQueryParam$(route: ActivatedRoute, paramName: string): Observable<string> {
  return route.queryParamMap.pipe(map((x) => x.get(paramName)));
}

export function getQueryParam(route: ActivatedRoute, paramName: string): string {
  return route.snapshot.queryParamMap.get(paramName);
}

export function getIntQueryParam(route: ActivatedRoute, paramName: string): number {
  const paramValue = route.snapshot.queryParamMap.get(paramName);
  return paramValue ? parseInt(paramValue, 10) : null;
}

export interface ExtrasState {
  extrasStateResolver: Record<string, any>;
}

/**
 * resolves state set in router.navigate(with state), to a Route data object
 * USAGE:
 * const routes: Routes = [
 *   {
 *     path: '',
 *     component: FormModalActivationComponent,
 *     resolve: {
 *       extrasStateResolver
 *     }
 *   }
 * ];
 *
 */
export const extrasStateResolver: ResolveFn<ExtrasState['extrasStateResolver']> = (route, state) => {
  const router = inject(Router);
  const currentNavigation = router.getCurrentNavigation();
  return currentNavigation?.extras.state ?? {};
};
