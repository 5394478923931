<ng-container
  *ngIf="{
    activeProduct: activeCintraProductService.activeProduct$ | async,
    breakpoints: cssBreakpointsService.breakpoints$ | async
  } as state"
>
  <div class="container-fluid g-0">
    <div id="top-nav-md" class="row g-0 top-nav" *ngIf="!state.breakpoints.isLargeScreen && !activeSecurity.isEmployeeHubIosUser">
      <div class="col-12 d-flex align-items-center justify-content-center">
        <ng-container [ngTemplateOutlet]="logo"></ng-container>
      </div>
      <div class="col-12 d-flex align-items-stretch">
        <ng-container [ngTemplateOutlet]="tabs"></ng-container>
      </div>
    </div>

    <div id="top-nav-lg" class="row g-0 top-nav" *ngIf="state.breakpoints.isLargeScreen && !activeSecurity.isEmployeeHubIosUser">
      <div class="col-7 d-flex align-items-center">
        <ng-container [ngTemplateOutlet]="logo"></ng-container>
        <ng-container [ngTemplateOutlet]="tabs"></ng-container>
      </div>
      <div class="col-5 d-flex align-items-center justify-content-end">
        <ng-container *ngIf="state.activeProduct === 'Payroll' || state.activeProduct === 'Bureau'">
          <button class="header-button beamer-button" [id]="elementIdForBeamer" tabindex="0">
            <app-body-xs bold>What's new?</app-body-xs>
          </button>

          <div class="divider"></div>
        </ng-container>

        <a
          tid="help-link"
          id="help-button"
          [href]="getProductHelpUrl(state.activeProduct)"
          target="_blank"
          title="Go to the help and support portal"
          tabindex="0"
        >
          <button class="header-button">
            <span class="live-help-icon {{ state.activeProduct.toLowerCase() + '-nav' }}"></span>
            <app-body-xs bold>Help</app-body-xs>
          </button>
        </a>

        <div class="divider"></div>

        <ng-container *ngIf="appLayout.accountMenu$ | async as accountMenuItems">
          <button id="account-menu" tid="account-menu" [matMenuTriggerFor]="accountMenu" class="header-button menu-trigger" tabindex="0">
            <span class="account-circle-icon {{ state.activeProduct.toLowerCase() + '-nav' }}"></span>
            <app-body-xs bold *ngIf="fullName$ | async as fullName">{{ fullName }}</app-body-xs>
            <mat-icon class="trigger-icon">arrow_drop_down</mat-icon>
          </button>

          <mat-menu #accountMenu class="header-menu">
            <button *ngFor="let action of accountMenuItems.actions" mat-menu-item (click)="action.execute && action.execute()" tabindex="0">
              {{ action.label }}
            </button>
            <button *ngIf="isImpersonated()" mat-menu-item (click)="endUserImpersonation()" tabindex="0">End Impersonation</button>
          </mat-menu>
        </ng-container>
      </div>
    </div>

    <app-cintra-product-navigation class="d-block"></app-cintra-product-navigation>
  </div>

  <ng-template #logo>
    <img src="/assets/img/cintra-logo.svg" alt="Cintra logo" class="cintra-logo" (click)="home()" tid="cloud-home-link" title="Home" />
  </ng-template>

  <ng-template #tabs>
    <ul id="nav-header-tabs" class="desktop-header-tabs flex-grow-1 align-self-end d-flex align-items-end">
      <li
        *ngIf="canAccessBureau"
        [ngClass]="{
          active: state.activeProduct === 'Bureau',
          'bureau-logo': true
        }"
        (click)="navigateProduct('Bureau')"
        title="Bureau Home"
        tid="bureau-home-link"
        tabindex="0"
      >
        <div class="tab-content-wrapper">
          <div class="img-container">
            <img *ngIf="state.activeProduct === 'Bureau'" src="/assets/img/BureauActive.svg" alt="Bureau Active" />
            <img *ngIf="state.activeProduct !== 'Bureau'" src="/assets/img/BureauNotActive.svg" alt="Bureau Not Active" />
          </div>
          <app-body bold [ink]="state.activeProduct === 'Bureau' ? 'ink-white' : 'ink-70'">Bureau</app-body>
        </div>
      </li>

      <li
        *ngIf="canAccessCintraPayrolls"
        [ngClass]="{
          active: state.activeProduct === 'Payroll',
          'payroll-logo': true,
          'use-underline': canAccessEmployeeHub && canAccessCintraPayrolls
        }"
        (click)="navigateProduct('Payroll')"
        title="Payrolls Home"
        tid="payroll-home-link"
        tabindex="0"
      >
        <div class="tab-content-wrapper">
          <div class="img-container">
            <img *ngIf="state.activeProduct === 'Payroll'" src="/assets/img/PayrollOutlineActive.svg" alt="Payroll Hub Active" />
            <img *ngIf="state.activeProduct !== 'Payroll'" src="/assets/img/PayrollOutlineNotActive.svg" alt="Payroll Hub Not Active" />
          </div>
          <app-body bold [ink]="state.activeProduct === 'Payroll' ? 'ink-white' : 'ink-70'">Payroll</app-body>
        </div>
      </li>

      <li
        *ngIf="canAccessEmployeeHub"
        [ngClass]="{
          active: state.activeProduct === 'EmployeeHub',
          'employee-hub-logo': true,
          'use-underline': canAccessEmployeeHub && canAccessCintraPayrolls
        }"
        (click)="navigateProduct('EmployeeHub')"
        title="Employee Hub Home"
        tid="employee-hub-home-link"
        tabindex="0"
      >
        <div class="tab-content-wrapper">
          <div class="img-container">
            <img *ngIf="state.activeProduct === 'EmployeeHub'" src="/assets/img/EmployeeHubActive.svg" alt="Employee Hub Active" />
            <img *ngIf="state.activeProduct !== 'EmployeeHub'" src="/assets/img/EmployeeHubNotActive.svg" alt="Employee Hub Not Active" />
          </div>
          <app-body bold [ink]="state.activeProduct === 'EmployeeHub' ? 'ink-white' : 'ink-70'">Employee Hub</app-body>
        </div>
      </li>

      <li
        *ngIf="canAccessAdminHub"
        [ngClass]="{
          active: state.activeProduct === 'AdminHub',
          'admin-hub-logo': true
        }"
        (click)="navigateProduct('AdminHub')"
        title="Admin Hub Home"
        tid="admin-hub-home-link"
        tabindex="0"
      >
        <div class="tab-content-wrapper">
          <div class="img-container">
            <img *ngIf="state.activeProduct === 'AdminHub'" src="/assets/img/AdminHubActive.svg" alt="Admin Hub Active" />
            <img *ngIf="state.activeProduct !== 'AdminHub'" src="/assets/img/AdminHubNotActive.svg" alt="Admin Hub Not Active" />
          </div>
          <app-body bold [ink]="state.activeProduct === 'AdminHub' ? 'ink-white' : 'ink-70'">Admin Hub</app-body>
        </div>
      </li>
    </ul>

    <div class="mobile-header-tabs">
      <app-mobile-tab-navigation></app-mobile-tab-navigation>
    </div>
  </ng-template>
</ng-container>
