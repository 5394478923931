import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AbstractUpdatedService } from '@utils/abstract-updated.service';
import { EmployeesUpdatedService } from './employees-updated.service';
import { OneOffPaymentsUpdatedService } from './one-off-payments-updated.service';
import { SalaryAndAllowancesUpdatedService } from './salary-and-allowances-updated.service';

/**
 * Service for communicating updates to the dashboard, so that the underlying data can be refreshed
 */
@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class DashboardUpdatedService extends AbstractUpdatedService {
  constructor(
    employeesUpdatedService: EmployeesUpdatedService,
    oneOffPaymentsUpdatedService: OneOffPaymentsUpdatedService,
    salaryAndAllowancesUpdatedService: SalaryAndAllowancesUpdatedService
  ) {
    super();
    employeesUpdatedService.updated.pipe(untilDestroyed(this)).subscribe((options) => {
      if (!options || options.updateDashboard) {
        this.trigger();
      }
    });
    oneOffPaymentsUpdatedService.updated.pipe(untilDestroyed(this)).subscribe((options) => {
      if (!options || options.updateDashboard) {
        this.trigger();
      }
    });
    salaryAndAllowancesUpdatedService.updated.pipe(untilDestroyed(this)).subscribe((options) => {
      if (!options || options.updateDashboard) {
        this.trigger();
      }
    });
  }
}
