import { Params, Router } from '@angular/router';
import { CostCode } from '@shared-models';
import { presetKeyParam } from '@app/payrolls/payroll-route-params';
import { isComponentRedirectionParam } from '@entity-framework/entity-grid/state';
import { GroupPayrollPeriodIdentifier } from '@app/shared/models/group-payroll-period-identifier';
import { PayrollPeriodIdentifier } from '@app/shared/models/payroll-period-identifier';

export const groupRoutes = {
  groupsHub: 'access-control/groups',
  createGroupWizard: 'access-control/groups/create-group-wizard',
  createSubgroupWizard: (groupId: number) => `access-control/groups/${groupId}/create-sub-group-wizard`,
  groupHub: (groupId: number) => `access-control/groups/${groupId}`,
  setEmployees: (groupId: number) => `access-control/groups/${groupId}/set-employees`,
  navigateOneOffPaymentsTabbedModal: (router: Router, groupId: number): void => {
    router.navigate(['', { outlets: { modal: ['group', 'imports', 'one-off-payments', groupId] } }], { queryParamsHandling: 'merge' });
  },
  navigateGroupPayrollView: (
    router: Router,
    params: { groupId: number; payrollId: number; payrollPeriodId: number; view: string }
  ): void => {
    const { groupId, payrollId, payrollPeriodId, view } = params;
    const queryParams: Params = { presetKey: view };
    router.navigate(['access-control', 'groups', groupId, 'payrolls', payrollId, 'period', payrollPeriodId ?? 'current'], { queryParams });
  },
  navigateGroupNewStarters: (router: Router, groupId: number): void => {
    router.navigate(['', { outlets: { modal: ['group', 'new-starter-wizard', groupId] } }], { queryParamsHandling: 'merge' });
  },
  navigateOneOffPaymentsModal: (
    router: Router,
    params: { groupId: number; payrollId: number; payrollPeriodId: number; costCode: CostCode; heading: string }
  ): void => {
    const { groupId, payrollId, payrollPeriodId, costCode, heading } = params;

    const queryParams: Params = { payrollPeriodId, costCodeId: costCode?.id, costCodeKey: costCode?.key, heading };
    router.navigate(['', { outlets: { modal: ['group', 'one-off-payments', groupId, payrollId] } }], {
      queryParamsHandling: 'merge',
      queryParams
    });
  },
  navigateDeleteGroupConfirmation: (router: Router, groupId: number): void => {
    router.navigate(['', { outlets: { modal: ['group', 'delete-group', groupId] } }]);
  },
  navigateToHubPreset: (params: {
    router: Router;
    groupId: number;
    payrollId: number;
    payrollPeriodId: number;
    presetKey: string;
  }): void => {
    const { router, payrollId, payrollPeriodId, presetKey, groupId } = params;
    const queryParams: Params = {};
    queryParams[presetKeyParam] = presetKey;
    router
      .navigate(['access-control', 'groups', groupId, 'payrolls', payrollId, 'period', payrollPeriodId ?? 'current'], { queryParams })
      .then();
  }
};
