<mat-form-field appearance="fill">
  <mat-label>{{ label }}</mat-label>
  <input
    matInput
    [type]="type"
    [placeholder]="placeholder"
    [formControl]="formControl"
    [title]="label"
    [required]="required"
    [email]="email"
    [step]="step"
    (blur)="onBlur()"
  />
  <mat-hint *ngIf="hint && hint.length">{{ hint }}</mat-hint>
  <mat-error *ngIf="formControl.hasError('required')">Required</mat-error>
  <mat-error *ngIf="formControl.hasError('email')">Not a valid email address</mat-error>
  <mat-error *ngIf="formControl.hasError('pattern')">Incorrect format</mat-error>
  <mat-error *ngIf="formControl.hasError('minlength')">Minimum {{ minLength }} {{ type === 'number' ? 'digits' : 'characters' }}</mat-error>
  <mat-error *ngIf="formControl.hasError('maxlength')">Maximum {{ maxLength }} {{ type === 'number' ? 'digits' : 'characters' }}</mat-error>
  <mat-error *ngIf="formControl.hasError('min')">Minimum value {{ minValue }}</mat-error>
  <mat-error *ngIf="formControl.hasError('max')">Maximum value {{ maxValue }}</mat-error>
  <mat-error *ngIf="formControl.hasError('decimalPlaces')">Maximum {{ decimalPlaces }} decimal places</mat-error>
</mat-form-field>
