import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { AbstractUpdatedService } from '@utils/abstract-updated.service';
import { UpdateOptions } from '../models';
import { ClutchStatusUpdatedService } from './clutch.status-updated.service';

/**
 * Service for communicating updates to a payslip, so that the underlying data (e.g. one-off payments) can be refreshed
 */
@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class PayslipUpdatedService extends AbstractUpdatedService<UpdateOptions> {
  constructor(clutchStatusUpdatedService: ClutchStatusUpdatedService) {
    super();
    clutchStatusUpdatedService.updated.pipe(untilDestroyed(this)).subscribe(() => this.trigger());
  }
}
