import { Component, Input } from '@angular/core';
import { Ink } from '../../typography/ink';

@Component({
  selector: 'app-h3',
  template: '<h3 [ngClass]="ngClass"><ng-content></ng-content></h3>',
  styleUrls: ['./h3.component.scss']
})
export class H3Component {
  @Input() noMargin?: boolean;
  @Input() ink: Ink = 'primary';

  get ngClass(): string[] {
    return [this.noMargin !== undefined ? 'no-margin' : '', this.ink];
  }
}
