import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CalendarMonthWeeks, CalendarWeekOfDayEvents, startOfWeekday } from './calendar';
import { AbstractCalendarComponentStateService } from './abstract-calendar-component.state-service';
import { WeekdayNumbers } from 'luxon';
import { filter, skipWhile } from 'rxjs/operators';

@UntilDestroy()
@Injectable()
/** standard month calendar **/
export class MonthCalendarComponentStateService extends AbstractCalendarComponentStateService {
  constructor() {
    super();

    this.state$
      .pipe(
        filter((state) => !!state[0]),
        untilDestroyed(this)
      )
      .subscribe(this.buildCalendar);
  }

  private buildCalendar = () => {
    let { calendarEventsList } = this.store.getValue();

    const calendarMonth: CalendarMonthWeeks = [];

    let currentWeek: CalendarWeekOfDayEvents = [];
    calendarMonth.push(currentWeek);

    let currentDateTime = this.calendarDate.startOf('month');

    const currentMonthIndex = currentDateTime.month;
    let currentWeekday = startOfWeekday;

    const nextWeekday = (): WeekdayNumbers => <WeekdayNumbers>(currentWeekday === 7 ? 1 : currentWeekday + 1);

    // fill some blanks at the beginning to start the first week
    while (currentWeekday != currentDateTime.weekday) {
      currentWeek.push(null);
      currentWeekday = nextWeekday();
    }

    // build the weeks and days of the month
    while (currentDateTime.month == currentMonthIndex) {
      currentWeek.push(calendarEventsList.getCalendarDayEvents(currentDateTime));

      currentDateTime = currentDateTime.plus({ day: 1 });

      currentWeekday = nextWeekday();

      if (currentWeekday == startOfWeekday && currentDateTime.month == currentMonthIndex) {
        currentWeek = [];
        calendarMonth.push(currentWeek);
      }
    }

    const padDaysInWeek = () => {
      while (currentWeek.length < 7) currentWeek.push(null);
    };
    padDaysInWeek();

    // pad max weeks for simple height-alignment in year-mode
    while (calendarMonth.length < 6) {
      currentWeek = [];
      calendarMonth.push(currentWeek);
      padDaysInWeek();
    }

    this.calendarMonthEvents$.next(calendarMonth);
  };
}
