import { Injectable } from '@angular/core';

import { AbstractUpdatedService } from '@utils/abstract-updated.service';
import { UpdateOptions } from '../models';
import { PresetKeys } from '../models/preset-keys';
import { mapToGridIdentifier } from '../utils/map-to-grid-identifier';
import { OneOffPaymentsUpdatedService } from './one-off-payments-updated.service';
import { PayslipUpdatedService } from './payslip-updated.service';
import { SalaryAndAllowancesUpdatedService } from './salary-and-allowances-updated.service';

/**
 * Service for notifying when a payroll or group re-calculation or payroll period change has occured.
 */
@Injectable({ providedIn: 'root' })
export class FinancialsUpdatedService extends AbstractUpdatedService<UpdateOptions> {
  constructor(
    private payslipUpdatedService: PayslipUpdatedService,
    private oneOffPaymentsUpdatedService: OneOffPaymentsUpdatedService,
    private salaryAndAllowancesUpdatedService: SalaryAndAllowancesUpdatedService
  ) {
    super();
  }

  triggerUpdateForPreset(presetKey: PresetKeys, options: UpdateOptions) {
    const { gridType } = mapToGridIdentifier(presetKey);

    switch (gridType) {
      case 'employees':
      case 'leavers':
      case 'new-starters':
        // no trigger necessary, as the EmployeesUpdatedService subscribes to the payslipUpdatedService
        // which we always trigger below.
        break;
      case 'one-off-payments':
      case 'one-off-payments-by-cost-code':
      case 'one-off-payments-by-heading':
        this.oneOffPaymentsUpdatedService.trigger(options);
        break;
      case 'salary-and-allowances':
        this.salaryAndAllowancesUpdatedService.trigger(options);
        break;
    }

    this.payslipUpdatedService.trigger(options);
  }
}
