import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EmptyComponent } from '@design/layout/empty/empty.component';
import { UiVersionRouteGuard } from '@security/authorization/ui-version.route-guard';
import { AnonymousRouteGuard } from '@security/authorization/anonymous.route-guard';
import { ForbiddenComponent } from '@security/authorization/forbidden/forbidden.component';
import { NoPermissionsComponent } from '@security/authorization/no-permissions/no-permissions.component';
import { ResourceNotFoundComponent } from '@design/layout/resource-not-found/resource-not-found.component';
import { payrollModalRoutes } from './cintra-payroll-modal-routes';
import { employeeHubModalRoutes } from './employee-hub/employee-hub-modal-routes';
import { SignInComponent } from './app-layout/auth-n/sign-in/sign-in.component';
import { IqLockedComponent } from '@security/authorization/IqLocked/IqLocked.component';
import { integrationsModalRoutes } from '@app/integrations-admin/integrations-modal-routes';
import { PayrollFeatureRouteData } from '@app/cintra-payroll-routing.module';
import { ResetPasswordComponent } from '@app/app-layout/auth-n/reset-password/reset-password.component';
import { extrasStateResolver } from '@utils/route-utils';
import { employeeLeaveModalRoutes } from '@app/employees/employee-leave/employee-leave-modal-routes';
import { bureauModalRoutes } from '@app/bureau/bureau-modal-routes';
import { calendarModalRoutes } from '@design/components/events-calendar/calendar-modal-routes';
import { adminHubModalRoutes } from '@app/admin-hub/admin-hub-modal-routes';
import { customReportTypesModalRoutes } from '@app/custom-reports-admin/custom-report-types-modal-routes';

const routes: Routes = [
  {
    path: '',
    canActivateChild: [UiVersionRouteGuard],
    children: [
      { path: 'redirect', component: EmptyComponent },
      { path: 'forbidden', component: ForbiddenComponent },
      { path: 'locked', component: IqLockedComponent },
      { path: 'no-permissions', component: NoPermissionsComponent },
      {
        path: 'under-maintenance',
        outlet: 'top',
        loadChildren: () => import('../framework/under-maintenance/under-maintenance.module').then((m) => m.UnderMaintenanceModule)
      },
      {
        path: 'auth/sign-in',
        pathMatch: 'full',
        redirectTo: '/auth/(top:sign-in)'
      },
      {
        path: 'auth',
        canActivate: [AnonymousRouteGuard],
        children: [
          {
            path: 'sign-in',
            component: SignInComponent,
            outlet: 'top',
            resolve: {
              extrasStateResolver
            }
          },
          {
            path: 'request-reset-password',
            component: ResetPasswordComponent,
            outlet: 'top'
          },
          {
            path: 'reset-password',
            component: ResetPasswordComponent,
            outlet: 'top'
          }
        ]
      },
      {
        path: 'auth/user-invitation/:id',
        redirectTo: '/user-invitations/(top:register/:id)'
      },
      {
        path: 'user-invitations',
        canActivate: [AnonymousRouteGuard],
        runGuardsAndResolvers: 'always',
        loadChildren: () => import('./security/user-invitations/user-invitation.module').then((m) => m.UserInvitationModule)
      },
      ...employeeHubModalRoutes,
      {
        path: 'employee-hub',
        loadChildren: () => import('./employee-hub/employee-hub.module').then((m) => m.EmployeeHubModule)
      },
      ...adminHubModalRoutes,
      ...employeeLeaveModalRoutes,
      {
        path: 'admin-hub',
        loadChildren: () => import('./admin-hub/admin-hub.module').then((m) => m.AdminHubModule)
      },
      ...calendarModalRoutes,
      ...integrationsModalRoutes,
      {
        path: 'integrations',
        data: <PayrollFeatureRouteData>{
          feature: 'Settings',
          section: 'ManageIntegrations'
        },
        loadChildren: () => import('./integrations-admin/integrations.module').then((m) => m.IntegrationsModule)
      },
      ...customReportTypesModalRoutes,
      {
        path: 'custom-reports',
        data: <PayrollFeatureRouteData>{
          feature: 'Settings',
          section: 'ManageCustomReports'
        },
        loadChildren: () => import('./custom-reports-admin/custom-report-types.module').then((m) => m.CustomReportTypesModule)
      },
      ...payrollModalRoutes,
      {
        path: '',
        loadChildren: () => import('./cintra-payroll.module').then((m) => m.CintraPayrollModule)
      },
      ...bureauModalRoutes,
      {
        path: 'bureau',
        loadChildren: () => import('./bureau/bureau.module').then((m) => m.BureauModule)
      },
      {
        path: 'hrlog',
        data: <PayrollFeatureRouteData>{
          feature: 'CintraHrLog'
        },
        loadChildren: () => import('./hrlog/cintrahrlog.module').then((m) => m.CintraHrLogModule)
      },
      { path: '', component: EmptyComponent },
      { path: '**', component: ResourceNotFoundComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { paramsInheritanceStrategy: 'always', onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
