import { NgModule } from '@angular/core';
import { MaterialModule } from '@pattern-library/material.module';
import { IfApplicationAuthorisedToDirective } from '@security/authorization/if-application-authorised-to.directive';
import { IfAuthorisedAllOrganisationPermissionsDirective } from '@security/authorization/if-authorised-all-organisation-permissions.directive';
import { IfAuthorisedAnyApplicationPermissionsDirective } from '@security/authorization/if-authorised-any-application-permissions.directive';
import { ForbiddenComponent } from './forbidden/forbidden.component';
import { IfAuthorisedAnyOrganisationPermissionsDirective } from './if-authorised-any-organisation-permissions.directive';
import { IfCustomerDirective } from './if-customer.directive';
import { IfOrganisationAuthorisedToDirective } from './if-organisation-authorised-to.directive';
import { NoPermissionsComponent } from './no-permissions/no-permissions.component';
import { IfFeaturePackAuthorisedToDirective } from '@security/authorization/if-feature-pack-authorised-to.directive';
import { IfAuthorisedAnyFeaturePackPermissionsDirective } from '@security/authorization/if-authorised-any-feature-pack-permissions.directive';
import { IqLockedComponent } from '@security/authorization/IqLocked/IqLocked.component';
import { IfEmploymentFeaturePackAuthorisedToDirective } from '@security/authorization/if-employment-feature-pack-authorised-to.directive';
import { IfAuthorisedAnyEmploymentFeaturePackPermissionsDirective } from '@security/authorization/if-authorised-any-employment-feature-pack-permissions.directive';
import { IfAuthorisedAnyBureauPermissionsDirective } from '@security/authorization/if-authorised-any-bureau-permissions.directive';
import { IfAuthorisedAllBureauPermissionsDirective } from '@security/authorization/if-authorised-all-bureau-permissions.directive';

const declarations = [
  IfOrganisationAuthorisedToDirective,
  IfAuthorisedAnyOrganisationPermissionsDirective,
  IfApplicationAuthorisedToDirective,
  IfAuthorisedAnyApplicationPermissionsDirective,
  IfAuthorisedAllOrganisationPermissionsDirective,
  IfAuthorisedAnyFeaturePackPermissionsDirective,
  IfAuthorisedAnyEmploymentFeaturePackPermissionsDirective,
  IfAuthorisedAllBureauPermissionsDirective,
  IfAuthorisedAnyBureauPermissionsDirective,
  IfFeaturePackAuthorisedToDirective,
  IfEmploymentFeaturePackAuthorisedToDirective,
  ForbiddenComponent,
  IqLockedComponent,
  NoPermissionsComponent,
  IfCustomerDirective
];

@NgModule({
  imports: [MaterialModule],
  exports: [...declarations],
  declarations
})
export class AuthorisationModule {}

export { IfOrganisationAuthorisedToDirective, ForbiddenComponent, IqLockedComponent, IfCustomerDirective };
