import { Component, Input } from '@angular/core';
import { AbstractFormFieldComponent } from '@design/forms/abstract-form-field.component';

@Component({
  selector: 'app-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss']
})
export class SwitchComponent extends AbstractFormFieldComponent {
  @Input() label: string = '';
  @Input() options: string[];

  changeValue(value: string) {
    this.formControl.setValue(value);
    this.onChange(value);
  }

  isEmptyOrWhitespace(str: string) {
    return str === null || str.match(/^ *$/) !== null;
  }
}
