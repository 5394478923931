<a *ngIf="url" [attr.href]="url" [ngClass]="ngClass()" tabindex="0" #anchor>
  <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
</a>
<a *ngIf="routeTo !== undefined && !url" [routerLink]="routeTo" [ngClass]="ngClass()" tabindex="0" #anchor>
  <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
</a>
<a *ngIf="!routeTo && !url" (click)="(click)" [ngClass]="ngClass()" tabindex="0" #anchor>
  <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
</a>
<ng-template #contentTpl><ng-content></ng-content></ng-template>
