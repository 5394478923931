import { EventEmitter, inject } from '@angular/core';
import { LoggingService } from '@logging/logging.service';

export abstract class AbstractUpdatedService<TData = any> {
  updated = new EventEmitter<TData>();
  private logger = inject(LoggingService);

  trigger = (data?: TData) => {
    this.logger.trace(this.constructor.name + ' triggered');
    this.updated.emit(data);
  };
}
