import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { createStore, select, setProp, setProps, withProps } from '@ngneat/elf';
import { UntilDestroy } from '@ngneat/until-destroy';
import { updateInFlight, withInFlight } from '@shared-state';
import { debounceTime, filter, map, Subject, take } from 'rxjs';
import { PayrollPeriodDetails } from '../../models';
import { PayrollPeriodDataProvider } from './payroll-period.data-provider';

interface PayrollPeriodState {
  payrollPeriods: PayrollPeriodDetails[];
  payrollPeriodId?: number;
  selectedIndex?: number;
}

const initialState: PayrollPeriodState = {
  payrollPeriods: [],
  payrollPeriodId: null,
  selectedIndex: null
};

function getSelectedIndex(params: { payrollPeriods: PayrollPeriodDetails[]; payrollPeriodId?: number }): number {
  const { payrollPeriods, payrollPeriodId } = params;

  const selectedIndex = payrollPeriodId
    ? payrollPeriods.findIndex((x) => x.payrollPeriodId === payrollPeriodId)
    : payrollPeriods.findIndex((x) => x.isCurrentPeriod);

  return selectedIndex === -1 ? null : selectedIndex;
}

function canMoveCurrent(state: PayrollPeriodState & { inFlight: boolean }): boolean {
  return !state.inFlight && state.selectedIndex < state.payrollPeriods.length - 1;
}

function canMoveNext(state: PayrollPeriodState & { inFlight: boolean }): boolean {
  return !state.inFlight && state.selectedIndex < state.payrollPeriods.length - 1;
}

function canMovePrevious(state: PayrollPeriodState & { inFlight: boolean }): boolean {
  return !state.inFlight && state.selectedIndex > 0;
}

function getSelectorPayrollPeriod(state: PayrollPeriodState): PayrollPeriodDetails {
  return state.selectedIndex !== null ? state.payrollPeriods[state.selectedIndex] : null;
}

@UntilDestroy()
@Injectable()
export class PayrollPeriodStateService implements OnDestroy {
  private store = createStore({ name: 'payroll-period' }, withProps<PayrollPeriodState>(initialState), withInFlight(true));
  private requestChangePayrollPeriodSubject = new Subject<number>();

  currentPayrollPeriod$ = this.store.pipe(
    filter((x) => !x.inFlight),
    select((x) => {
      const payrollPeriodCount = x.payrollPeriods.length;
      return payrollPeriodCount > 0 ? x.payrollPeriods[payrollPeriodCount - 1] : null;
    })
  );

  selectorPayrollPeriod$ = this.store.pipe(
    filter((x) => !x.inFlight),
    select(getSelectorPayrollPeriod)
  );

  selectedPayrollPeriod$ = this.store.pipe(
    select(
      (x) =>
        x.payrollPeriods[
          getSelectedIndex({
            payrollPeriods: x.payrollPeriods,
            payrollPeriodId: x.payrollPeriodId
          })
        ]
    )
  );

  isCurrentPayrollPeriod$ = this.selectedPayrollPeriod$.pipe(map((x) => !x || !!x.isCurrentPeriod));

  requestChangePayrollPeriod$ = this.requestChangePayrollPeriodSubject.asObservable().pipe(debounceTime(1000));

  canMoveCurrent$ = this.store.pipe(select(canMoveCurrent));

  canMoveNext$ = this.store.pipe(select(canMoveNext));

  canMovePrevious$ = this.store.pipe(select(canMovePrevious));

  constructor(private dataProvider: PayrollPeriodDataProvider, private router: Router) {}

  fetchPayrollPeriods(params: { payrollId: number; payrollPeriodId?: number; updateSelectedIndex?: boolean }) {
    const { payrollId, payrollPeriodId, updateSelectedIndex } = params;

    this.store.update(updateInFlight(true));

    this.dataProvider
      .getAllPayrollPeriods$(payrollId)
      .pipe(take(1))
      .subscribe({
        next: (payrollPeriods) => {
          const selectedIndex = getSelectedIndex({ payrollPeriods, payrollPeriodId });
          this.store.update(setProps({ payrollPeriods, selectedIndex, payrollPeriodId, inFlight: false }));
          if (updateSelectedIndex) {
            this.changeSelectedIndex(selectedIndex);
          }
        },
        error: () => this.store.update(updateInFlight(false))
      });
  }

  updatePayrollPeriod(payrollPeriodId?: number) {
    this.store.update((state) => {
      return {
        ...state,
        payrollPeriodId,
        selectedIndex: getSelectedIndex({ payrollPeriods: state.payrollPeriods, payrollPeriodId })
      };
    });
  }

  clearState(clearPayrollPeriodId?: boolean) {
    this.store.update(setProps(initialState));
    if (clearPayrollPeriodId) {
      this.requestChangePayrollPeriodSubject.next(null);
    }
  }

  movePayrollPeriodForward() {
    const state = this.store.getValue();
    if (canMoveNext(state)) {
      this.changeSelectedIndex(state.selectedIndex + 1);
    }
  }

  movePayrollPeriodBackward() {
    const state = this.store.getValue();
    if (canMovePrevious(state)) {
      this.changeSelectedIndex(state.selectedIndex - 1);
    }
  }

  moveToCurrentPayrollPeriod() {
    const state = this.store.getValue();
    if (canMoveCurrent(state)) {
      this.changeSelectedIndex(state.payrollPeriods.length - 1);
    }
  }

  private changeSelectedIndex(newSelectedIndex: number) {
    const state = this.store.getValue();
    const newPayrollPeriod = state.payrollPeriods[newSelectedIndex];
    this.store.update(setProp('selectedIndex', newSelectedIndex));
    this.requestChangePayrollPeriodSubject.next(newPayrollPeriod.payrollPeriodId);
  }

  ngOnDestroy() {
    this.store.destroy();
  }
}
