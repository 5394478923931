import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { CintraThemePalette } from '@design/theme/cintra-theme-palette';
import { FormModalService } from '@design/modals/form-modal/form-modal.service';
import { Subscription } from 'rxjs';
import { ActiveCintraProductService } from '@app/active-cintra-product.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IconConfig } from '@design/buttons/icon/icon-config';

@UntilDestroy()
@Component({
  selector: 'app-form-modal-template',
  templateUrl: './form-modal-template.component.html',
  styleUrls: ['./form-modal-template.component.scss']
})
export class FormModalTemplateComponent {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() displayFooter = true;
  @Input() bigHeader = false;
  @Input() headerIconOptions?: IconConfig;
  @Output() scrollEvent = new EventEmitter<any>();
  @Output() closeEvent = new EventEmitter();
  @Input() theme?: CintraThemePalette = undefined;

  private closeSubscription: Subscription;

  constructor(
    formModalService: FormModalService,
    private activeProduct: ActiveCintraProductService
  ) {
    // handle an e.g. ESC close, which has no wiring to our custom close events
    this.closeSubscription = formModalService.dialogClosed$.pipe(untilDestroyed(this)).subscribe(() => this.closeClicked());
  }

  contentScrolled(event: any) {
    this.scrollEvent.emit(event);
  }

  closeClicked() {
    this.closeSubscription?.unsubscribe(); // prevent duplicate triggers
    this.closeEvent.emit();
  }

  showIcon(iconString: string) {
    return iconString && iconString.length > 0;
  }

  @HostBinding('class') get ngClass() {
    if (this.theme) return [this.theme];
    return [this.activeProduct.activeTheme];
  }
}
