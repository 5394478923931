<header>
  <div class="title-stack">
    <app-body bold ink="ink-white" *ngIf="topTitle">{{ topTitle }}</app-body>
    <app-h4 noMargin ink="ink-white" *ngIf="bottomTitle">{{ bottomTitle }}</app-h4>
  </div>

  <app-icon class="close-icon" color="ink-45" hoverColor="ink-45" (click)="onClose()" tid="close-modal-icon" clickable="true">
    close
  </app-icon>
</header>

<div class="content">
  <ng-content select="[body-content]"></ng-content>
</div>

<footer class="row g-0">
  <div class="col">
    <ng-content select="[footer-content-left]"></ng-content>
  </div>

  <div class="col d-flex justify-content-end">
    <ng-content select="[footer-content-right]"></ng-content>
  </div>
</footer>
