import { ChangeDetectionStrategy, Component } from '@angular/core';

/**
 * Simple layout component that has a standard small margin-bottom so that content flows with consistent spacing.
 */
@Component({
  selector: 'app-content-sm',
  templateUrl: './content-block-small.component.html',
  styleUrls: ['./content-block-small.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContentBlockSmallComponent {}
