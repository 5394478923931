import { ChangeDetectionStrategy, Component } from '@angular/core';
import { combineLatest, map } from 'rxjs';
import { PayrollPeriodStateService } from '../state/payroll-period.state-service';

@Component({
  selector: 'app-payroll-period-selector',
  templateUrl: './payroll-period-selector.component.html',
  styleUrls: ['./payroll-period-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PayrollPeriodSelectorComponent {
  data$ = combineLatest([
    this.stateService.selectorPayrollPeriod$,
    this.stateService.canMoveCurrent$,
    this.stateService.canMovePrevious$,
    this.stateService.canMoveNext$
  ]).pipe(
    map(([payrollPeriod, canMoveCurrent, canMovePrevious, canMoveNext]) => ({
      payrollPeriod,
      canMovePrevious,
      canMoveNext,
      canMoveCurrent
    }))
  );

  constructor(private stateService: PayrollPeriodStateService) {}

  moveToCurrentPayrollPeriod() {
    this.stateService.moveToCurrentPayrollPeriod();
  }

  movePayrollPeriodBack() {
    this.stateService.movePayrollPeriodBackward();
  }

  movePayrollPeriodForward() {
    this.stateService.movePayrollPeriodForward();
  }
}
