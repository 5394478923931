<div class="status-pill" [matTooltipClass]="tooltipClass" [matTooltip]="config.tooltip" [ngClass]="ngClass">
  <div tid="status-pill-button" (click)="config.onClick !== undefined ? config.onClick() : false">
    <app-icon
      [useSymbol]="config.useIconSymbol"
      [outlined]="config.useIconOutlined"
      *ngIf="config.leftIcon"
      [color]="config.ink"
      style="margin-right: 0.4rem"
      [hoverColor]="config.ink"
      size="{{ getIconSize() }}"
    >
      {{ config.leftIcon }}
    </app-icon>
    <span *ngIf="config.label">{{ config.label }}</span>
    <ng-content></ng-content>
  </div>
  <app-icon
    *ngIf="config.deleteConfig"
    (click)="config.deleteConfig.onClick()"
    [color]="config.ink"
    hoverColor="ink-45"
    size="extra-small"
    class="interactive"
    tid="delete-button"
  >
    clear
  </app-icon>
</div>
