import { Inject, Injectable, Injector, Optional } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { NGXLogger, NgxLoggerLevel } from 'ngx-logger';

import { Log } from './log';
import { LoggingConfig, LoggingConfigInjectionToken } from './logging-config';
import { CintraRollbarService } from '@logging/cintra-rollbar.service';
import { environment } from '@env/environment';

declare var window: any;

/**
 * Application-wide, root-injected, logging mechanism, containing standard log methods with remote logging support.
 *
 * Configured via {@link LoggingConfig}, injected by a runtime app, there are two thresholds:
 * 1. the log-severity threshold for logging to the console
 * 2. the log-severity threshold for logging to the remote sink
 */
@Injectable({
  providedIn: 'root'
})
export class LoggingService {
  requestId?: string;
  sessionId?: string;
  userId?: string;
  email?: string;
  organisationId?: string;
  employeeId?: string;

  /**
   * Constructor dependencies.
   *
   * @param ngxLogger - The 3rd party logging component that is being wrapped.
   * @param loggingConfig - Configuration for the logging component.
   * @param injector
   *
   * This is typically set in the app's Core Module via {@link LoggingModule#forRoot}
   */
  constructor(
    private ngxLogger: NGXLogger,
    @Optional() @Inject(LoggingConfigInjectionToken) private loggingConfig: LoggingConfig,
    private injector: Injector
  ) {
    /*
    During resolution of dependencies that raise exceptions,
    the logging config might not yet have been resolved and would
    create its own exception without the optionality of being null
     */
    if (!loggingConfig) console.warn('No logging config found via LoggingConfigInjectionToken (see LoggingModule.forRoot)');
    else {
      this.updateConfig(loggingConfig);
      this.trace('LoggingService: configured:', ngxLogger.getConfigSnapshot());
    }
    this.trace('LoggingService: service ready');
  }

  /**
   * Generate stack output at the point of logging; useful for tracing (see below)
   */
  private static stack(): string {
    const stack = new Error('').stack || '';
    const lines = stack.split('\n');
    lines.splice(0, 3);
    return lines.join('\n');
  }

  private currentRoute() {
    const activatedRoute = this.injector.get(ActivatedRoute);
    if (activatedRoute) {
      return activatedRoute.snapshot['_routerState'].url;
    }
  }

  updateConfig(loggingConfig: LoggingConfig) {
    this.loggingConfig = loggingConfig;
    this.ngxLogger.updateConfig({
      level: loggingConfig.threshold,
      serverLogLevel: NgxLoggerLevel.OFF,
      serverLoggingUrl: null
    });
  }

  trace(message: any, ...args: any[]) {
    if (!window.Cypress) this.ngxLogger.trace(message, ...args);
  }

  debug(message: any, ...args: any[]) {
    this.ngxLogger.debug(message, ...args);
  }

  info(message: any, ...args: any[]) {
    this.ngxLogger.info(message, ...args);
  }

  log(message: any, ...args: any[]) {
    this.ngxLogger.log(message, ...args);
  }

  warn(message: any, ...args: any[]) {
    this.ngxLogger.warn(message, ...args);
  }

  error(error: any, logExtras: Partial<Log> = {}) {
    if (!this.loggingConfig.logToRollbar) return;
    const rollbarService = this.injector.get(CintraRollbarService);
    rollbarService.error(error, this.buildLog(logExtras));
  }

  /**
   * Build the log packet to send to the server.
   */
  buildLog(log: Partial<Log>): Log {
    return {
      ...log,
      sessionId: this.sessionId,
      organisationId: this.organisationId,
      userId: this.userId,
      employeeId: this.employeeId,
      email: this.email,
      route: this.currentRoute(),
      environment: environment.environment
    } as any as Log;
  }
}
