import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { filter, Observable } from 'rxjs';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LoggingService } from '@logging/logging.service';
import { RightSidebar } from '../app-layout';
import { AppLayoutStateService } from '../app-layout.state-service';
import { ActiveAuthenticationStateService } from '@auth-n/state/active-authentication.state-service';

/**
 * Service for controlling the models of the {@link RightSidebarComponent} which
 * contains a placeholder for a dynamic content component
 */
@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class RightSidebarService {
  constructor(
    private activeSecurity: ActiveAuthenticationStateService,
    private appLayoutStateService: AppLayoutStateService,
    private router: Router,
    private logger: LoggingService
  ) {
    activeSecurity.activeIdentity$
      .pipe(
        filter((id) => id === undefined),
        untilDestroyed(this)
      )
      .subscribe(this.deactivate);
  }

  /**
   * Toggle the right sidebar; optionally force the models by parameter
   */
  toggleRightSidebar = (opened?: boolean) => {
    this.logger.trace('RIGHT SIDE BAR: toggling ');
    const rightSidebar = this.appLayoutStateService.getRightSidebar();
    const visible = opened === undefined ? !rightSidebar.visible : opened;

    this.appLayoutStateService.updateRightSidebar({ visible });
  };

  /**
   * Clear down the right sidebar and its route part
   */
  deactivate = () => {
    const state = this.appLayoutStateService.getRightSidebar();
    if (!state?.visible) return;
    this.router.navigate([{ outlets: { right: null } }], { queryParamsHandling: 'preserve' }).then();
    this.toggleRightSidebar(false);
  };
}
