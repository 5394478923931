import { Component, ChangeDetectionStrategy, Output, EventEmitter, Input } from '@angular/core';
import { MAT_DATE_FORMATS, DateAdapter } from '@angular/material/core';
import { DateTime } from 'luxon';
import { CustomCalendarHeaderComponent } from './custom-calendar-header.component';
import { CustomDateAdapter } from './custom-date-adapter';

@Component({
  selector: 'app-year-picker',
  templateUrl: './year-picker.component.html',
  styleUrls: ['./year-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: DateAdapter, useClass: CustomDateAdapter },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: { month: 'short', year: 'numeric', day: 'numeric' }
        },
        display: {
          dateInput: 'input',
          monthYearLabel: { year: 'numeric', month: 'short' },
          dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
          monthYearA11yLabel: { year: 'numeric', month: 'long' }
        }
      }
    }
  ]
})
export class YearPickerComponent {
  @Input() selectedDate: DateTime = DateTime.now();

  @Output()
  yearChanged: EventEmitter<number> = new EventEmitter<number>();

  customCalendarHeader = CustomCalendarHeaderComponent;

  public onYearSelected(event) {
    const date = DateTime.fromJSDate(event);
    this.selectedDate = date;
    this.yearChanged.emit(date.year);
  }
}
