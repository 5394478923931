import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { LoggingService } from '@logging/logging.service';
import { UiVersionStateService } from './ui-version.state-service';

@Injectable({ providedIn: 'root' })
export class UiVersionRouteGuard {
  constructor(private router: Router, private logger: LoggingService, private uiVersionStateService: UiVersionStateService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkUiChanged(state);
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.checkUiChanged(state);
  }

  checkUiChanged(state: RouterStateSnapshot): boolean {
    if (this.uiVersionStateService.isDifferent()) {
      let shouldReload = true;

      const lastUiVersionReloadKey: string = 'last-ui-version-reload';
      const lastReload = localStorage.getItem(lastUiVersionReloadKey);

      if (lastReload && lastReload.length > 0) {
        const lastReloadDate = new Date(lastReload);
        const secondsDifference = (new Date().getTime() - lastReloadDate.getTime()) / 1000;

        // limit reload attempts to once every 5 minutes - incase ConfigCat and index.html go out of sync
        if (secondsDifference <= 300) shouldReload = false;
      }

      if (shouldReload) {
        localStorage.setItem(lastUiVersionReloadKey, new Date().toISOString());

        this.navigateToUrl(state.url);
        return false;
      }
    }

    return true;
  }

  navigateToUrl(url: string): void {
    window.location.href = url;
  }
}
