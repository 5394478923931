import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { HmrcStarterChecklistDetailsUpdatedService, PayslipUpdatedService } from '@shared-update-services';
import { AbstractUpdatedService } from '@utils/abstract-updated.service';
import { UpdateOptions } from '../models';

/**
 * Service for communicating updates to the payroll employees grid, so that the underlying data (e.g. personal details and payments)
 * can be refreshed
 */
@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class EmployeesUpdatedService extends AbstractUpdatedService<UpdateOptions> {
  constructor(
    payslipUpdatedService: PayslipUpdatedService,
    hmrcStarterChecklistDetailsUpdatedService: HmrcStarterChecklistDetailsUpdatedService
  ) {
    super();
    payslipUpdatedService.updated.pipe(untilDestroyed(this)).subscribe((options) => this.trigger(options));
    hmrcStarterChecklistDetailsUpdatedService.updated.pipe(untilDestroyed(this)).subscribe(() => this.trigger());
  }
}
