import { NgxLoggerLevel } from 'ngx-logger';
import { InjectionToken } from '@angular/core';

/**
 * Used to pass {@link LoggingConfig} up to shared-modules from the runtime app -
 * see {@link LoggingModule#forRoot}
 */
export const LoggingConfigInjectionToken = new InjectionToken<LoggingConfig>('LoggingConfig');

/**
 * Defines logging configuration for the current runtime environment
 */
export interface LoggingConfig {
  /**
   * The threshold at which logs will be logged to console
   */
  threshold: NgxLoggerLevel;
  /**
   * Whether to write certain logs (errors, info) to rollbar
   */
  logToRollbar: boolean;
  /**
   * Controls whether to output a full stack trace when tracing (adds verbosity)
   */
  stackTrace?: boolean;
}
