import { Inject, Injectable } from '@angular/core';
import { ActionConfig } from '@design/buttons/action-config';
import { LoggingService } from '@logging/logging.service';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActiveSecurityContextStateService } from '../../../app/security/active-security/active-security-context.state-service';
import { FeatureFlagService, FeatureFlagsServiceInjectionToken } from '../../feature-flags/feature-flag.service';
import { isActionVisible } from './is-action-visible';

@Injectable({ providedIn: 'root' })
export class ActionConfigService {
  constructor(
    protected activeSecurity: ActiveSecurityContextStateService,
    @Inject(FeatureFlagsServiceInjectionToken) protected featureFlagService: FeatureFlagService,
    private logger: LoggingService
  ) {}

  resolveVisibility$(actions: ActionConfig[]): Observable<ActionConfig[]> {
    if (actions.length == 0) {
      return of([]);
    }

    return combineLatest([this.featureFlagService.getAllValues$(), this.activeSecurity.activeMembership$]).pipe(
      map(([flags, _]) => {
        const resolved = actions.filter((action) => isActionVisible({ activeSecurity: this.activeSecurity, action, featureFlags: flags }));

        this.logger.trace('ActionConfigService: resolved action visibility from/to', actions, resolved);

        return resolved;
      })
    );
  }
}
