<div [ngClass]="{ 'search-border': showBorder, 'search-wrapper': true, standalone: standalone, 'for-context-menu': forContextMenu }">
  <input
    [autocomplete]="autocomplete"
    matInput
    tabindex="0"
    type="text"
    class="search-input"
    [tid]="tid"
    [placeholder]="placeholder"
    [formControl]="formControl"
  />
</div>
