import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { AbstractUpdatedService } from '@utils/abstract-updated.service';
import { EmployeesUpdatedService } from './employees-updated.service';
import { OneOffPaymentsUpdatedService } from './one-off-payments-updated.service';
import { SalaryAndAllowancesUpdatedService } from './salary-and-allowances-updated.service';

/**
 * Service for triggering when a group handover status refresh needs to occur.
 * The handover status includes a significant changes indicator & needs refreshing
 * when financial changes occur.
 */
@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class GroupHandoverStatusUpdateService extends AbstractUpdatedService {
  constructor(
    employeesUpdatedService: EmployeesUpdatedService,
    oneOffPaymentsUpdatedService: OneOffPaymentsUpdatedService,
    salaryAndAllowancesUpdatedService: SalaryAndAllowancesUpdatedService
  ) {
    super();
    employeesUpdatedService.updated.pipe(untilDestroyed(this)).subscribe(() => this.trigger());
    oneOffPaymentsUpdatedService.updated.pipe(untilDestroyed(this)).subscribe(() => this.trigger());
    salaryAndAllowancesUpdatedService.updated.pipe(untilDestroyed(this)).subscribe(() => this.trigger());
  }
}
