import { ModalOptions } from '@design/modals/modal-options';

export type NonStandardModalType = 'Stepper' | 'Edit' | 'Message' | 'Standard' | 'Question' | 'Narrow' | 'Custom';

interface ModalDimensions {
  height: string;
  width: string;
}

export type NonStandardModalOptions = {
  modalType: NonStandardModalType;
  dimensions: ModalDimensions;
  activeTab?: string;
  panelClass?: string;
  showAsWide: boolean;
  fullScreen: boolean;
} & ModalOptions;

const editModal: ModalDimensions = { height: '75vh', width: '93.6rem' };
const stepperModal: ModalDimensions = { height: '88vh', width: '93.6rem' };
const messageModal: ModalDimensions = { height: '28rem', width: '52rem' };
const customModal: ModalDimensions = { height: '-', width: '-' };
const standardModal: ModalDimensions = { height: '80vh', width: '93.6rem' };
const questionModal: ModalDimensions = { height: 'fit-content', width: '70rem' };
const narrowModal: ModalDimensions = { height: '44rem', width: '70rem' };

export const standardModalStyles = new Map<NonStandardModalType, ModalDimensions>([
  ['Stepper', stepperModal],
  ['Edit', editModal],
  ['Message', messageModal],
  ['Custom', customModal],
  ['Standard', standardModal],
  ['Question', questionModal],
  ['Narrow', narrowModal]
]);
