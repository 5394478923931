<a *ngIf="keyStat.routeTo" [routerLink]="keyStat.routeTo">
  <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
</a>

<ng-container *ngIf="!keyStat.routeTo">
  <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
</ng-container>

<ng-template #contentTpl>
  <div class="info-tile">
    <app-icon [config]="keyStat.iconConfig">{{ keyStat.iconConfig.iconName }}</app-icon>
    <app-h3 noMargin>
      {{ keyStat.value }}
      <!--      <span class="pill-box">-->
      <!--        <mat-icon>trending_up</mat-icon>-->
      <!--        +3-->
      <!--      </span>-->
    </app-h3>
    <div class="label">
      <app-body>{{ keyStat.label }}</app-body>
    </div>
    <div class="footer">
      <app-body-sm italic>{{ keyStat.footerText }}</app-body-sm>
    </div>
  </div>
</ng-template>
