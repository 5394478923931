import { Router } from '@angular/router';

export const navigatePayrollLocked = (router: Router, payrollId: number): Promise<boolean> =>
  router.navigate(
    [
      '',
      {
        outlets: {
          message: ['payroll-locked', payrollId]
        }
      }
    ],
    { queryParamsHandling: 'preserve' }
  );
