import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoggingService } from '@logging/logging.service';
import { OnscreenMessagingService } from '@pattern-library/onscreen-messaging/onscreen-messaging.service';
import { HolidayDataUpdatedService } from '@shared-update-services';
import { AbstractEntityState } from '@entity-framework/entity-record/state/abstract-entity.state';
import { AbstractEntityStateService } from '@entity-framework/entity-record/state/abstract-entity.state-service';
import { HolidayApproverSummaryDataProvider } from './holiday-approver-summary.data-provider';
import { HolidayApproverSummary } from './holiday-approver-summary';

@Injectable({ providedIn: 'root' })
export class HolidayApproverSummaryStateService extends AbstractEntityStateService<
  HolidayApproverSummary,
  AbstractEntityState<HolidayApproverSummary>
> {
  constructor(
    protected dataProvider: HolidayApproverSummaryDataProvider,
    protected route: ActivatedRoute,
    protected messaging: OnscreenMessagingService,
    protected logger: LoggingService,
    private holidayDataUpdatedService: HolidayDataUpdatedService
  ) {
    super('HolidayApproverSummaryState', dataProvider, route, messaging, logger);
    this.holidayDataUpdatedService.updated.subscribe(this.fetchEntity);
  }
}
