export enum PresetKeys {
  AllEmployees = 'all-employees',
  CurrentEmployees = 'current-employees',
  CurrentPeriodLeavers = 'current-period-leavers',
  CurrentPeriodNewStarters = 'current-period-new-starters',
  LeaversAllTime = 'leavers',
  OneOffPayments = 'one-off-payments',
  OneOffPaymentsByCostCode = 'one-off-payments-by-cost-code',
  OneOffPaymentsByHeading = 'one-off-payments-by-heading',
  OneOffPaymentsArchive = 'one-off-payments-archive',
  SalaryAndAllowances = 'salary-and-allowances',
  SelectedPeriodEmployees = 'selected-period-employees',
  SelectedPeriodLeavers = 'selected-period-leavers',
  SelectedPeriodNewStarters = 'selected-period-new-starters',
  EmployeeHubUsers = 'employee-hub-users',
  EmployeeHubLeavers = 'employee-hub-leavers',
  PayrollAccessUsers = 'payroll-access-users',
  BureauUsers = 'bureau-users',
  AllOrganisations = 'all-organisations',
  Integrations = 'integrations',
  IntegrationClients = 'integration-clients',
  LiveReports = 'live-reports',
  BureauOrganisations = 'bureau-organisations',
  BureauBankFiles = 'bureau-bank-files',
  BureauBankFileTransactions = 'bureau-bank-file-transactions'
}
