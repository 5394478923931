<div class="tab-list">
  <app-button
    *ngFor="let tab of getTabs()"
    tabButton
    [tid]="tab.tid"
    [icon]="tab.icon"
    [iconOutlined]="tab.outlinedIcon"
    [buttonText]="tab.label"
    [uppercaseText]="false"
    [disabled]="tab.active"
    (clickEvent)="changeTab(tab)"
    (keyup.enter)="changeTab(tab)"
    [iconImagePath]="tab.iconImagePath"
  ></app-button>
</div>
