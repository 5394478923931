import { Component, Input, Output, EventEmitter } from '@angular/core';
import { AbstractFormFieldComponent } from '@design/forms/abstract-form-field.component';
import { ThemePalette } from '@angular/material/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['../_slide-toggle.scss']
})
export class ToggleComponent extends AbstractFormFieldComponent {
  @Input() type = 'boolean';
  @Input() focussed = false;
  @Input() theme: ThemePalette | 'alternate' | undefined = undefined;
  @Input() class: string;
  @Input() labelPosition: 'before' | 'after' = 'before';
  @Input() disabled = true;
  @Output() valueChange = new EventEmitter();

  onToggle = (event: MatSlideToggleChange) => {
    this.onChange(event.checked);
    this.valueChange.emit(event);
  };
}
