import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractIdentifiable } from '@entity-framework/entity-record/state/abstract-entity';
import { environment } from '@env/environment';
import { LoggingService } from '@logging/logging.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class EquifaxDisclosureAcknowledgementDataProvider {
  constructor(protected logger: LoggingService, private httpClient: HttpClient) {}

  protected apiRoot = `${environment.apiPaths.equifax}/equifax-disclosure-acknowledgements`;

  hasAcknowledgedEquifaxDisclosure$(): Observable<boolean> {
    return this.httpClient.get<boolean>(`${environment.apiPaths.equifax}/has-acknowledged-equifax-disclosure`).pipe(
      tap((response) => {
        this.logger.trace('GET HAS ACKNOWLEDGED EQUIFAX DISCLOSURE: response: ', response);
      })
    );
  }

  create$(): Observable<AbstractIdentifiable> {
    return this.httpClient.post<AbstractIdentifiable>(`${this.apiRoot}`, null).pipe(
      tap((response) => {
        this.logger.trace('CREATE DISCLOSURE ACKNOWLEDGEMENT: response: ', response);
      })
    );
  }
}
