import { Component, Input } from '@angular/core';
import { TabConfig } from './tab-config';

@Component({
  selector: 'app-tab-list',
  templateUrl: './tab-list.component.html',
  styleUrls: ['./tab-list.component.scss']
})
export class TabListComponent {
  @Input() tabs: TabConfig[];
  private previouslyActiveTab: TabConfig;

  changeTab = (tabConfig: TabConfig) => {
    this.tabs.forEach((cfg) => {
      if (cfg.active) this.previouslyActiveTab = cfg;
      cfg.active = cfg === tabConfig;
    });
    tabConfig.navigate();
  };

  cancelChangeTab = () => {
    this.changeTab(this.previouslyActiveTab);
  };

  getTabs() {
    return this.tabs?.filter((t) => t.hidden !== true);
  }
}
