import { StatusPillColor, StatusPillConfig } from '@design/components/status-pill/status-pill-config';

export function createPayrollHandoverStatusPillConfig(options: { isPayrollLocked: boolean; isOssUser: boolean }): StatusPillConfig {
  let label: string;
  let tooltip: string;
  let color: StatusPillColor;

  if (options.isPayrollLocked) {
    if (options.isOssUser) {
      label = 'Ready';
      tooltip = null;
    } else {
      label = 'Submitted';
      tooltip = 'Payroll has been submitted for processing and is locked until further notice.';
    }

    color = 'system-blue';
  } else {
    label = 'Not Submitted';
    tooltip = options.isOssUser ? null : 'Payroll should be submitted by the date and time detailed in your Annual Processing Schedule.';
    color = 'ink-45';
  }

  return { label, tooltip, ink: color };
}

export function createGroupHandoverStatusPillConfig(options: {
  submittedGroupCount: number;
  unsubmittedGroupCount: number;
}): StatusPillConfig {
  let label: string;
  let tooltip: string;
  let color: StatusPillColor;

  if (options.submittedGroupCount === 0 && options.unsubmittedGroupCount === 0) {
    return null;
  }

  if (options.unsubmittedGroupCount > 0) {
    label = 'Outstanding groups';
    tooltip = 'Payroll has outstanding groups and is not ready.';
    color = 'system-red';
  } else {
    label = 'All groups locked';
    tooltip = 'All groups have been submitted and are now locked.';
    color = 'system-blue';
  }

  return { label, tooltip, ink: color };
}
