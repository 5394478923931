<a *ngIf="summary.routeTo" [routerLink]="summary.routeTo" (mouseover)="onMouseOver()" (mouseleave)="onMouseLeave()">
  <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
  <app-icon class="next-icon" [config]="nextIconConfig">{{ nextIconConfig.iconName }}</app-icon>
</a>

<ng-container *ngIf="!summary.routeTo">
  <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
</ng-container>

<ng-template #contentTpl>
  <div class="info-tile">
    <div>
      <div class="icon-and-status-pill">
        <app-icon [config]="summary.iconConfig">{{ summary.iconConfig.iconName }}</app-icon>
        <app-status-pill tid="summary-status-pill" *ngIf="summary.statusPillConfig" [config]="summary.statusPillConfig"></app-status-pill>
      </div>

      <app-h4 noMargin>
        {{ summary.label }}
      </app-h4>

      <app-status-pill
        tid="additional-summary-status-pill"
        *ngIf="summary.additionalStatusPillConfig"
        [config]="summary.additionalStatusPillConfig"
      ></app-status-pill>
    </div>

    <div class="summary">
      <div *ngFor="let value of values">
        <app-body>{{ value[0] }}: {{ value[1] }}</app-body>
      </div>
    </div>
  </div>
</ng-template>
