import { ChangeDetectionStrategy } from '@angular/core';
import { Component } from '@angular/core';

@Component({
  selector: 'app-vertical-layout',
  template: '<div class="v-form-container"><ng-content></ng-content></div>',
  styleUrls: ['./vertical-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VerticalLayoutComponent {}
