<div
  class="calendar"
  [class.drop-shadow]="!disableDropShadow"
  *ngIf="navigatorStateService.activeDate$ | async as activeDate"
  [attr.data-year]="activeDate.year"
  [attr.data-month]="activeDate.month"
  #wrapper
>
  <app-month-navigator *ngIf="calendarViewMode === 'month'"></app-month-navigator>

  <table *ngIf="dayContainerWidth$ | async as dayContainerWidth">
    <caption *ngIf="calendarViewMode === 'year'">
      <app-body bold>{{ activeMonth }}</app-body>
    </caption>
    <tr>
      <th *ngFor="let weekday of weekdays">{{ weekday.substring(0, 1) }}</th>
    </tr>
    @for (week of calendarMonthWeek$ | async; track $index) {
      <tr>
        @for (day of week; track $index) {
          <td [attr.data-day-of-month]="day?.dayOfMonth" [ngClass]="getDayCss(day)" (click)="clickDay(day)" tid="calendar-day-container">
            <div
              class="day-container"
              [style.width.px]="dayContainerWidth"
              [style.height.px]="dayContainerWidth"
              [style.line-height.px]="dayContainerWidth"
            >
              @if (!day?.hasEvent && actionMenuItems?.length) {
                <div class="menu-target" [matMenuTriggerFor]="menu"></div>

                <mat-menu #menu="matMenu" class="app-action-list-panel">
                  @for (item of actionMenuItems; track item.label) {
                    <button
                      mat-menu-item
                      tid="{{ item.tid }}"
                      [ngClass]="{ 'button-disabled': item.disabled }"
                      (click)="!item.disabled && item.execute(day)"
                    >
                      <span class="d-flex align-items-center">
                        <img alt="action-icon" src="{{ item.iconImageSrc }}" />
                        <span>{{ item.label }}</span>
                      </span>
                    </button>
                  }
                </mat-menu>
              }
              <app-calendar-day-icon *ngIf="day?.hasEvent" [day]="day"></app-calendar-day-icon>
              <div class="date">{{ day?.dayOfMonth ?? '&nbsp;' }}</div>
            </div>
          </td>
        }
      </tr>
    }
  </table>
</div>
