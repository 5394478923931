<app-form-modal-template title="Change Phone Number" (closeEvent)="close()">
  <form body-content *ngIf="formGroup$ | async as formGroup" [formGroup]="formGroup">
    <app-input
      tid="phone-number"
      label="Mobile number"
      placeholder="Enter your mobile number"
      hint="International format required e.g. +447890123456"
      formPropertyName="phoneNumber"
      [requiredField]="true"
    ></app-input>
  </form>

  <div footer-content-left>
    <app-button type="button" tabindex="0" (click)="close()" tid="cancel-button">Cancel</app-button>
  </div>
  <div footer-content-right>
    <app-button theme="primary-action" (click)="changeDetailsSubmit()" tabindex="0" tid="confirm-button">Save</app-button>
  </div>
</app-form-modal-template>
