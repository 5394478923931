<button
  type="button"
  *ngIf="options$ | async as options"
  [ngClass]="options.buttonClass"
  (mouseenter)="highlight()"
  (mouseleave)="diminish()"
  [disabled]="selected$ | async"
  (click)="onClick()"
>
  <app-icon *ngIf="options.iconName" [color]="options.iconColor" [hoverColor]="options.iconColor">{{ options.iconName }}</app-icon>
  <app-body-sm bold [ink]="options.fontColor"><ng-content></ng-content></app-body-sm>
</button>
