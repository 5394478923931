<span *ngIf="loading" id="spinner-span">
  <mat-spinner diameter="20" strokeWidth="3" color=""></mat-spinner>
</span>

<span [ngClass]="{ 'hyperlink-btn': hyperlinkButton }">
  <ng-container [ngSwitch]="buttonType">
    <button
      *ngSwitchCase="'ng-content-button'"
      mat-flat-button
      [type]="type"
      [color]="theme"
      [disabled]="disabled"
      [disableRipple]="true"
      [ngClass]="ngClass"
      [matTooltip]="tooltip"
      (click)="callClick($event)"
    >
      <ng-container *ngTemplateOutlet="contentOutlet"></ng-container>
    </button>

    <button
      *ngSwitchCase="'icon-button'"
      mat-icon-button
      [type]="type"
      [color]="theme"
      [disabled]="disabled"
      [disableRipple]="true"
      [ngClass]="ngClass"
      [matTooltip]="tooltip"
      (click)="callClick($event)"
    >
      <mat-icon *ngIf="!loading">{{ icon }}</mat-icon>
    </button>

    <button
      *ngSwitchCase="'text-icon-button'"
      mat-flat-button
      [type]="type"
      [color]="theme"
      [disabled]="disabled"
      [disableRipple]="true"
      [ngClass]="ngClass"
      [matTooltip]="tooltip"
      (click)="callClick($event)"
    >
      <app-icon
        class="text-icon me-2"
        [outlined]="iconOutlined"
        [useSymbol]="useSymbol"
        [color]="iconColor"
        [size]="iconSize"
        [hoverColor]="iconHoverColor"
        *ngIf="!loading && iconLeft"
      >
        {{ icon }}
      </app-icon>
      <span>{{ buttonText }}</span>
      <app-icon
        class="text-icon ms-2"
        [outlined]="iconOutlined"
        [useSymbol]="useSymbol"
        [color]="iconColor"
        [size]="iconSize"
        [hoverColor]="iconHoverColor"
        *ngIf="!loading && !iconLeft"
      >
        {{ icon }}
      </app-icon>
      <app-badge *ngIf="badgeCount !== undefined">{{ badgeCount }}</app-badge>
    </button>

    <button
      *ngSwitchCase="'tab-button'"
      mat-flat-button
      [type]="type"
      [color]="theme"
      [disabled]="disabled"
      [disableRipple]="true"
      [ngClass]="ngClass"
      [matTooltip]="tooltip"
      tabindex="0"
      (click)="callClick($event)"
    >
      <app-icon *ngIf="icon" color="iconColor" [useSymbol]="useSymbol" hoverColor="iconColor" [outlined]="iconOutlined">{{
        icon
      }}</app-icon>
      <img *ngIf="iconImagePath" [src]="iconImagePath" />
      <span>{{ buttonText }}</span>
    </button>

    <button
      *ngSwitchCase="'basic-button'"
      mat-button
      [type]="type"
      [color]="theme"
      [disabled]="disabled"
      [disableRipple]="true"
      [ngClass]="ngClass"
      [matTooltip]="tooltip"
      (click)="callClick($event)"
    >
      <ng-container *ngTemplateOutlet="contentOutlet"></ng-container>
    </button>
  </ng-container>
</span>

<ng-template #contentOutlet>
  <ng-content></ng-content>
</ng-template>
