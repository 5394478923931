import { environment } from '@env/environment';

export const holidayAllowanceRoutes = {
  manageHolidayAllowances: '/admin-hub/holiday-allowances'
};

const apiRoot = `${environment.employeeHubApiPaths.employeeLeave}`;

export const holidayAllowanceApiRoutes = {
  holidayAllowance: `${apiRoot}/holiday-allowance`,
  defaultHolidayScheme: `${apiRoot}/default-holiday-scheme`
};
