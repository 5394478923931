import { Injectable, Injector } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Hub } from '@aws-amplify/core';
import {
  SignInInput,
  signIn,
  SignInOutput,
  signOut,
  getCurrentUser,
  GetCurrentUserOutput,
  confirmSignIn,
  ConfirmSignInOutput
} from '@aws-amplify/auth';

import { UserIdentity } from './state/user-identity';
import { ActiveAuthenticationStateService } from './state/active-authentication.state-service';
import { BroadcastService } from '@utils/broadcast.service';
import { OnscreenMessagingService } from '@pattern-library/onscreen-messaging/onscreen-messaging.service';
import { LoggingService } from '@logging/logging.service';
import { environment } from '@env/environment';
import { AppHostService } from '../analytics/app-host.service';

/**
 * Service for managing the authenticated models of the current user.
 * Provides wrapper mechanism around AWS Cognito which is configured via the app using
 * {@link AuthenticationModule#forRoot}, sending in the cognito settings.
 * amplify-js is the npm package bridge between the ui and the cognito backend.
 */
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private router: Router | null = null;
  private route: ActivatedRoute | null = null;

  private initialUrl: string | null = null;
  setInitialUrl(url: string) {
    if (this.initialUrl === null) {
      this.initialUrl = url;
    }
  }
  getInitialUrl(): string | null {
    return this.initialUrl;
  }

  constructor(
    private injector: Injector, // Router not available from an APP_INITIALIZER (see AuthenticationSetupService), so will locate it directly
    private authenticationState: ActiveAuthenticationStateService,
    private messagingService: OnscreenMessagingService,
    private broadcastService: BroadcastService,
    private logger: LoggingService,
    private appHostService: AppHostService
  ) {
    this.handleSignOutOnMultipleTabs();
  }

  /**
   * Ensures that when a user signs-out, the event is broadcast to other tabs so they can redirect
   */
  private handleSignOutOnMultipleTabs() {
    const signOutTopic = 'sign-out';
    Hub.listen('auth', (data) => {
      if (data.payload.event === 'signedOut') {
        this.broadcastService.send(signOutTopic, null);
      }
    });
    this.broadcastService.on(signOutTopic, () => {
      this.setAuthenticatedIdentity(undefined);
    });
  }

  /**
   * Wrapper around Amplify's current authenticated user which throws when null.
   */
  currentUser = (): Promise<GetCurrentUserOutput | undefined> =>
    new Promise((resolve) => {
      getCurrentUser()
        .then((output) => resolve(output))
        .catch(() => resolve(undefined));
    });

  /**
   * Manually sets the auth session in local storage
   */
  persistSessionToLocalStorage({ cognitoId, accessToken, idToken, refreshToken }) {
    const cognitoClientId = environment.cognitoConfig.Auth.Cognito.userPoolClientId;
    const makeKey = (name: string) => `CognitoIdentityServiceProvider.${cognitoClientId}.${cognitoId}.${name}`;

    localStorage.setItem(`CognitoIdentityServiceProvider.${cognitoClientId}.LastAuthUser`, cognitoId);
    localStorage.setItem(makeKey('accessToken'), accessToken);
    localStorage.setItem(makeKey('idToken'), idToken);
    localStorage.setItem(makeKey('refreshToken'), refreshToken);
    localStorage.setItem(makeKey('clockDrift'), '0');
  }

  /**
   * Attempts to set up an authentic Cognito user using the session tokens that were stored in local storage
   * after the last successful sign-in
   */
  checkForAuthenticatedUser() {
    this.logger.trace('AUTHN: attempting to read Cognito user.');

    this.currentUser().then((cognitoUser) => {
      this.setAuthenticatedIdentity(cognitoUser);
    });
  }

  /**
   * After successful authentication, extract the cognito settings and persist to models.
   */
  private setAuthenticatedIdentity(currentUser: GetCurrentUserOutput | undefined, signingOut = false) {
    if (currentUser === undefined) {
      this.logger.trace('AUTHN: user is not authenticated and will be prompted to sign-in.');

      this.authenticationState.activeIdentity = undefined;
      this.navigateToSignIn(false, signingOut);
      return;
    }

    this.logger.trace('AUTHN: found authenticated user - resolving user details from: ', currentUser);

    this.authenticationState.activeIdentity = <UserIdentity>{
      id: currentUser.userId,
      email: currentUser.username
    };
  }

  /**
   * possible to arrive here during an APP_INITIALIZER, so we need to pull the Router from the Injector directly
   */
  private ensureRouter() {
    if (!this.router) this.router = this.injector.get<Router>(Router);
    if (!this.route) this.route = this.injector.get<ActivatedRoute>(ActivatedRoute);
  }

  navigateToSignIn(force = true, signingOut = false) {
    this.ensureRouter();

    if (this.initialUrl.includes('user-invitations/')) return;

    if (this.initialUrl.includes('/ios-employee-hub')) {
      // Will only happen from the iOS Employee Hub app which uses WKWebView and therefore has its own local storage
      // Shouldn't affect users then logging into the app normally from a different browser on the same device
      // This means that we don't need to remove it once it's been set

      this.appHostService.setIOS();
    }

    // only redirect if someone hasn't landed on an anon route e.g. reset password
    const isAnAuthRoute = this.initialUrl.includes('auth/');
    // remember the app entry path to redirect to after successful sign-in
    if (!isAnAuthRoute && !signingOut) this.authenticationState.landingRoute = this.initialUrl;
    // /auth routes target the auxiliary outlet 'top' in app.component
    if (!isAnAuthRoute || force)
      this.router.navigate(['/auth', { outlets: { right: null, modal: null, message: null, top: ['sign-in'] } }]).then();
  }

  /**
   * Set the authenticated user in models (triggers further lookups see also {@link ActiveAuthenticationStateService})
   */
  private onAuthenticated = (): Promise<void> => {
    return new Promise<void>((resolve) => {
      this.logger.trace('AUTH: user is now authenticated.');

      this.currentUser().then((cognitoUser) => {
        this.setAuthenticatedIdentity(cognitoUser);
        resolve();
      });
    });
  };

  /**
   * STEP 1: sign-into Cognito using the provided security credentials, and potentially handle MFA,
   * or handle unknown credentials.
   */
  userSignIn(username: string, password: string): Promise<void> {
    return new Promise<void>((resolve) => {
      this.authenticationState.inFlight = true;
      const input: SignInInput = {
        username,
        password,
        options: {
          authFlowType: 'CUSTOM_WITH_SRP'
        }
      };
      signIn(input)
        .then((output) => {
          this.logger.trace('AUTHN: sign-in user', output);

          this.handleAuthChallenge(username, output).then(() => resolve());
        })
        .catch((err: any) => {
          this.logger.trace('AUTHN SIGN-IN: Login failed: ', err);
          this.messagingService.warn('The username/password combination was not recognised', null, 'ok', {
            horizontalPosition: 'start',
            duration: 5000
          });
          resolve();
        })
        .finally(() => (this.authenticationState.inFlight = false));
    });
  }

  private handleAuthChallenge(username: string, signInOutput: SignInOutput): Promise<void> {
    return new Promise<void>((resolve) => {
      const nextStep = signInOutput.nextStep;
      if (nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {
        // new user; password reset required...
        this.logger.trace('AUTHN: New password required');
        this.authenticationState.completePasswordOptions = {
          username,
          signInOutput
        };
        resolve();
      } else if (nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE') {
        // MFA code via mobile
        this.logger.trace('AUTHN: MFA required');

        this.authenticationState.signInMfaOptions = {
          signInOutput,
          challengeMobileNumber: (signInOutput.nextStep as any).additionalInfo.phone
        };
        resolve();
      } else {
        // All good...
        this.onAuthenticated().then(() => resolve());
      }
    });
  }

  /**
   * STEP 1 (fork): first sign-in - must create new password
   */
  completeNewPassword(username: string, newPassword: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.authenticationState.inFlight = true;
      confirmSignIn({ challengeResponse: newPassword }).then(
        // successful ...
        (signInOutput: ConfirmSignInOutput) => {
          this.logger.trace('AUTHN: sign-in user', signInOutput);

          this.messagingService.success('Password successfully created.');

          this.userSignIn(username, newPassword).then(() => {
            this.authenticationState.completePasswordOptions = undefined;
            resolve();
          });
        },
        // rejected ...
        (reason) => {
          this.authenticationState.inFlight = false;

          this.logger.trace('AUTHN SIGN-IN: Complete password failed: ', reason);
          console.warn(reason);

          this.messagingService.warn(reason.message, null, 'ok', { horizontalPosition: 'start' });
          if (reason.code === 'NotAuthorizedException' && reason.message === 'Invalid session for the user, session is expired') {
            this.navigateToSignIn();
            resolve();
          } else if (reason.code === 'ExpiredCodeException') {
            this.messagingService.warn(reason.message, null, 'ok', { horizontalPosition: 'start' });
            resolve();
          }

          reject();
        }
      );
    });
  }

  /**
   * STEP 2: sign-into Cognito using MFA
   */
  signInMfa(mfaCode: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.authenticationState.inFlight = true;
      confirmSignIn({ challengeResponse: mfaCode }).then(
        // successful ...
        () =>
          this.onAuthenticated().then(() => {
            this.authenticationState.inFlight = false;
            resolve();
          }),
        // rejected ...
        (reason) => {
          this.authenticationState.inFlight = false;

          this.logger.trace('AUTHN SIGN-IN: MFA failed: ', reason);
          console.warn(reason);

          this.messagingService.warn(reason.message, null, 'ok', { horizontalPosition: 'start' });
          if (reason.code === 'NotAuthorizedException' && reason.message === 'Invalid session for the user, session is expired') {
            this.navigateToSignIn();
          } else if (reason.code === 'ExpiredCodeException') {
            this.messagingService.warn(reason.message, null, 'ok', { horizontalPosition: 'start' });
          } else {
            this.messagingService.warn('The security code was not recognised.', null, 'ok', { horizontalPosition: 'start' });
          }
          resolve();
        }
      );
    });
  }

  /**
   * Sign-out the current user and navigate to the login form
   */
  signOutAndRedirect = () => {
    signOut()
      .then(() => {
        this.logger.trace('AUTH: user was signed out');
        localStorage.removeItem('organisationId');
        localStorage.removeItem('privateDetails');
        localStorage.removeItem('impersonatedUserId');
        localStorage.removeItem('activeProduct');
        this.authenticationState.landingRoute = undefined;
        this.setAuthenticatedIdentity(undefined, true);
      })
      .then(() => {
        window.location.reload();
      });
  };

  /**
   * Sign-out the current user
   */
  userSignOut = () => {
    signOut().then(() => {
      localStorage.removeItem('organisationId');
      localStorage.removeItem('privateDetails');
      localStorage.removeItem('impersonatedUserId');
      localStorage.removeItem('activeProduct');
      this.authenticationState.landingRoute = undefined;
      this.authenticationState.activeIdentity = undefined;
    });
  };
}
