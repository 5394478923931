<mat-form-field class="dataset-search">
  <app-form-label [requiredField]="required" *ngIf="formLabel">{{ formLabel }}</app-form-label>
  <mat-select [formControl]="formControl" [placeholder]="label" panelClass="dataset-search-ahead">
    <mat-option>
      <ngx-mat-select-search
        [placeholderLabel]="label"
        [noEntriesFoundLabel]="noResultsText"
        [formControl]="searchInput"
        tid="selectSearch"
      ></ngx-mat-select-search>
    </mat-option>
    <mat-option *ngFor="let item of filteredItems$ | async" [value]="dataset.getValue(item)">
      {{ dataset.getLabel(item) }}
    </mat-option>
  </mat-select>
</mat-form-field>
<ng-container *ngIf="noResults$ | async">
  <div class="pt-2 pb-2"><app-body>No items matched the search criteria.</app-body></div>
</ng-container>
