import { ChangeDetectionStrategy, Component, HostListener, Input, OnInit } from '@angular/core';
import { Summary } from './summary';
import { IconConfig } from '@design/buttons/icon/icon-config';
import { AbstractInfoTileComponent } from '../../layout/info-tile/abstract-info-tile.component';

@Component({
  selector: 'app-summary-tile',
  templateUrl: './summary-tile.component.html',
  styleUrls: ['./summary-tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SummaryTileComponent extends AbstractInfoTileComponent implements OnInit {
  @Input() summary: Summary;
  values!: [string, string][];

  ngOnInit(): void {
    this.values = Array.from(this.summary.values);
  }

  nextIconConfig: IconConfig = {
    iconName: 'arrow_forward',
    backgroundType: 'circle-system-blue'
  };

  onMouseOver() {
    this.nextIconConfig = { ...this.nextIconConfig, backgroundType: 'circle-white', color: 'system-blue' };
  }

  onMouseLeave() {
    this.nextIconConfig = { ...this.nextIconConfig, backgroundType: 'circle-system-blue', color: 'white' };
  }

  navigate(o) {
    window.alert(o);
  }
}
