import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { KeyStat } from '../key-stat-tile/key-stat';

@Component({
  selector: 'app-key-stat-group',
  templateUrl: './key-stat-tile-group.component.html',
  styleUrls: ['./key-stat-tile-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KeyStatTileGroupComponent {
  @Input() maxCols = 3;
  @Input() keyStats: KeyStat[];
}
