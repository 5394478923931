/**
 * This is a simple deep clone using json conversion. It will deep clone properties, arrays and objects but NOT functions.
 * If function cloning is required use lodash/cloneDeep.
 */
export const deepClone = <T = any>(obj: T): T => JSON.parse(JSON.stringify(obj));

export const isObject = (o) => o != null && o.constructor.name === 'Object';

export const isDate = (o) => o != null && o.constructor.name === 'Date';

export const isArray = (o) => Array.isArray(o);

export const isEmpty = (val) => val === null || val === undefined || val.toString().trim().length === 0;

export const isNumeric = (val) => !isNaN(val);

export function toDictionary(values: (string | number)[]): { [id: string]: boolean } {
  const dictionary: { [id: string]: boolean } = {};

  for (const value of values) {
    dictionary[value] = true;
  }

  return dictionary;
}
