import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PopoverComponent implements OnInit {
  @Input() header?: string;
  @Input() matIcon?: string;
  @Input() linkText?: string;
  @Input() onHover = false;
  @Input() preferDisplayAbove: boolean = false;
  @Input() popoverClass = 'popover';
  @Input() enabled: boolean = true;
  @Input() preferDisplayRight: boolean = false;
  @Input() preferDisplayLeft: boolean = false;

  placement: string = 'bottom top';

  ngOnInit() {
    if (this.preferDisplayAbove) {
      this.placement = 'top bottom';
    }

    if (this.preferDisplayRight) {
      this.placement = 'right auto';
    }

    if (this.preferDisplayLeft) {
      this.placement = 'left auto';
    }
  }
}
