import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR, RequiredValidator } from '@angular/forms';
import { AbstractDatasetComponent } from '@design/forms/abstract-dataset.component';
import { toKebabCase } from '@utils/string-utils';
import { Observable } from 'rxjs';
import { LoggingService } from '@logging/logging.service';

@Component({
  selector: 'app-dataset-checkboxes',
  templateUrl: './dataset-checkboxes.component.html',
  styleUrls: ['./dataset-checkboxes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatasetCheckboxesComponent),
      multi: true
    },
    RequiredValidator
  ]
})
export class DatasetCheckboxesComponent<TItem, TValue> extends AbstractDatasetComponent<TItem, TValue> {
  @Input() checkCounts: string[];

  getTid = (item: TItem): string => {
    return toKebabCase(`${this.dataset.getValue(item).toString()}-check`);
  };

  countItems = (index: number): string => {
    if (this.checkCounts.length >= index) {
      return this.checkCounts[index];
    }
    return '0';
  };
}
