import { ChangeDetectorRef, Directive, Inject, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FeatureFlagBooleanValue, FeatureFlagKey, FeatureFlagService, FeatureFlagsServiceInjectionToken } from './feature-flag.service';

/**
 * Structural directive to show content based on feature flag config.
 *
 * Usage:
 *    <div *ifFeatureFlagged="'unitTestFeatureFlag'">hello</div>
 *    <div *ifFeatureFlagged="'unitTestFeatureFlagOff'; showIf: 'off'">hello again</div>
 */
@UntilDestroy()
// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: '[ifFeatureFlagged]' })
export class IfFeatureFlaggedDirective implements OnInit {
  @Input() ifFeatureFlagged: FeatureFlagKey;
  // see far out convention: https://stackoverflow.com/questions/41789702/how-to-use-angular-structural-directive-with-multiple-inputs
  @Input() ifFeatureFlaggedShowIf: FeatureFlagBooleanValue = 'on';

  constructor(
    private changes: ChangeDetectorRef,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    @Inject(FeatureFlagsServiceInjectionToken) private featureFlagService: FeatureFlagService
  ) {}

  ngOnInit(): void {
    if (this.ifFeatureFlagged === 'none') {
      this.checkFlag(undefined);
      return;
    }

    this.featureFlagService
      .getValue$(this.ifFeatureFlagged)
      .pipe(untilDestroyed(this))
      .subscribe((v) => {
        this.checkFlag(v as boolean);
      });
  }

  private checkFlag(configValue: boolean): void {
    this.viewContainer.clear();
    const directiveValue = this.ifFeatureFlaggedShowIf === 'on';
    if (this.ifFeatureFlagged === 'none' || configValue === directiveValue) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
    this.changes.markForCheck();
  }
}
