import { ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import { AbstractFormFieldComponent } from '../abstract-form-field.component';
import { AbstractDataset } from '@entity-framework/datasets/abstract-dataset';
import { MatSelect } from '@angular/material/select';

export type SelectTheme = 'standard' | 'dark' | 'white';

@Component({
  selector: 'app-dataset-select',
  templateUrl: './dataset-select.component.html',
  styleUrls: ['../_mat-select-overrides.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatasetSelectComponent<TItem, TValue> extends AbstractFormFieldComponent {
  @Input() dataset!: AbstractDataset<TItem, TValue>;
  @Input() multiple = false;
  @Input() type?: string;
  @Input() selectTheme: SelectTheme = 'standard';
  @Input() formLabel?: string;
  @Input() useEmptyOption: boolean = false;
  @Input() maxSelectedItemsToDisplay = 1;
  @Input() showAsRequired = false;
  @Input() hierarchicalDataset = false;
  @Input() hideContainer = false;

  @ViewChild(MatSelect) select: MatSelect;

  onContainerClick() {
    this.select.focus();
    this.select.open();
  }

  getSelectedValues(): string[] {
    const values: any[] = this.formControl.value;
    return this.select && values ? this.select.options.filter((x) => values.includes(x.value)).map((x) => x.viewValue) : [];
  }

  getHierarchyDepth(item: any): number {
    return this.hierarchicalDataset ? item.hierarchyDepth : 0;
  }

  getItemClass(index: number): string {
    if (!this.hierarchicalDataset) return '';

    let itemClasses = new Array<string>();

    const item = this.dataset.items[index];
    const prevItem = index > 0 ? this.dataset.items[index - 1] : null;
    const nextItem = index < this.dataset.items.length - 1 ? this.dataset.items[index + 1] : null;

    const itemDepth = this.getHierarchyDepth(item);

    itemClasses.push(`hierarchy hierarchy-level-${itemDepth}`);

    if (prevItem) {
      const prevItemDepth = this.getHierarchyDepth(prevItem);

      if (prevItemDepth < itemDepth) itemClasses.push('hierarchy-level-first');
    }

    if (nextItem) {
      const nextItemDepth = this.getHierarchyDepth(nextItem);

      if (nextItemDepth != itemDepth) itemClasses.push('hierarchy-level-last');

      if (nextItemDepth > itemDepth) itemClasses.push('hierarchy-parent');
    }

    return itemClasses.join(' ');
  }
}
