import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogComponentData, ConfirmDialogMessageData } from './confirm-dialog-data';

/**
 * Standard inline replacement for javascript confirm().
 * A useful dialog in which to capture a binary response from the user.
 * See {@link OnscreenMessagingService#openConfirmationDialog} for usage
 */
@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogMessageData | ConfirmDialogComponentData
  ) {}
  onNoClick(): void {
    this.dialogRef.close();
  }
}
