import { AfterViewInit, Directive, ElementRef, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, FormGroupDirective, ValidatorFn } from '@angular/forms';

import { toKebabCase } from '@utils/string-utils';
import { UntilDestroy } from '@ngneat/until-destroy';

@Directive()
@UntilDestroy()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class AbstractFormComponent implements OnInit, AfterViewInit {
  protected otherValidators: ValidatorFn[] = [];

  formGroup: UntypedFormGroup;
  formControl = new UntypedFormControl();

  @Input() tid = '';
  @Input() label: string;
  @Input() hideLabel = false;
  @Input() placeholder: string;
  @Input() formPropertyName: string;
  @Input() readonlyField = false;
  @Input() requiredField = false;
  @Input() emailField = false;

  constructor(private elementRef: ElementRef, private formGroupDirective: FormGroupDirective) {}

  ngOnInit() {
    this.formGroup = this.formGroupDirective.control;
    this.formControl = this.formGroup.get(this.formPropertyName) as UntypedFormControl;
  }

  ngAfterViewInit(): void {
    if (this.otherValidators.length) {
      const validators = [...this.otherValidators];
      if (this.formControl.validator) validators.push(this.formControl.validator);
      this.formControl.setValidators(validators);
    }

    this.createTestIdentificationAttribute();
  }

  private createTestIdentificationAttribute() {
    const id = toKebabCase(this.tid);
    this.elementRef.nativeElement.setAttributeNode(document.createAttribute(`data-tid-${id}-field`));
  }
}
