<span matSnackBarLabel class="message-box custom-snack-bar-span">
  <span class="custom-snack-bar-span">
    <mat-icon class="circle-icon">check_circle</mat-icon>
  </span>
  <span class="custom-snack-bar-span">{{ data }}</span>
</span>
<span class="close-action custom-snack-bar-span" matSnackBarActions>
  <button mat-button matSnackBarAction class="close-button" (click)="snackBarRef.dismissWithAction()">
    <app-icon color="primary-action" hoverColor="primary-action" class="close-icon">close</app-icon>
  </button>
</span>
