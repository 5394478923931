import { Routes } from '@angular/router';

// modal routes only work at the root level...

export const employeeHubModalRoutes: Routes = [
  {
    path: 'holiday-request',
    outlet: 'modal',
    loadChildren: () =>
      import('@app/employee-hub/employee-leave/my-leave/holiday-request-record/holiday-request.module').then((m) => m.HolidayRequestModule)
  },
  {
    path: 'approved-holiday',
    outlet: 'modal',
    loadChildren: () =>
      import('@app/employee-hub/employee-leave/leave-approvals/approved-holiday/approved-holiday.module').then(
        (m) => m.ApprovedHolidayModule
      )
  },
  {
    path: 'holiday-approvals/:holidayRequestId/decline',
    outlet: 'modal',
    loadChildren: () =>
      import('@app/employee-hub/employee-leave/leave-approvals/grid/decline/decline-holiday-request.module').then(
        (m) => m.DeclineHolidayRequestModule
      )
  },
  {
    path: 'holiday-approvals/:holidayRequestId',
    outlet: 'modal',
    loadChildren: () =>
      import('@app/employee-hub/employee-leave/leave-approvals/holiday-request/holiday-request-modal.module').then(
        (m) => m.HolidayRequestModalModule
      )
  },
  {
    path: 'absence-approvals/:absenceRequestId',
    outlet: 'modal',
    loadChildren: () =>
      import('@app/employee-hub/employee-leave/leave-approvals/absence-request/absence-request-modal.module').then(
        (m) => m.AbsenceRequestModalModule
      )
  },
  {
    path: 'edit-my-profile/:recordType',
    outlet: 'modal',
    loadChildren: () => import('./my-profile/edit/edit-my-profile.module').then((m) => m.EditMyProfileModule)
  },
  {
    path: 'edit-approver-sickness/:sicknessId',
    outlet: 'modal',
    loadChildren: () =>
      import('@app/employee-hub/employee-leave/leave-approvals/approver-sickness/approver-sickness.module').then(
        (m) => m.ApproverSicknessModule
      )
  },
  {
    path: 'sickness-request',
    outlet: 'modal',
    loadChildren: () => import('./employee-leave/my-leave/sickness/my-sickness.module').then((m) => m.MySicknessModule)
  },
  {
    path: 'absence-request',
    outlet: 'modal',
    loadChildren: () => import('./employee-leave/general-absence/absence-request.module').then((m) => m.AbsenceRequestModule)
  },
  {
    path: 'approved-absence',
    outlet: 'modal',
    loadChildren: () =>
      import('./employee-leave/general-absence/approved-absence/approved-absence.module').then((m) => m.ApprovedAbsenceModule)
  }
];
