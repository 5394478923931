import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnChanges, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DateTime } from 'luxon';
import { sortBy } from 'lodash';
import { CalendarDayEvents, isSunday, isWeekend, weekdays } from '../state/calendar';
import { CalendarNavigatorStateService } from '../state/calendar-navigator.state-service';
import { PersonCalendarEvents, TeamCalendarClickEvent, TeamCalendarEvents } from '../state/team-calendar';
import { ActionMenuItemConfig } from '@design/buttons/action-config';
import { TeamEventData } from '@design/components/events-calendar/team-events/team-event-data';

@UntilDestroy()
@Component({
  selector: 'app-team-events',
  templateUrl: './team-events.component.html',
  styleUrls: ['./team-events.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TeamEventsComponent implements OnChanges {
  private today = DateTime.utc().startOf('day');
  startDate: DateTime;
  private endDate: DateTime;
  calendarMonth: DateTime[];

  @Input() displayMonthNavigator = true;
  @Input() canClickAvailableDays = false;

  constructor() {
    this.navigatorStateService.activeDate$.pipe(untilDestroyed(this)).subscribe((activeDate) => {
      this.startDate = activeDate.startOf('month');
      this.endDate = activeDate.endOf('month');
      this.calendarMonth = this.makeCalendarMonth();
    });
  }

  navigatorStateService = inject(CalendarNavigatorStateService);
  weekdays = weekdays;

  @Input() activePersonId?: number;

  _highlightedPersonId?: number;
  @Input() set highlightedPersonId(highlightedPersonId: number) {
    this._highlightedPersonId = highlightedPersonId;
  }

  @Input() set teamEvents(teamEvents: TeamCalendarEvents) {
    const teamEventData = new TeamEventData();
    teamEventData.teamEvents = teamEvents;
    this.teamEventData = teamEventData;
  }

  @Input() teamEventData: TeamEventData;
  @Input() actionMenuItems: ActionMenuItemConfig[];

  @Output() dayClick$: EventEmitter<TeamCalendarClickEvent> = new EventEmitter();

  ngOnChanges() {
    if (!!this.teamEventData.teamEvents) {
      this.teamEventData.teamEvents.forEach((te) => {
        te.isColleague = this.activePersonId !== te.iqPersonId;
        te.unHighlighted = this._highlightedPersonId !== te.iqPersonId;
      });
      this.teamEventData.teamEvents = sortBy(this.teamEventData.teamEvents, ['unHighlighted', 'isColleague', 'forename', 'surname']);
    }
    if (!!this.teamEventData.subordinateEvents) {
      this.teamEventData.subordinateEvents.forEach((te) => {
        te.isColleague = this.activePersonId !== te.iqPersonId;
        te.unHighlighted = this._highlightedPersonId !== te.iqPersonId;
      });
      this.teamEventData.subordinateEvents = sortBy(this.teamEventData.subordinateEvents, [
        'unHighlighted',
        'isColleague',
        'forename',
        'surname'
      ]);
    }
  }

  getHeaderDayCss = (day: DateTime): Record<string, boolean> => ({
    'is-weekend': isWeekend(day.weekday),
    'is-sunday': isSunday(day.weekday),
    today: this.today.equals(day)
  });

  getRowEventCss = (personEvents: any): Record<string, boolean> => {
    const isRowSelected =
      (this.activePersonId != undefined && this.activePersonId === personEvents.iqPersonId) ||
      (this._highlightedPersonId != undefined && this._highlightedPersonId === personEvents.iqPersonId);
    return {
      'row-selected': isRowSelected
    };
  };

  dayClick(calendarDay: CalendarDayEvents, personCalendarEvents: PersonCalendarEvents) {
    if (this.actionMenuItems == undefined || this.actionMenuItems.length == 0 || calendarDay?.hasEvent) {
      const event: TeamCalendarClickEvent = { ...calendarDay, ...personCalendarEvents };
      this.dayClick$.emit(event);
    }
  }

  private makeCalendarMonth(): DateTime[] {
    let date = this.startDate;
    const calendarMonth = [];
    while (date <= this.endDate) {
      calendarMonth.push(date);
      date = date.plus({ days: 1 });
    }
    return calendarMonth;
  }
}
