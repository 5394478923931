import { Injectable, OnDestroy } from '@angular/core';
import { createStore } from '@ngneat/elf';
import { OnscreenMessagingService } from '@pattern-library/onscreen-messaging/onscreen-messaging.service';
import { FinancialsUpdatedService } from '@shared-update-services';
import { finalize, take } from 'rxjs';
import { selectInFlight, updateInFlight, withInFlight } from '@app/shared/state';
import { PayrollIdentifier } from '../models';
import { RecalculationDataProvider, RecalculationResponse } from './recalculation.data-provider';

@Injectable()
export class RecalculationStateService implements OnDestroy {
  private store = createStore({ name: 'recalculation' }, withInFlight());

  inFlight$ = this.store.pipe(selectInFlight());

  constructor(
    private messagingService: OnscreenMessagingService,
    private dataProvider: RecalculationDataProvider,
    private financialsUpdatedService: FinancialsUpdatedService
  ) {}

  recalculate(payrollId: PayrollIdentifier) {
    this.store.update(updateInFlight(true));
    this.dataProvider
      .recalculate$(payrollId)
      .pipe(
        take(1),
        finalize(() => this.store.update(updateInFlight(false)))
      )
      .subscribe((result: RecalculationResponse) => {
        if (result?.calcInProgress) {
          this.messagingService.warn(
            'Another member of your team was recalculating the payroll at the same time.',
            () => {
              this.recalculate(payrollId);
            },
            'TRY AGAIN',
            {
              duration: 15000
            }
          );
        } else {
          this.financialsUpdatedService.trigger();
          this.messagingService.success('Your payroll total is now up to date');
        }
      });
  }

  ngOnDestroy(): void {
    this.store.destroy();
  }
}
