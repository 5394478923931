import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { KeyStat } from './key-stat';
import { AbstractInfoTileComponent } from '../../layout/info-tile/abstract-info-tile.component';

@Component({
  selector: 'app-key-stat-tile',
  templateUrl: './key-stat-tile.component.html',
  styleUrls: ['./key-stat-tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KeyStatTileComponent extends AbstractInfoTileComponent {
  @Input() keyStat: KeyStat;
}
