import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AbstractIdentifiable } from '../state/abstract-entity';
import { LoggingService } from '@logging/logging.service';

export abstract class AbstractEntityDataProvider<TEntity extends AbstractIdentifiable> {
  protected abstract apiRoot: string;
  protected parentPath?: string;
  public childId?: string;

  protected constructor(protected httpClient: HttpClient, protected logger: LoggingService) {}

  /**
   * Overridden in implementations, typically by the id when in RUD.
   */
  setParentPath(parentId: any): void {
    this.parentPath = undefined;
  }

  protected getBaseEndpoint(): string {
    const apiPath = `${this.apiRoot}`;
    return this.parentPath?.toString().length ? `${apiPath}/${this.parentPath}` : apiPath;
  }

  protected getEndpoint(entity?: TEntity): string {
    // this method can be overridden in order to use the passed entity
    const base = this.getBaseEndpoint();
    return this.childId ? `${base}/${this.childId}` : base;
  }

  read$(params?: any, responseType: any = 'json'): Observable<TEntity> {
    const endpoint = this.getBaseEndpoint();
    this.logger.trace('AbstractEntityDataProvider: read', endpoint);
    return this.httpClient.get<TEntity>(endpoint, { params, responseType });
  }

  create$(entity: TEntity): Observable<AbstractIdentifiable> {
    const endpoint = this.getBaseEndpoint();
    this.logger.trace('AbstractEntityDataProvider: create', endpoint, entity);
    return this.httpClient.post<AbstractIdentifiable>(endpoint, entity);
  }

  update$(entity: TEntity): Observable<void> {
    const endpoint = this.getEndpoint();
    this.logger.trace('AbstractEntityDataProvider: update', endpoint, entity);
    return this.httpClient.put<void>(endpoint, entity);
  }

  delete$ = (): Observable<void> => {
    const endpoint = this.getEndpoint();
    this.logger.trace('AbstractEntityDataProvider: delete', endpoint);
    return this.httpClient.delete<void>(endpoint);
  };
}
