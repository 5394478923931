import { Injectable } from '@angular/core';

type AppHost = 'Web Browser' | 'iOS native app' | 'Android native app';

@Injectable({ providedIn: 'root' })
export class AppHostService {
  constructor() {}

  get appHost(): AppHost {
    return (localStorage.getItem('appHost') ?? 'Web Browser') as AppHost;
  }

  set appHost(appHost: AppHost) {
    localStorage.setItem('appHost', appHost);
  }

  isIOS() {
    return this.appHost === 'iOS native app';
  }

  setIOS() {
    this.appHost = 'iOS native app';
  }
}
