<div class="container-fluid g-0" id="static-header" *ngIf="showStaticHeader">
  <div class="row g-0">
    <div class="col">
      <div class="container g-0 inner-content">
        <div class="row g-0">
          <div [ngClass]="cols()">
            <ng-content select="[static-header]"></ng-content>
            <hr />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div id="wa-content" (scroll)="onScroll($event)" [ngClass]="{ 'has-static-header': showStaticHeader }">
  <div class="container-fluid g-0" id="top-content" *ngIf="!hideTop" [ngClass]="topNgClass">
    <div class="row g-0">
      <div class="col">
        <div class="container g-0 inner-content">
          <div class="row g-0">
            <div [ngClass]="cols()">
              <ng-content select="[top]"></ng-content>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid" id="bottom-content" *ngIf="!hideBottom" [ngStyle]="{ 'padding-top': bottomPaddingTop }">
    <div class="row">
      <div class="col">
        <div class="inner-content">
          <div class="row">
            <div [ngClass]="cols()">
              <ng-content select="[bottom]"></ng-content>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
