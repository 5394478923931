import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-variance-pill',
  styleUrls: ['./variance-pill.component.scss'],
  templateUrl: './variance-pill.component.html'
})
export class VariancePillComponent {
  @Input() variance: number;
  @Input() showTrendingFlatWhenZero: boolean = false;
}
