import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-in-flight-spinner',
  templateUrl: './in-flight-spinner.component.html',
  styleUrls: ['./in-flight-spinner.component.scss']
})
export class InFlightSpinnerComponent {
  @Input() fullSize = false;
}
