import { Directive, inject, OnDestroy } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { createStore, select, setProps, withProps } from '@ngneat/elf';
import { DateTime } from 'luxon';
import { CalendarEventsList, CalendarMonthOfDayEvents, CalendarMonthWeeks, EventModel } from './calendar';
import { CalendarNavigatorStateService } from './calendar-navigator.state-service';

export interface CalendarComponentState {
  calendarEventsList?: CalendarEventsList;
  calendarMonth?: CalendarMonthWeeks | CalendarMonthOfDayEvents;
  calendarDate?: DateTime;
}

@Directive()
export abstract class AbstractCalendarComponentStateService implements OnDestroy {
  protected readonly today = DateTime.utc().startOf('day');

  protected readonly store = createStore({ name: 'ElfCalendarComponentStateStore' }, withProps<CalendarComponentState>({}));

  protected navigatorStateService = inject(CalendarNavigatorStateService);

  private calendarEvents$ = this.store.pipe(select((s) => s.calendarEventsList));

  private calendarDate$ = this.store.pipe(select((s) => s.calendarDate));

  protected state$ = combineLatest([this.calendarEvents$, this.calendarDate$]);

  calendarMonthEvents$ = new BehaviorSubject<CalendarMonthWeeks | CalendarMonthOfDayEvents>([]); // not in the Elf store due to streaming issue

  set eventModels(eventModels: EventModel[]) {
    const calendarEventsList = new CalendarEventsList(eventModels);
    this.store.update(setProps({ calendarEventsList }));
  }

  get calendarDate(): DateTime {
    return this.store.query((q) => q.calendarDate);
  }

  set calendarDate(calendarDate: DateTime) {
    this.store.update(setProps({ calendarDate }));
  }

  ngOnDestroy(): void {
    this.store.destroy();
  }
}
