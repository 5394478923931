import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DynamicModule } from 'ng-dynamic-component';

import { MaterialModule } from '@pattern-library/material.module';
import { AuthorisationModule } from '@security/authorization/authorisation.module';
import { FeatureFlagModule } from '@feature-flags/feature-flag.module';
import { H1Component } from './typography/h1/h1.component';
import { H2Component } from './typography/h2/h2.component';
import { H3Component } from './typography/h3/h3.component';
import { H4Component } from './typography/h4/h4.component';
import { BodyComponent } from './typography/body/body.component';
import { BodyMediumComponent } from './typography/body-md/body-medium.component';
import { BodySmallComponent } from './typography/body-sm/body-small.component';
import { FootnoteComponent } from './typography/footnote/footnote.component';
import { CallToActionComponent } from './typography/call-to-action/call-to-action.component';
import { CounterComponent } from './typography/counter/counter.component';
import { AnchorComponent } from './buttons/anchor/anchor.component';
import { ButtonComponent } from './buttons/button/button.component';
import { ContentBlockComponent } from './layout/content-block/content-block.component';
import { PComponent } from './layout/p/p.component';
import { FullscreenModalComponent } from './layout/fullscreen-modal/fullscreen-modal.component';
import { FullscreenModalContainerComponent } from './layout/fullscreen-modal/fullscreen-modal.container-component';
import { WorkareaComponent } from './layout/workarea/workarea.component';
import { InfoTileComponent } from './layout/info-tile/info-tile.component';
import { KeyStatTileComponent } from './components/key-stat-tile/key-stat-tile.component';
import { IconComponent } from './buttons/icon/icon.component';
import { TileLayoutComponent } from './layout/tile-layout/tile-layout.component';
import { KeyStatTileGroupComponent } from './components/key-stat-tile-group/key-stat-tile-group.component';
import { SummaryTileComponent } from './components/summary-tile/summary-tile.component';
import { SummaryTileGroupComponent } from './components/summary-tile-group/summary-tile-group.component';
import { ActionMenuComponent } from './menus/action-menu/action-menu.component';
import { NonStandardModalEntryComponent } from './modals/non-standard-modal/non-standard-modal-entry.component';
import { NonStandardModalTemplateComponent } from './modals/non-standard-modal/template/non-standard-modal-template.component';
import { BodyLargeComponent } from '@design/typography/body-lg/body-large.component';
import { TogglePanelComponent } from './components/toggle-panel/toggle-panel.component';
import { H5Component } from './typography/h5/h5.component';
import { DividerDotComponent } from '@design/layout/divider-dot/divider-dot.component';
import { FormLabelComponent } from '@design/typography/form-label/form-label.component';
import { TestIdDirective } from '@design/core/test-id.directive';
import { BodyExtraSmallComponent } from '@design/typography/body-xs/body-extra-small.component';
import { TabListComponent } from '@design/buttons/tab-list/tab-list.component';
import { VariancePillComponent } from './components/variance-pill/variance-pill.component';
import { PopoverComponent } from '@design/buttons/popover/popover.component';
import { EmptyResultPlaceholderComponent } from '@design/components/empty-result-placeholder/empty-result-placeholder.component';
import { InFlightSpinnerComponent } from './spinners/in-flight-spinner/in-flight-spinner.component';
import { SpinnerOverlayComponent } from './spinners/in-flight-spinner/spinner-overlay/spinner-overlay.component';
import { PreventClickPropagationDirective } from './core/prevent-click-propagation.directive';
import { ButtonGroupComponent } from '@design/components/button-group/button-group.component';
import { PresetButtonComponent } from './components/preset-button/preset-button.component';
import { MonthEventsCalendarComponent } from '@design/components/events-calendar/month-events-calendar.component';
import { StatusPillComponent } from '@design/components/status-pill/status-pill.component';
import { ResourceNotFoundComponent } from '@design/layout/resource-not-found/resource-not-found.component';
import { FluidLayoutComponent } from '@design/layout/fluid-layout/fluid-layout.component';
import { EmptyComponent } from '@design/layout/empty/empty.component';
import { CompactValuesLabelComponent } from './components/compact-values-label/compact-values.label.component';
import { DashboardWidgetTemplateComponent } from './layout/dashboard-widget-template/dashboard-widget-template.component';
import { CountStatusButtonComponent } from './components/count-status-button/count-status-button';
import { FeatureMenuItemComponent } from '@app/app-layout/header/product-navigation/navigation-bar/feature-menu-item/feature-menu-item.component';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { BodyMediumLargeComponent } from './typography/body-ml/body-medium-large.component';
import { WarningIconComponent } from '@design/layout/warning-icon/warning-icon.component';
import { ContentBlockSmallComponent } from './layout/content-block-small/content-block-small.component';
import { VerticalLayoutComponent } from './layout/form/vertical-layout/vertical-layout.component';
import { HorizontalLayoutComponent } from './layout/form/horizontal-layout/horizontal-layout.component';
import { CheckMarkComponent } from './components/check-mark/check-mark.component';
import { FullscreenSpinnerComponent } from './spinners/fullscreen-spinner/fullscreen-spinner/fullscreen-spinner.component';
import { ViewCardComponent } from './layout/view-card/view-card.component';
import { BannerComponent } from './layout/banner-layout/banner.component';
import { TeamEventsComponent } from './components/events-calendar/team-events/team-events.component';
import { PersonEventsMonthRowComponent } from './components/events-calendar/team-events/person-events-month-row/person-events-month-row.component';
import { MonthNavigatorComponent } from './components/events-calendar/month-navigator/month-navigator.component';
import { ProgressBarComponent } from './progress-bar/app-progress-bar.component';
import { ProgressBarOverlayComponent } from './progress-bar/progress-bar-overlay/progress-bar-overlay.component';
import { FormModalTemplateComponent } from '@design/modals/form-modal/template/form-modal-template.component';
import { YearPickerComponent } from './components/year-picker/year-picker.component';
import { CustomCalendarHeaderComponent } from './components/year-picker/custom-calendar-header.component';
import { DividerLineComponent } from '@design/layout/divider-line/divider-line.component';
import { BodyExtraLargeComponent } from '@design/typography/body-xl/body-extra-large.component';
import { ActionButtonsComponent } from '@design/components/action-buttons/action-buttons.component';
import { SafePipe } from '@design/core/safe-pipe/safe.pipe';
import { CalendarDayIconComponent } from '@design/components/events-calendar/calender-day-icon/calendar-day-icon.component';
import { NotificationBannerComponent } from './components/notification-banner/notification-banner.component';
import { BadgeComponent } from './layout/badge/badge.component';
import { VarDirective } from '@design/core/var.directive';

const declarations = [
  H1Component,
  H2Component,
  H3Component,
  H4Component,
  H5Component,
  BodyComponent,
  BodyExtraLargeComponent,
  BodyMediumComponent,
  BodyMediumLargeComponent,
  BodySmallComponent,
  BodyLargeComponent,
  BodyExtraSmallComponent,
  FootnoteComponent,
  CallToActionComponent,
  CheckMarkComponent,
  CompactValuesLabelComponent,
  CountStatusButtonComponent,
  CounterComponent,
  AnchorComponent,
  ButtonComponent,
  PComponent,
  ContentBlockComponent,
  ContentBlockSmallComponent,
  FullscreenModalComponent,
  FullscreenModalContainerComponent,
  WorkareaComponent,
  IconComponent,
  InfoTileComponent,
  TileLayoutComponent,
  KeyStatTileComponent,
  KeyStatTileGroupComponent,
  SummaryTileComponent,
  SummaryTileGroupComponent,
  ActionMenuComponent,
  NonStandardModalEntryComponent,
  NonStandardModalTemplateComponent,
  StatusPillComponent,
  TogglePanelComponent,
  TestIdDirective,
  VariancePillComponent,
  DividerDotComponent,
  FormLabelComponent,
  TabListComponent,
  PopoverComponent,
  EmptyResultPlaceholderComponent,
  InFlightSpinnerComponent,
  SpinnerOverlayComponent,
  PreventClickPropagationDirective,
  ProgressBarComponent,
  ButtonGroupComponent,
  PresetButtonComponent,
  MonthEventsCalendarComponent,
  MonthNavigatorComponent,
  TeamEventsComponent,
  PersonEventsMonthRowComponent,
  ResourceNotFoundComponent,
  FluidLayoutComponent,
  EmptyComponent,
  DashboardWidgetTemplateComponent,
  FeatureMenuItemComponent,
  WarningIconComponent,
  HorizontalLayoutComponent,
  VerticalLayoutComponent,
  FullscreenSpinnerComponent,
  ViewCardComponent,
  BannerComponent,
  FormModalTemplateComponent,
  YearPickerComponent,
  CustomCalendarHeaderComponent,
  ProgressBarOverlayComponent,
  DividerLineComponent,
  ActionButtonsComponent,
  SafePipe,
  CalendarDayIconComponent,
  NotificationBannerComponent,
  BadgeComponent,
  VarDirective
];

@NgModule({
  declarations,
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    DynamicModule,
    AuthorisationModule,
    FeatureFlagModule,
    NgbPopoverModule
  ],
  exports: [...declarations]
})
export class DesignSystemModule {}
