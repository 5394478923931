import { ChangeDetectionStrategy, Component, Inject, Input, ViewEncapsulation } from '@angular/core';
import { ActionMenuItemConfig } from '@design/buttons/action-config';
import { IconColor, IconSize } from '@design/buttons/icon/icon-config';
import { isActionVisible } from '@design/buttons/is-action-visible';
import { CintraThemePalette } from '@design/theme/cintra-theme-palette';
import { FeatureFlagService, FeatureFlagsServiceInjectionToken } from '@feature-flags/feature-flag.service';
import { ActiveSecurityContextStateService } from '@security/active-security/active-security-context.state-service';
import { BehaviorSubject, combineLatest, map, Observable } from 'rxjs';
import { Ink } from '@design/typography/ink';

@Component({
  selector: 'app-action-menu',
  templateUrl: './action-menu.component.html',
  styleUrl: './action-menu.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionMenuComponent {
  private _actionsSubject = new BehaviorSubject<ActionMenuItemConfig[]>([]);
  private _dataSubject = new BehaviorSubject<any>(undefined);

  visibleActions$: Observable<ActionMenuItemConfig[]> = combineLatest([
    this.featureFlagService.getAllValues$(),
    this._actionsSubject,
    this._dataSubject,
    this.activeSecurity.activeMembership$
  ]).pipe(
    map(
      ([featureFlags, actions, data, _]) =>
        actions?.filter((action) => isActionVisible({ activeSecurity: this.activeSecurity, action, featureFlags, data })) ?? []
    )
  );

  @Input() buttonTheme: CintraThemePalette = 'secondary-action';
  @Input() buttonIcon?: string = undefined;
  @Input() buttonText?: string = undefined;
  @Input() iconLeft: boolean = false;
  @Input() iconColor: IconColor;
  @Input() iconSize: IconSize;
  @Input() ink = <Ink>'ink-85';
  @Input() showButton: boolean = true;

  @Input() set actions(values: ActionMenuItemConfig[]) {
    this._actionsSubject.next(values);
  }

  @Input() set data(value: any) {
    this._dataSubject.next(value);
  }

  constructor(
    private activeSecurity: ActiveSecurityContextStateService,
    @Inject(FeatureFlagsServiceInjectionToken) protected featureFlagService: FeatureFlagService
  ) {}

  getData = () => {
    return this._dataSubject.value;
  };
}
