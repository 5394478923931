export const employeeHubRoutes = {
  dashboard: '/employee-hub/dashboard',
  holidays: '/employee-hub/my-holidays',
  payslips: '/employee-hub/payslips',
  documents: '/employee-hub/documents',
  profile: '/employee-hub/my-profile',
  holidayApprovalsRequests: 'employee-hub/holiday-approvals/requests',
  holidayApprovalsHistory: 'employee-hub/holiday-approvals/history/approved',
  holidayApprovalsEntitlements: 'employee-hub/holiday-approvals/entitlements',
  holidayApprovalsCalendar: 'employee-hub/holiday-approvals/calendar'
};
