<div *ngIf="dataset" [ngClass]="layout">
  @if (layout === 'standard') {
    <mat-label>{{ label }}</mat-label>
    <ng-container *ngTemplateOutlet="selectTemplate"></ng-container>
    <ng-container *ngTemplateOutlet="itemsTemplate"></ng-container>
  } @else {
    <div class="d-flex flex-row justify-content-between">
      <div><ng-content select="[label-content]"></ng-content></div>
      <div style="flex: 0.5"><ng-container *ngTemplateOutlet="selectTemplate"></ng-container></div>
    </div>
    <ng-container *ngTemplateOutlet="itemsTemplate"></ng-container>
  }
</div>

<ng-template #selectTemplate>
  <ng-content select="[select-help-content]"></ng-content>
  <mat-form-field appearance="fill">
    <mat-label *ngIf="selectLabel">{{ selectLabel }}</mat-label>
    <mat-select #matSelect>
      <mat-option *ngFor="let item of unselectedItems" [value]="dataset.getValue(item)" (onSelectionChange)="select($event, item)">
        {{ dataset.getLabel(item) }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</ng-template>

<ng-template #itemsTemplate>
  <mat-chip-listbox class="cintra" [multiple]="multiple">
    <ng-content></ng-content>
    <ng-container *ngIf="selectedItems$ | async as selectedItems">
      <mat-chip-option
        *ngFor="let item of selectedItems"
        [value]="item"
        [selected]="true"
        [selectable]="true"
        (click)="toggleItem(item)"
        [title]="dataset.getLabel(item)"
      >
        <span class="chip-label">{{ dataset.getLabel(item) }}</span>
        <mat-icon>clear</mat-icon>
      </mat-chip-option>
    </ng-container>
  </mat-chip-listbox>
</ng-template>
