import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BannerContentType, BannerService, BannerType } from './banner.service';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BannerComponent {
  @Input() bannerName: string;

  @Input() contentType: BannerContentType;

  @Input() bannerType: BannerType = 'warning';

  @Input() showClose: boolean = true;

  constructor(public bannerService: BannerService) {}
}
