import { Injectable, OnDestroy } from '@angular/core';
import { LoggingService } from '@logging/logging.service';
import { createStore, select, setProp, setProps, withProps } from '@ngneat/elf';
import { updateInFlight, withInFlight } from '@shared-state';
import { PayslipUpdatedService } from '@shared-update-services';
import { filter } from 'rxjs/operators';
import { PayrollDetails } from './payroll-details';
import { PayrollDetailsDataProvider } from './payroll-details.data-provider';

@Injectable()
export class PayrollDetailsStateService implements OnDestroy {
  private static storeName = 'payroll-details-models-service';

  private initialValue = {};
  private readonly store = createStore(
    { name: PayrollDetailsStateService.storeName },
    withProps(this.initialValue as PayrollDetails),
    withInFlight()
  );

  payrollDetails$ = this.store.pipe(
    select((q) => q),
    filter((v) => !v.inFlight)
  );

  constructor(
    protected dataProvider: PayrollDetailsDataProvider,
    protected payslipUpdatedService: PayslipUpdatedService,
    protected logger: LoggingService
  ) {}

  fetch(payrollId: number) {
    this.store.update(updateInFlight(true));
    this.dataProvider.readDetails$(payrollId).subscribe((details) => {
      this.logger.trace('PayrollDetailsStateService: data', details);
      this.store.update(setProps(details), updateInFlight(false));
    });
  }

  applyClutch = () => {
    const id = this.store.getValue().id;
    this.dataProvider.applyClutch$(id).subscribe(() => {
      this.logger.trace('PayrollDetailsStateService: applied clutch', id);
      this.updateLockedForEdit(true);
    });
  };

  releaseClutch = () => {
    const id = this.store.getValue().id;
    this.dataProvider.releaseClutch$(id).subscribe(() => {
      this.logger.trace('PayrollDetailsStateService: release clutch', id);
      this.updateLockedForEdit(false);
    });
  };

  updateLockedForEdit(locked: boolean) {
    this.store.update(setProp('lockedForEdit', locked));
  }

  ngOnDestroy(): void {
    this.store.destroy();
  }
}
