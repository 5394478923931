import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { StatusPillConfig } from './status-pill-config';

@Component({
  selector: 'app-status-pill',
  templateUrl: './status-pill.component.html',
  styleUrls: ['./status-pill.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StatusPillComponent {
  @Input() config: StatusPillConfig;
  @Input() w100?: boolean;

  tooltipClass = '';

  get ngClass(): string[] {
    const classes: string[] = [];

    if (this.config) {
      classes.push(this.config.ink);

      if (this.config.tooltip) {
        classes.push('cursor-pointer');
      }

      if (this.config.pillSize) {
        classes.push('size-' + this.config.pillSize);
      } else {
        classes.push('size-medium');
      }

      if (this.config.display) {
        classes.push('display-' + this.config.display);
      }

      if (this.config.usePillOutline) {
        classes.push('pill-outlined');
      }

      if (this.config.leftIcon) {
        classes.push('has-icon');
      }

      if (this.config.backgroundColor) {
        classes.push(this.config.backgroundColor);
      }

      if (this.config.tooltipClass) {
        this.tooltipClass = this.config.tooltipClass;
      }

      if (this.config.useTransparentBackground) {
        classes.push('transparent-background');
      }

      if (this.config.width100Percent) {
        classes.push('w100');
      }
    }

    return classes;
  }

  getIconSize() {
    return this.config?.pillSize == 'small' ? 'extra-small' : 'small';
  }
}
