<ng-container *ngIf="data$ | async as data">
  <div class="selector-container" *ngIf="data.payrollPeriod">
    <mat-icon [ngClass]="{ disabled: !data.canMovePrevious }" tid="move-payroll-period-back" (click)="movePayrollPeriodBack()">
      keyboard_arrow_left
    </mat-icon>
    <app-payroll-period [payrollPeriod]="data.payrollPeriod"></app-payroll-period>
    <mat-icon [ngClass]="{ disabled: !data.canMoveNext }" tid="move-payroll-period-forward" (click)="movePayrollPeriodForward()">
      keyboard_arrow_right
    </mat-icon>

    <div class="move-forward" *ngIf="data.canMoveCurrent">
      <mat-icon tid="move-to-current-period" matTooltip="Go to Current Period" (click)="moveToCurrentPayrollPeriod()">redo</mat-icon>
    </div>
  </div>
</ng-container>
