<form [formGroup]="formGroup" tid="private-organisation-login-form">
  <app-h4>Private Organisation Database Login</app-h4>
  <p>
    Enter the password to gain access
    <br />
  </p>
  <mat-form-field appearance="fill">
    <mat-label>Enter password</mat-label>
    <input
      tid="database-password-field"
      type="text"
      required
      matInput
      placeholder="Password"
      formControlName="password"
      autocomplete="off"
      title="Password"
    />
  </mat-form-field>
  <div class="footer-buttons">
    <app-button tid="sign-out-button" theme="alternate" (click)="signOut()">Sign Out</app-button>
    <app-button tid="login-button" theme="primary-action" type="submit" (click)="login()">Login</app-button>
  </div>
</form>
