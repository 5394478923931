<div class="button-group" *ngIf="actions$">
  <div
    *ngFor="let action of actions$ | async"
    [matTooltip]="disabledText || action.tooltipWhenDisabled"
    [matTooltipDisabled]="!action.disabled"
  >
    <app-button
      [theme]="action.buttonTheme ? action.buttonTheme : buttonTheme"
      elevated
      (click)="callExecute(action)"
      [tid]="action.tid"
      [disabled]="action.disabled"
      [visible]="action.visible"
      [tooltip]="action.tooltip"
      [uppercaseText]="uppercaseText"
      [showAsHyperlink]="showAsHyperlink"
    >
      <span *ngIf="action.icon" class="button-content">
        <app-icon
          [useSymbol]="action.useIconSymbol"
          [outlined]="action.useIconOutlined"
          [color]="iconColor"
          [hoverColor]="iconHoverColor"
          >{{ action.icon }}</app-icon
        >
        <app-body-md [ink]="ink" bold>{{ action.label }}</app-body-md>
      </span>
      <ng-container *ngIf="!action.icon">
        {{ action.label }}
      </ng-container>
    </app-button>
  </div>
</div>
