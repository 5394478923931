import { ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatChipSelectionChange } from '@angular/material/chips';

import { AbstractDatasetComponent } from '../abstract-dataset.component';

@Component({
  selector: 'app-dataset-chips',
  templateUrl: './dataset-chips.component.html',
  styleUrls: ['./dataset-chips.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatasetChipsComponent),
      multi: true
    }
  ]
})
export class DatasetChipsComponent<TItem, TValue> extends AbstractDatasetComponent<TItem, TValue> {
  onSelectionChange = (event: MatChipSelectionChange) => {
    if (event.isUserInput) {
      this.toggleItem(event.source.value);
    }
  };
}
