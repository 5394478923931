import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { AbstractEntityStateService } from '@entity-framework/entity-record/state/abstract-entity.state-service';
import { OnscreenMessagingService } from '@pattern-library/onscreen-messaging/onscreen-messaging.service';
import { LoggingService } from '@logging/logging.service';
import { internationalMobileNumberFormat } from '@design/forms/form-validation';
import { MyAccountModel } from './my-account.model';
import { MyAccountState } from './my-account.state';
import { MyAccountDataProvider } from './my-account.data-provider';
import { Observable } from 'rxjs';

@Injectable()
export class MyAccountStateService extends AbstractEntityStateService<MyAccountModel, MyAccountState> {
  private static storeName = 'my-account-edit';

  constructor(
    protected dataProvider: MyAccountDataProvider,
    protected route: ActivatedRoute,
    protected messaging: OnscreenMessagingService,
    protected logger: LoggingService
  ) {
    super(MyAccountStateService.storeName, dataProvider, route, messaging, logger);
  }

  setupForm(id?: string) {
    this.fetchEntityByIdAndBindFormGroup(id, this.createForm);
  }

  private createForm(user: MyAccountModel) {
    return new UntypedFormGroup({
      id: new UntypedFormControl(user.id),
      phoneNumber: new UntypedFormControl(user.phoneNumber, [Validators.pattern(internationalMobileNumberFormat), Validators.required])
    });
  }

  persist$(): Observable<any> {
    this.standardisePhoneNumber();
    return super.persist$();
  }

  private standardisePhoneNumber() {
    this.properties = {
      phoneNumber: this.entity.phoneNumber.replace('+440', '+44')
    };
  }
}
