import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { NonStandardModalService } from './non-standard-modal.service';
import { NonStandardModalOptions, standardModalStyles } from './non-standard-modal-options';
import { deepClone } from '@utils/object-utils';

/**
 * Creates a mat dialog appearing in the centre of the screen for the user to act contextually.
 * To surface a component in the dialog, use routing to target the 'modal' auxiliary router-outlet in {@link AppLayoutComponent}
 * specifying the componentType in the route config's 'data' object property
 * e.g. see {@link PayrollHubComponent} and routing.. app > employees,
 * and configured to open from a grid row action menu see {@link PayrollEmployeesGridConfig}
 *
 * LOCKED FOR EDIT - EXTEND ONLY INTO SPECIALISED SUB-TYPES
 */
@Component({
  selector: 'app-non-standard-modal-entry',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NonStandardModalEntryComponent {
  constructor(private modalService: NonStandardModalService, route: ActivatedRoute) {
    // adding params here is required because the activated route becomes out of scope in a mat dialog
    // due to the dialog being out of the component hierarchy
    const data = route.snapshot.data as NonStandardModalOptions;
    data.route = route;
    data.activeTab = route.snapshot.data.activeTab;
    data.params = route.snapshot.params; // active
    data.params$ = route.params; // future (change of stepper step)
    data.dimensions = deepClone(standardModalStyles.get(data.modalType));

    if (data.showAsWide) {
      data.dimensions.width = '120rem';
    }

    if (data.fullScreen) {
      data.dimensions.width = '100vw';
      data.dimensions.height = '100vh';
    }

    this.modalService.openDialog(data);
  }
}
