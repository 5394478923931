<app-form-label [requiredField]="requiredField" *ngIf="!hideLabel">{{ this.label }}</app-form-label>
<input
  *ngIf="type !== 'text-area'"
  matInput
  tabindex="0"
  [type]="type"
  [placeholder]="placeholder"
  [formControl]="formControl"
  [title]="label"
  [required]="requiredField"
  [readonly]="readonlyField"
  [email]="emailField"
  [step]="step"
  (blur)="onBlur()"
/>

<textarea
  *ngIf="type === 'text-area'"
  matInput
  tabindex="0"
  [type]="type"
  [placeholder]="placeholder"
  [formControl]="formControl"
  [title]="label"
  [required]="requiredField"
  [readonly]="readonlyField"
  [rows]="rows"
></textarea>

<mat-hint *ngIf="hint && hint.length">{{ hint }}</mat-hint>

<mat-error *ngIf="formControl.hasError('required') && formControl.dirty && (!hasBeenInteractedWith || formControl.touched)"
  >Required</mat-error
>
<mat-error *ngIf="formControl.hasError('email') && (!hasBeenInteractedWith || formControl.touched)">Not a valid email address</mat-error>
<mat-error *ngIf="formControl.hasError('pattern') && (!hasBeenInteractedWith || formControl.touched)">{{ invalidRegexMessage }}</mat-error>
<mat-error *ngIf="formControl.hasError('minlength') && (!hasBeenInteractedWith || formControl.touched)"
  >Minimum {{ minLength }} {{ type === 'number' ? 'digits' : 'characters' }}</mat-error
>
<mat-error *ngIf="formControl.hasError('maxlength') && (!hasBeenInteractedWith || formControl.touched)"
  >Maximum {{ maxLength }} {{ type === 'number' ? 'digits' : 'characters' }}</mat-error
>
<mat-error *ngIf="formControl.hasError('min') && (!hasBeenInteractedWith || formControl.touched)">Minimum value {{ minValue }}</mat-error>
<mat-error *ngIf="formControl.hasError('max') && (!hasBeenInteractedWith || formControl.touched)">Maximum value {{ maxValue }}</mat-error>
<mat-error *ngIf="formControl.hasError('decimalPlaces') && (!hasBeenInteractedWith || formControl.touched)"
  >Maximum {{ decimalPlaces }} decimal places</mat-error
>
