import { NgxLoggerLevel } from 'ngx-logger';
import { LogLevel } from 'configcat-common';

import pkg from '../../package.json';
const { name, version } = pkg;
import { EnvironmentSettings } from './environment-settings';
import { ApiPaths, EmployeeHubApiPaths, IApiPaths } from './api-paths';
import { ResourcesConfig } from '@aws-amplify/core';

const apiRoot = 'https://api.cintra.cloud/';
const apiPaths: ApiPaths = {
  authentication: `${apiRoot}auth`,
  activeSecurity: `${apiRoot}auth-n`,
  users: `${apiRoot}users`,
  cintraEmployees: `${apiRoot}cintra-employees`,
  featurePacks: `${apiRoot}feature-packs`,
  userInvitationRegister: `${apiRoot}user-invitations`,
  myAccount: `${apiRoot}my-account`,
  organisations: `${apiRoot}organisations`,
  organisation: `${apiRoot}organisation`,
  onboarding: `${apiRoot}onboarding`,
  employers: `${apiRoot}employers`,
  messaging: `${apiRoot}messaging`,
  payrolls: `${apiRoot}payrolls`,
  employees: `${apiRoot}employees`,
  schemas: `${apiRoot}schemas`,
  costCodes: `${apiRoot}cost-codes`,
  payrollReports: `${apiRoot}payroll-reports`,
  payrollReportPack: `${apiRoot}payroll-reports/pack`,
  positions: `${apiRoot}positions`,
  accessControl: `${apiRoot}access-control`,
  equifax: `${apiRoot}equifax`,
  integrations: `${apiRoot}integrations`,
  reporting: `${apiRoot}reporting`,
  bureau: `${apiRoot}access-control/bureau`
};

const iapiRoot = 'https://integrations.cintra.cloud/integrations/';
const iapiPaths: IApiPaths = {
  events: `/integrationEvents/`
};

const employeeHubApiRoot = 'https://employee-hub-api.cintra.cloud/';
const employeeHubApiPaths: EmployeeHubApiPaths = {
  schemas: `${employeeHubApiRoot}schemas`,
  adminHub: `${employeeHubApiRoot}admin-hub`,
  employeeLeave: `${employeeHubApiRoot}employee-leave`
};

const cognitoConfig: ResourcesConfig = {
  Auth: {
    Cognito: {
      userPoolId: 'eu-west-2_JcqJ6qymX',
      userPoolClientId: '1k0ghh16oiofht0elmqbmaphk7'
    }
  }
};

export const environment: EnvironmentSettings = {
  environment: 'production',
  appName: name,
  appVersion: version,
  production: true,
  cognitoConfig: cognitoConfig,
  loggingConfig: {
    threshold: NgxLoggerLevel.WARN,
    logToRollbar: true
  },
  idleActivityConfig: {
    enabled: true,
    trace: false,
    idleTimeout: 2 * 60 * 60 // seconds
  },
  abandonmentConfig: {
    enabled: true,
    abandonmentTimeout: 4 * 60 * 60 // seconds
  },
  suppressBeforeUnloadEvent: false,
  apiRoot,
  apiPaths,
  iapiRoot,
  iapiPaths,
  employeeHubApiRoot,
  employeeHubApiPaths,
  segmentKey: 'JQHdFINjxDsBauBW4LPCPYj6ohZZsC1H',
  configCatSettings: { apiKey: 'I4rYCJ7wjUqEBd8QE1i2Uw/QZsE4mjo30aRIi_i5C3rYg', logLevel: LogLevel.Off, pollIntervalSeconds: 15 * 60 },
  analyticsDisabled: false
};
