import { Injectable } from '@angular/core';
import { ActiveSecurityContextStateService } from '@security/active-security/active-security-context.state-service';
import { TopNavigationLink } from '@app/app-layout/header/product-navigation/navigation-bar/navigation-bar';
import { HashMap } from '@datorama/akita';
import { FeatureFlagType } from '@feature-flags/feature-flag.service';
import { bureauRoutes } from '@app/bureau/bureau-routes';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class BureauNavigationService {
  constructor(
    private activeSecurity: ActiveSecurityContextStateService,
    private router: Router
  ) {}

  fetchBureauNavigation = (featureFlags: HashMap<FeatureFlagType>): TopNavigationLink[] => {
    const navigationLinks: TopNavigationLink[] = [];

    const isBureauAdmin = this.activeSecurity.hasApplicationAuthorityTo('EditBureaux');
    const isSupportUser = this.activeSecurity.hasApplicationAuthorityTo('IsCintraSupport');

    if (
      this.activeSecurity.activeBureau?.teamsEnabled &&
      (this.activeSecurity.hasBureauProfileAuthorityToAllOf(['IsBureauTeamUser', 'EditBureauTeams']) || isBureauAdmin)
    ) {
      navigationLinks.push({
        label: 'Teams',
        route: bureauRoutes.teams,
        feature: 'BureauTeams',
        allBureauPermissions: ['IsBureauTeamUser', 'EditBureauTeams']
      });
    }

    if (this.activeSecurity.hasBureauProfileAuthorityTo('ViewBureauOrganisations') || isBureauAdmin) {
      navigationLinks.push({
        label: 'Organisations',
        route: bureauRoutes.organisations,
        feature: 'BureauOrganisations',
        anyBureauPermissions: ['IsBureauUser']
      });
    }

    if (
      featureFlags['bureauDataMining'] === true &&
      (this.activeSecurity.hasBureauProfileAuthorityTo('ViewBureauReports') || isSupportUser)
    ) {
      navigationLinks.push({
        label: 'Reports',
        route: bureauRoutes.reports,
        feature: 'BureauReports',
        anyBureauPermissions: ['ViewBureauReports']
      });
    }

    if (this.activeSecurity.hasBureauProfileAuthorityTo('EditBureauUsers') || isBureauAdmin) {
      navigationLinks.push({
        label: 'Bureau users',
        route: bureauRoutes.users,
        feature: 'BureauUsers',
        anyBureauPermissions: ['EditBureauUsers']
      });
    }

    if (featureFlags['bureauBankFiles'] === true && (this.activeSecurity.hasBureauProfileAuthorityTo('AccessBACS') || isSupportUser)) {
      navigationLinks.push({
        label: 'Bank files',
        route: bureauRoutes.bankFiles,
        feature: 'BankFiles',
        anyBureauPermissions: ['AccessBACS']
      });
    }

    return navigationLinks;
  };
}
