import { Injectable, Type } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AlertDialogOptions } from './alert-dialog/alert-dialog-options';
import { AlertDialogComponent } from './alert-dialog/alert-dialog.component';
import { ConfirmDialogComponentData, ConfirmDialogMessageData } from './confirmation-dialog/confirm-dialog-data';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { CodeDialogOptions } from './code-dialog/code-dialog-options';
import { CodeDialogComponent } from './code-dialog/code-dialog.component';
import { CustomSnackBarComponent } from './custom-snack-bar/custom-snack-bar.component';

/**
 * Standard service for delivering consistent on-screen messaging (alerts) in the application
 */
@Injectable({ providedIn: 'root' })
export class OnscreenMessagingService {
  constructor(
    private snackBar: MatSnackBar,
    public dialog: MatDialog
  ) {}

  /**
   * Popup snackbar with a message for the user - useful when something unexpected has happened
   * @param message - the message to display
   * @param action - a function to execute on confirmation
   * @param actionText - if overriding the close-button text
   * @param cfg - any additional configuration
   */
  warn(
    message: string,
    action?: () => void,
    actionText = 'OK',
    cfg: MatSnackBarConfig = {
      duration: 3000,
      panelClass: 'warn',
      horizontalPosition: 'start'
    }
  ) {
    const sb = this.snackBar.open(message, actionText, cfg);

    sb.onAction().subscribe(() => {
      if (action) action();
      /**
       * brute force appears to be the only way to "close" this on demand -
       * it closes but only after losing focus, and I was unable to mimic this with code
       */
      sb.instance.snackBarRef.dismiss(); // normally would close the snackbar
    });
  }

  success(
    message: string,
    position?: MatSnackBarHorizontalPosition,
    verticalPosition: MatSnackBarVerticalPosition = 'top',
    panelClass: string = 'success'
  ) {
    let usePosition: MatSnackBarHorizontalPosition = 'center';

    if (position) {
      usePosition = position;
    }

    this.snackBar.openFromComponent(CustomSnackBarComponent, {
      data: message,
      duration: 3000,
      panelClass: panelClass,
      horizontalPosition: usePosition,
      verticalPosition: verticalPosition
    });
  }

  confirm(message: string, confirm: () => void): void {
    this.openConfirmationDialog(message, confirm);
  }

  confirm$(dialogData: Partial<ConfirmDialogMessageData>): Observable<boolean> {
    const defaultData: ConfirmDialogMessageData = {
      title: '',
      message: '',
      messageFontSize: 'small',
      confirmText: 'Yes',
      refuteText: 'No'
    };

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { ...defaultData, ...dialogData }
    });

    return dialogRef.afterClosed().pipe(map((result) => result === 'CONFIRMED'));
  }

  /**
   * Helper for eliciting a binary response from the user via the {@link ConfirmationDialogComponent}
   * @param message - what you want to ask
   * @param confirm - a function to invoke given a positive response
   * @param cancel - a function to invoke given a negative response
   * @param title - optional modal title
   * @param confirmText - optional override text for the 'Yes' button
   * @param refuteText - optional override text for the 'No' button
   */
  openConfirmationDialog(
    message: string,
    confirm: () => void,
    cancel?: () => void,
    title?: string,
    confirmText?: string,
    refuteText?: string
  ): void {
    const data: ConfirmDialogMessageData = {
      title: title != null ? title : '',
      message,
      messageFontSize: 'small',
      confirmText: confirmText != null ? confirmText : 'Yes',
      refuteText: refuteText != null ? refuteText : 'No'
    };

    this._openConfirmationDialog(data, confirm, cancel);
  }

  /**
   * Helper for eliciting a binary response from the user via the {@link ConfirmationDialogComponent}
   * @param contentComponent - the component to load as the content of the confirmation - use content on the data property to supply extra info
   * @param content - content data
   * @param confirm - a function to invoke given a positive response
   * @param cancel - a function to invoke given a negative response
   * @param title - optional modal title
   * @param confirmText - optional override text for the 'Yes' button
   * @param refuteText - optional override text for the 'No' button
   */
  openConfirmationComponentDialog(
    contentComponent: Type<any>,
    content: any,
    confirm: () => void,
    confirmText?: string,
    refuteText?: string,
    cancel?: () => void,
    title?: string
  ): void {
    const data: ConfirmDialogComponentData = {
      contentComponent,
      content,
      title: title != null ? title : '',
      confirmText: confirmText != null ? confirmText : 'Yes',
      refuteText: refuteText != null ? refuteText : 'No'
    };

    this._openConfirmationDialog(data, confirm, cancel);
  }

  private _openConfirmationDialog(
    data: ConfirmDialogMessageData | ConfirmDialogComponentData,
    confirm: () => void,
    cancel?: () => void
  ): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'CONFIRMED') {
        confirm();
        return;
      }

      if (cancel) {
        cancel();
      }
    });
  }

  /**
   * Helper for alerting a message to the user via a modal dialogue {@link AlertDialogComponent}
   */
  openAlertDialog(dialogOptions: AlertDialogOptions): void {
    const dialog = this.dialog.open(AlertDialogComponent, {
      data: dialogOptions,
      maxWidth: 400
    });

    dialog.afterClosed().subscribe((res) => {
      if (dialogOptions.action) {
        dialogOptions.action();
      }
    });
  }

  /**
   * Helper for showing text in a pre tag to the user via a modal dialogue {@link CodeDialogComponent}
   */
  openCodeDialog(dialogOptions: CodeDialogOptions): void {
    const dialog = this.dialog.open(CodeDialogComponent, {
      data: dialogOptions,
      maxWidth: 800
    });
  }

  openSnackBar(message: string, durationInSeconds: number) {
    this.snackBar.openFromComponent(CustomSnackBarComponent, {
      data: message,
      duration: durationInSeconds * 1000,
      horizontalPosition: 'center',
      verticalPosition: 'top'
    });
  }
}
