import { environment } from '@env/environment';

export function initHotjarFunction() {
  return () => {
    if (environment.analyticsDisabled) {
      return;
    }

    const n = document.createElement('script');
    n.type = 'text/javascript';
    n.src = '/assets/hotjar.js';
    n.async = !0;
    const a = document.getElementsByTagName('script')[0];
    a.parentNode.insertBefore(n, a);
  };
}
