import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractEntityDataProvider } from '@entity-framework/entity-record/entity-data-provider/abstract-entity.data-provider';
import { environment } from '@env/environment';
import { LoggingService } from '@logging/logging.service';
import { Member } from '@security/users/models/member';

/**
 * Provides http access to the currently signed-in member's security context (roles, permissions and payroll access)
 */
@Injectable({ providedIn: 'root' })
export class ActiveMemberDataProvider extends AbstractEntityDataProvider<Member> {
  protected apiRoot = `${environment.apiPaths.activeSecurity}/active-member`;

  constructor(protected httpClient: HttpClient, protected logger: LoggingService) {
    super(httpClient, logger);
  }
}
