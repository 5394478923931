import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { EntityGridComponent } from './entity-grid/entity-grid.component';
import { OnscreenMessagingModule } from '@pattern-library/onscreen-messaging/onscreen-messaging.module';
import { MaterialModule } from '@pattern-library/material.module';
import { EditEntityDialogComponent } from './entity-dialog/edit-entity-dialog.component';
import { DynamicModule } from 'ng-dynamic-component';
import { DesignSystemModule } from '@design/design-system.module';
import { GridPresetButtonComponent } from './entity-grid/preset-button/grid-preset-button.component';
import { EntityDataTableComponent } from '@entity-framework/entity-grid/data-table/entity-data-table.component';
import { AuthorisationModule } from '@security/authorization/authorisation.module';
import { GridExportButtonComponent } from './entity-grid/export-button/grid-export-button.component';
import { GridTotalBarComponent } from './entity-grid/total-bar/grid-total-bar.component';
import { GridTotalBarItemComponent } from './entity-grid/total-bar/total-column/grid-total-bar-item.component';
import { PaginatorComponent } from './entity-grid/paginator/paginator.component';
import { EntityDataCardListComponent } from './entity-grid/data-card-list/entity-data-card-list.component';

@NgModule({
  declarations: [
    EntityDataTableComponent,
    EntityDataCardListComponent,
    EntityGridComponent,
    EditEntityDialogComponent,
    GridPresetButtonComponent,
    GridExportButtonComponent,
    GridTotalBarComponent,
    GridTotalBarItemComponent,
    PaginatorComponent
  ],
  imports: [
    RouterModule,
    MaterialModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    DynamicModule,
    OnscreenMessagingModule,
    DesignSystemModule,
    AuthorisationModule
  ],
  exports: [
    EntityDataTableComponent,
    EntityDataCardListComponent,
    EntityGridComponent,
    EditEntityDialogComponent,
    PaginatorComponent,
    GridExportButtonComponent
  ]
})
export class EntityFrameworkModule {}
