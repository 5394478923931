import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Injectable } from '@angular/core';
import { SegmentAnalyticsService } from '@analytics';
import { ActiveAuthenticationStateService } from '@auth-n/state/active-authentication.state-service';
import { ActiveUserDataProvider } from '@security/active-security/active-user.data-provider';
import { CintraRollbarService } from '@logging/cintra-rollbar.service';
import { LoggingService } from '@logging/logging.service';
import { UserIdentity } from '@auth-n/state/user-identity';
import { generate as shortUid } from 'short-uuid';
import { catchError, distinctUntilChanged } from 'rxjs/operators';
import { of, throwError } from 'rxjs';
import { ActiveSecurityContextStateService } from '@security/active-security/active-security-context.state-service';
import { AuthenticationService } from '@auth-n/authentication.service';
import { OnscreenMessagingService } from '@pattern-library/onscreen-messaging/onscreen-messaging.service';
import { FullScreenSpinnerService } from '@design/spinners/fullscreen-spinner/fullscreen-spinner.service';

/**
 * Provides models management for the active user.
 * Prompted by a change of active identity in authentication models, triggering a fetch of the active user (if authenticated).
 */
@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class ActiveUserService {
  constructor(
    private analyticsService: SegmentAnalyticsService,
    private authenticationState: ActiveAuthenticationStateService,
    private activeSecurityContextState: ActiveSecurityContextStateService,
    private activeUserDataProvider: ActiveUserDataProvider,
    private authenticationService: AuthenticationService,
    private messaging: OnscreenMessagingService,
    private cintraRollbarService: CintraRollbarService,
    private logger: LoggingService,
    private fullscreenSpinnerService: FullScreenSpinnerService
  ) {}

  observeActiveIdentity() {
    // observe a change in authenticated models
    this.authenticationState.activeIdentity$
      .pipe(untilDestroyed(this), distinctUntilChanged())
      .subscribe((activeIdentity) => this.onActiveIdentityChanged(activeIdentity));
  }

  refreshActiveUser() {
    this.activeUserDataProvider.read$().subscribe((activeUser) => {
      this.logger.trace('ACTIVE USER: fetched active user: ', activeUser);
      this.activeSecurityContextState.setActiveUser(activeUser);
      this.activeSecurityContextState.resolveActiveMembership(activeUser.memberships);
    });
  }

  /**
   * Update the current user identity in models.
   * When it is an active identity, the active user is then fetched, joined by the identity's (cognito) id.
   * Any auth guards will not resolve whilst this is transitioning.
   */
  private onActiveIdentityChanged(activeIdentity?: UserIdentity) {
    this.logger.trace('ACTIVE USER: (start transitioning) active identity models changed: ', activeIdentity);

    const sessionId = shortUid();
    this.activeSecurityContextState.setNewSessionId(sessionId);

    this.logger.sessionId = sessionId;
    this.logger.userId = activeIdentity ? activeIdentity.id : undefined;
    this.logger.email = activeIdentity ? activeIdentity.email : undefined;

    this.cintraRollbarService.setIdentity(activeIdentity);

    // not authenticated...
    if (!activeIdentity) {
      this.logger.trace('ACTIVE USER: not authenticated');

      localStorage.removeItem('activeMembership');

      this.analyticsService.logout();

      this.activeSecurityContextState.setMembershipInactive();

      return;
    }

    // authenticated - fetch the extended user info
    this.fullscreenSpinnerService.show();
    this.activeUserDataProvider
      .read$()
      .pipe(
        catchError((err) => {
          if (err.status === 400) {
            this.fullscreenSpinnerService.reset();
            console.warn('400 account expired');

            this.messaging.openAlertDialog({
              title: 'Account expired',
              message: 'Your access to Cintra Cloud has now expired.',
              action: () => {
                this.authenticationService.signOutAndRedirect();
              }
            });

            return of(undefined);
          }
          return throwError(() => err);
        })
      )
      .subscribe((activeUser?) => {
        this.logger.trace('ACTIVE USER: fetched active user: ', activeUser);

        if (!activeUser) return;

        this.activeSecurityContextState.setActiveUser(activeUser);

        this.analyticsService.identify(activeUser);

        this.activeSecurityContextState.resolveActiveMembership(activeUser.memberships);
      });
  }
}

/**
 * Sets up the {@link ActiveUserService} to monitor active authentication
 */
export function setUpActiveUserService(activeUserService: ActiveUserService, segmentAnalyticsService: SegmentAnalyticsService) {
  return () => {
    segmentAnalyticsService.loadAnalytics().then(() => activeUserService.observeActiveIdentity());
  };
}
