@if (!!day) {
  @if (day.amEvent || day.pmEvent) {
    <span class="half-day am-graphic">
      <img *ngIf="day.amEvent" [alt]="day.amEvent.eventStatus" [src]="getImgSrc(day.amEvent, 'am')" [ngClass]="getEventCss(day.amEvent)" />
    </span>
    <span class="half-day pm-graphic">
      <img *ngIf="day.pmEvent" [alt]="day.pmEvent.eventStatus" [src]="getImgSrc(day.pmEvent, 'pm')" [ngClass]="getEventCss(day.pmEvent)" />
    </span>
  } @else if (day.fullDayEvent) {
    <span class="day-graphic">
      <img
        *ngIf="day.fullDayEvent"
        [alt]="day.fullDayEvent.eventStatus"
        [src]="getImgSrc(day.fullDayEvent, 'day')"
        [ngClass]="getEventCss(day.fullDayEvent)"
      />
    </span>
  }
}
