import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { LoggingService } from '@logging/logging.service';

@Component({
  templateUrl: './resource-not-found.component.html',
  styleUrls: ['./resource-not-found.component.scss']
})
export class ResourceNotFoundComponent {
  constructor(private router: Router, private logger: LoggingService) {
    logger.error(`Could not find route: ${router.url}`, {});
  }
}
