import { formatNumber } from '@angular/common';
import { toUpper } from 'lodash';

export const toCurrency = (
  value: number | undefined | null,
  decimals = 0,
  currency: string = 'GBP',
  valueWhenNullUndefinedOrEmpty: string = '-'
): string => {
  if (value === undefined || value === null) return valueWhenNullUndefinedOrEmpty;
  if (currency === undefined || currency === null) currency = 'GBP';

  currency = toUpper(currency);

  // create a number formatter.
  const formatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: currency,

    // these options are needed to round to whole numbers if required.
    minimumFractionDigits: decimals, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: decimals // (causes e.g. 2500.99 to be printed as $2,501)
  });

  return formatter.format(value); /* £2,500.00 */
};

export function getPoundsOnly(value: number): number {
  return value >= 0 ? Math.floor(value + Number.EPSILON) : Math.ceil(value - Number.EPSILON);
}

export function getFormattedPoundsOnly(value: number, currency: string = 'GBP'): string {
  if (currency === undefined || currency === null) currency = 'GBP';
  currency = toUpper(currency);
  return `${value < 0 ? '-' : ''}${getCurrencySymbol(currency)}${formatNumber(Math.abs(getPoundsOnly(value)), 'en-GB', '1.0-0')}`;
}

export function getPence(value: number): string {
  return formatNumber(Math.abs(value - getPoundsOnly(value)) * 100, 'en-GB', '2.0-0');
}

export function getCurrencySymbol(currency: string): string {
  if (currency === undefined || currency === null) currency = 'GBP';
  currency = toUpper(currency);
  return currency == 'EUR' ? '€' : '£';
}
