<div class="variance-container">
  <ng-container *ngIf="showTrendingFlatWhenZero">
    <span *ngIf="variance === null" class="no-variance"></span>

    <div *ngIf="variance !== null">
      <span [ngClass]="{ negative: variance < 0, positive: variance > 0, zero: variance === 0 }">
        <mat-icon *ngIf="variance > 0">trending_up</mat-icon>
        <mat-icon *ngIf="variance < 0">trending_down</mat-icon>
        <mat-icon *ngIf="variance === 0">trending_flat</mat-icon>
        {{ variance }}%
      </span>
    </div>
  </ng-container>

  <ng-container *ngIf="!showTrendingFlatWhenZero">
    <span *ngIf="variance === 0" class="no-variance"></span>

    <div *ngIf="variance !== 0">
      <span [ngClass]="{ negative: variance < 0, positive: variance > 0 }">
        <mat-icon *ngIf="variance > 0">trending_up</mat-icon>
        <mat-icon *ngIf="variance < 0">trending_down</mat-icon>

        {{ variance }}%
      </span>
    </div>
  </ng-container>
</div>
