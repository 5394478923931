import { Injectable, OnDestroy } from '@angular/core';
import { createStore, select, setProp, setProps, withProps } from '@ngneat/elf';
import { filter, map } from 'rxjs';
import { ActiveAuthentication, CompletePasswordOptions, SignInMfaOptions } from './active-authentication';
import { UserIdentity } from './user-identity';

/**
 * Provides models management for the (partly transient) models of the active authenticating/authenticated identity
 */
@Injectable({ providedIn: 'root' })
export class ActiveAuthenticationStateService implements OnDestroy {
  private store = createStore(
    { name: 'active-authentication' },
    withProps({
      inFlight: false,
      activeIdentitySet: false
    } as ActiveAuthentication)
  );

  /**
   * Observable of the current authenticated user (or undefined).
   */
  activeIdentity$ = this.store.pipe(
    select((s) => [s.activeIdentity, s.activeIdentitySet]),
    filter(([_, activeIdentitySet]: [UserIdentity, boolean]) => !!activeIdentitySet),
    map(([activeIdentity]) => activeIdentity)
  );
  /**
   * Observing if mfa on sign in has been activated
   */
  signInMfaOptions$ = this.store.pipe(select((s) => s.signInMfaOptions));
  /**
   * Observing if update temporary password on sign in has been activated
   */
  completePasswordOptions$ = this.store.pipe(select((s) => s.completePasswordOptions));
  /**
   * Observable if the remote service is working
   */
  inFlight$ = this.store.pipe(select((s) => s.inFlight));

  /**
   * Get the route the user entered the app with
   */
  get landingRoute(): string | undefined {
    return localStorage.getItem('landingRoute');
  }

  /**
   * Set the route the  user entered the app with
   */
  set landingRoute(landingRoute: string | undefined) {
    if (!landingRoute) {
      localStorage.removeItem('landingRoute');
      return;
    }
    localStorage.setItem('landingRoute', landingRoute);
  }

  /**
   * Get / sets the identity of the current authenticated user
   */
  get activeIdentity(): UserIdentity {
    return this.store.getValue().activeIdentity;
  }

  set activeIdentity(activeIdentity: UserIdentity) {
    this.store.update(setProps({ activeIdentity, activeIdentitySet: true }));
  }

  /**
   * Is there are currently signed-in in user with an active my-profile?
   */
  get isAuthenticated(): boolean {
    return this.checkForAuthenticUser(this.activeIdentity);
  }

  /**
   * Indicates an authenticated user.
   */
  private checkForAuthenticUser(userIdentity?: UserIdentity): boolean {
    return !!userIdentity;
  }

  /**
   * Gets the details of the MFA challenge on sign-in
   */
  get signInMfaOptions(): SignInMfaOptions | undefined {
    return this.store.getValue().signInMfaOptions;
  }
  set signInMfaOptions(signInMfaOptions: SignInMfaOptions) {
    this.store.update(setProp('signInMfaOptions', signInMfaOptions));
  }

  /**
   * Gets/sets the details of the update temporary password on sign-in
   */
  get completePasswordOptions(): CompletePasswordOptions | undefined {
    return this.store.getValue().completePasswordOptions;
  }
  set completePasswordOptions(completePasswordOptions: CompletePasswordOptions) {
    this.store.update(setProp('completePasswordOptions', completePasswordOptions));
  }

  /**
   * Get / sets whether the remote service is working
   */
  get inFlight(): boolean {
    return this.store.getValue().inFlight;
  }
  set inFlight(inFlight: boolean) {
    this.store.update(setProp('inFlight', inFlight));
  }

  ngOnDestroy() {
    this.store.destroy();
  }
}
