import { Directive, HostBinding } from '@angular/core';

/**
 * Inherited by all tile components who can take a common css class for bootstrap grid layout of the tiles.
 * See {@link TileLayoutComponent}
 */
@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class AbstractInfoTileComponent {
  @HostBinding('class.tile-container') private cls = true;
}
