import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Ink } from '@design/typography/ink';

@Component({
  selector: 'app-body-xs',
  template: '<span [ngClass]="ngClass"><ng-content></ng-content></span>',
  styleUrls: ['./body-extra-small.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BodyExtraSmallComponent {
  @Input() bold?: boolean;
  @Input() semiBold?: boolean;
  @Input() italic?: boolean;
  @Input() ink: Ink = 'ink-85';

  get ngClass(): string[] {
    return [
      this.bold !== undefined ? 'bold' : '',
      this.semiBold !== undefined ? 'semi-bold' : '',
      this.italic !== undefined ? 'italic' : '',
      this.ink
    ];
  }
}
