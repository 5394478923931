import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CalendarEventsList, CalendarMonthOfDayEvents } from './calendar';
import { AbstractCalendarComponentStateService } from './abstract-calendar-component.state-service';

@UntilDestroy()
@Injectable()
/** horizontal team view **/
export class HorizontalCalendarComponentStateService extends AbstractCalendarComponentStateService {
  constructor() {
    super();
    this.state$.pipe(untilDestroyed(this)).subscribe(this.buildCalendar);
  }

  private buildCalendar = () => {
    let { calendarEventsList } = this.store.getValue();
    calendarEventsList ??= new CalendarEventsList([]);

    const calendarMonthEvents: CalendarMonthOfDayEvents = [];

    let currentDateTime = this.navigatorStateService.getActiveDate().startOf('month');

    const currentMonthIndex = currentDateTime.month;

    while (currentDateTime.month == currentMonthIndex) {
      calendarMonthEvents.push(calendarEventsList.getCalendarDayEvents(currentDateTime));
      currentDateTime = currentDateTime.plus({ day: 1 });
    }

    this.calendarMonthEvents$.next(calendarMonthEvents);
  };
}
