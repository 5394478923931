import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from '@angular/core';

export type AnchorSize = 'default' | 'extra-small' | 'small' | 'large';
export type AnchorInk = 'default-ink' | 'employee-hub-ink' | 'primary-action' | 'system-red';

@Component({
  selector: 'app-a',
  templateUrl: './anchor.component.html',
  styleUrls: ['./anchor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnchorComponent {
  @Input() size: AnchorSize = 'default';
  @Input() italic: boolean = false;
  @Input() url?: string;
  @Input() routeTo?: string;
  @Input() click?: string;
  @Input() target?: string;
  @Input() icon?: boolean;
  @Input() ink: AnchorInk = 'default-ink';
  @Input() disabled = false;
  @Input() underlined = false;
  @Input() bold: boolean = true;

  @ViewChild('anchor') set anchor(anchor: ElementRef<HTMLAnchorElement>) {
    if (!(anchor && this.target)) return;
    anchor.nativeElement.target = this.target;
  }

  ngClass(): any {
    return {
      default: this.size === 'default',
      'extra-small': this.size === 'extra-small',
      small: this.size === 'small',
      large: this.size === 'large',
      italic: this.italic,
      icon: this.icon !== undefined,
      'default-ink': this.ink === 'default-ink',
      'employee-hub-ink': this.ink === 'employee-hub-ink',
      'system-red': this.ink === 'system-red',
      disabled: this.disabled,
      underlined: this.underlined,
      bold: this.bold
    };
  }
}
