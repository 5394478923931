export const toKebabCase = (s: string): string =>
  s
    .replace(/['"?%$!.\/,()]/g, '')
    .replace(/\s/g, '-')
    .toLowerCase();

export const splitWordOnUpperCase = (s: string, separator = ' ') => s.match(/[A-Z][a-z]+|[0-9]+/g)?.join(separator);

/**
 * Reads the local file and converts it to a base64 string.
 */
export const toBase64 = (file: File): Promise<string> => {
  return new Promise<string>((resolve) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const dataUrl = event.target.result as string;
      resolve(dataUrl.substr(dataUrl.indexOf(',') + 1));
    };

    reader.readAsDataURL(file);
  });
};

export function isNullUndefinedOrWhitespace(str: string) {
  return str === null || str === undefined || str.match(/^\s*$/) !== null;
}

export const toTitleCase = (target: string) =>
  target.replace(/(^|\s)\S/g, function (t) {
    return t.toUpperCase();
  });
