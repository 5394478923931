import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CssBreakpointsService } from '@design/layout/css-breakpoints.service';

@Component({
  selector: 'app-view-card',
  templateUrl: './view-card.component.html',
  styleUrls: ['./view-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ViewCardComponent {
  @Input() cardTitle: string;
  @Input() cardSubTitle: string;
  @Input() cardTertiaryTitles: TitleValues[];
  @Input() showEdit: boolean = true;
  @Input() disableEditButton: boolean = false;
  @Input() editPopoverText: string[] = [];
  @Input() collapsible = false;
  @Input() collapsed = false;
  @Output() editClick = new EventEmitter();
  @Input() hideCard = false;

  constructor(public cssBreakpointsService: CssBreakpointsService) {}

  edit($event: MouseEvent): void {
    this.editClick.emit($event);
    $event.stopPropagation();
  }

  showHide(): void {
    this.collapsed = !this.collapsed;
  }
}

export interface TitleValues {
  title: string;
  value: string;
}
