import { NativeDateAdapter } from '@angular/material/core';

export class CustomDateAdapter extends NativeDateAdapter {
  format(date: Date, displayFormat: Object): string {
    if (displayFormat === 'input') {
      const year = date.getFullYear();
      return `${year}`;
    } else {
      return date.toDateString();
    }
  }
}
