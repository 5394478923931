import { Component, Input } from '@angular/core';

import { AbstractFormComponent } from '../abstract-form-component';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { CINTRA_MOMENT_DATE_FORMATS } from '@design/forms/date/date-formats';
import { MomentUtcDateAdapter } from '@design/forms/date/moment-utc-date-adapter';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: MAT_DATE_FORMATS, useValue: CINTRA_MOMENT_DATE_FORMATS },
    { provide: DateAdapter, useClass: MomentUtcDateAdapter }
  ]
})
export class DatePickerComponent extends AbstractFormComponent {
  @Input() minDate: Date = new Date(1900, 0, 1);
  @Input() maxDate: Date = new Date(2100, 0, 1);
  private _disabled = false;

  @Input() set disabled(disabled: boolean) {
    this._disabled = disabled;
  }

  get disabled(): boolean {
    return this._disabled;
  }

  clearDate() {
    this.formControl.setValue(null);
  }
}
