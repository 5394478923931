import { FormControl, UntypedFormArray, UntypedFormControl, ValidatorFn, Validators } from '@angular/forms';

export const passwordRegEx = new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/);

export const internationalMobileNumberFormat = new RegExp(/^\+(?:[0-9]?){6,14}[0-9]$/);

export const textRangeValidator = (min: number = 2, max: number = 100) => {
  return [Validators.minLength(min), Validators.maxLength(max)];
};

export const nationalInsuranceNumberRegEx = new RegExp(
  /^(?!BG)(?!GB)(?!NK)(?!KN)(?!TN)(?!NT)(?!ZZ)(?:[A-CEGHJ-PR-TW-Z][A-CEGHJ-NPR-TW-Z])(?:\s*\d\s*){6}([A-D]|\s)$/
);

export const postcodeRegEx = new RegExp(/^[A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}$/, 'i');

export const numberOnlyRegex = new RegExp(/^[0-9]/);

export class ArrayValidators {
  public static minLength(min: number): ValidatorFn | any {
    return (control: UntypedFormControl) => {
      return control.value?.length < min ? { minLength: true } : null;
    };
  }
}

export class NumberValidators {
  public static maxDecimalPlaces(decimalPlaces: number): ValidatorFn | any {
    return (control: UntypedFormControl) => {
      const dps = [];
      for (let i = 1; i <= decimalPlaces; i++) dps.push(i);
      const regex = `^\\d*(?:[.]\\d{${dps.join(',')}})?$`;
      return control.value?.toString().match(new RegExp(regex)) ? null : { decimalPlaces: true };
    };
  }
}

export class FormControlArrayValidators {
  public static minLength(min: number): ValidatorFn | any {
    return (control: FormControl<any[]>) => {
      return control.value?.length < min ? { minLength: true } : null;
    };
  }
}
