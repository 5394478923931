import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IfFeatureFlaggedDirective } from './if-feature-flagged.directive';

@NgModule({
  declarations: [IfFeatureFlaggedDirective],
  imports: [CommonModule],
  exports: [IfFeatureFlaggedDirective]
})
export class FeatureFlagModule {}
