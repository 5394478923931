import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { LoggingService } from '@logging/logging.service';
import { Observable } from 'rxjs';

export interface PrivateOrganisationValidateRequest {
  password: string;
}

export interface PrivateOrganisationValidateResponse {
  isValid: boolean;
  header: string;
}

@Injectable({ providedIn: 'root' })
export class OrganisationAccessControlDataProvider {
  protected apiRoot = `${environment.apiPaths.accessControl}/organisations`;

  constructor(private logger: LoggingService, private httpClient: HttpClient) {}

  validatePrivate$(request: PrivateOrganisationValidateRequest): Observable<PrivateOrganisationValidateResponse> {
    return this.httpClient.post<PrivateOrganisationValidateResponse>(`${this.apiRoot}/validate-private`, request);
  }
}
