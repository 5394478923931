<div [tid]="bannerName + '-banner'" class="{{ bannerType }}-banner">
  <app-icon *ngIf="bannerType === 'warning'" hoverColor="tertiary" color="tertiary">warning-icon</app-icon>
  <ndc-dynamic [ndcDynamicComponent]="contentType"></ndc-dynamic>
  <ng-content></ng-content>
  <app-icon
    *ngIf="showClose"
    tid="close-banner-button"
    class="close-button"
    color="ink-45"
    hoverColor="ink-85"
    (click)="bannerService.hide(bannerName)"
  >
    clear
  </app-icon>
</div>
