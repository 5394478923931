import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

import { ActiveSecurityContextStateService } from '@security/active-security/active-security-context.state-service';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: '[ifCustomer], [ifNotCustomer]' })
export class IfCustomerDirective implements OnInit {
  @Input() ifCustomer: boolean;
  @Input() ifNotCustomer: boolean;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private activeSecurity: ActiveSecurityContextStateService
  ) {}

  ngOnInit(): void {
    this.considerRenderView(!this.activeSecurity.isOssUser());
  }

  private considerRenderView(isCustomer: boolean): void {
    this.viewContainer.clear();
    if (this.ifCustomer) {
      if (isCustomer) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      }
    } else if (this.ifNotCustomer) {
      if (!isCustomer) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      }
    }
  }
}
