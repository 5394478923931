import { Component, Input } from '@angular/core';
import { IconColor } from '@design/buttons/icon/icon-config';

export type TogglePanelType = 'info' | 'warning';

@Component({
  selector: 'app-toggle-panel',
  templateUrl: './toggle-panel.component.html',
  styleUrls: ['./toggle-panel.component.scss']
})
export class TogglePanelComponent {
  @Input() toggled = false;
  @Input() togglePanelType: TogglePanelType;
  @Input() title: string;
  @Input() icon: string;
  @Input() iconColor: IconColor;

  constructor() {}

  get ngClass(): string[] {
    return [this.togglePanelType];
  }
}
