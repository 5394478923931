import { HttpClient, HttpErrorResponse, HttpHandler } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

/**
 * Error object extending details of an angular HttpErrorResponse
 */
export class CintraHttpErrorResponse extends HttpErrorResponse {
  constructor(httpErrorResponse: HttpErrorResponse, public stack: string) {
    super(httpErrorResponse);
  }
}

/**
 * Extended HttpClient that writes a stack trace on error
 */
@Injectable()
export class ExtendedHttpClient extends HttpClient {
  constructor(handler: HttpHandler) {
    super(handler);
  }

  request(...args: [any]): Observable<any> {
    // we're doing this here to get a decent stack in the event of an error,
    // which you don't get inside of catchError below... apparently fixed in Angular 15 todo: #ETN-277
    const stackError = new Error();
    stackError.name = CintraHttpErrorResponse.name;
    const errorStack = stackError.stack;

    return super.request(...args).pipe(
      catchError((err: HttpErrorResponse) => {
        if (!navigator.onLine) {
          return throwError(() => err);
        }

        const cintraError = new CintraHttpErrorResponse(err, errorStack);

        // potentially handled by individual data providers, and then finally by CustomErrorHandler
        return throwError(() => cintraError);
      })
    );
  }
}
