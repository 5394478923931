import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

export type BadgeSize = 'default' | 'large';

@Component({
  selector: 'app-badge',
  templateUrl: './badge.component.html',
  styleUrl: './badge.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BadgeComponent {
  @Input() size: BadgeSize = 'default';
}
