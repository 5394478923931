import { Router } from '@angular/router';

export const customReportTypesRoutes = {
  hub: 'custom-reports/hub',
  create: (router: Router) => {
    router
      .navigate(['', { outlets: { modal: ['custom-reports', 'create'] } }], {
        queryParamsHandling: 'preserve'
      })
      .then();
  },
  edit: (router: Router, id: any) => {
    router
      .navigate(['', { outlets: { modal: ['custom-reports', 'edit', id] } }], {
        queryParamsHandling: 'preserve'
      })
      .then();
  }
};
