import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-dashboard-widget-template',
  templateUrl: './dashboard-widget-template.component.html',
  styleUrls: ['./dashboard-widget-template.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardWidgetTemplateComponent {
  @Input() title?: string;
}
