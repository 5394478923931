import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { LoggingService } from '@logging/logging.service';
import { OrganisationPropertiesModel } from '@security/organisations/models/organisation-properties.model';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class OrganisationPropertiesDataProvider {
  protected apiRoot = `${environment.apiPaths.organisation}/properties`;

  constructor(private httpClient: HttpClient, private logger: LoggingService) {}

  organisationProperties$(): Observable<OrganisationPropertiesModel> {
    return this.httpClient.get<OrganisationPropertiesModel>(`${this.apiRoot}`);
  }
}
