<div class="container">
  <div class="row g-0">
    <div class="col-12 d-flex flex-column justify-content-center">
      <ng-container *ngIf="!data.hasOwnProperty('contentComponent')">
        <div [align]="'center'" class="title">
          <app-h4 *ngIf="data.title">{{ data.title }}</app-h4>
        </div>
        <div class="align-center">
          <!-- align left within a centrally aligned block to cater for multi-line messages.
            The left alignment will not be noticed for a single line message -->
          <div [align]="'left'">
            <app-content [ngClass]="{ 'align-text-center': data.messageTextAlignment }">
              <app-body *ngIf="data.messageFontSize === 'large'">{{ data.message }}</app-body>
              <app-p *ngIf="data.messageFontSize === 'small'">{{ data.message }}</app-p>
            </app-content>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="data.hasOwnProperty('contentComponent')">
        <ndc-dynamic [ndcDynamicComponent]="data.contentComponent"></ndc-dynamic>
      </ng-container>
    </div>
  </div>

  <div class="row g-0">
    <div class="col-6">
      <app-button data-tid-no-button theme="alternate" (click)="onNoClick()">{{ data.refuteText }}</app-button>
    </div>
    <div class="col-6 d-flex justify-content-end">
      <app-button
        data-tid-yes-button
        [theme]="data.confirmText.toLowerCase() === 'delete' ? 'warn' : 'primary-action'"
        matDialogClose="CONFIRMED"
        focussed
        >{{ data.confirmText }}</app-button
      >
    </div>
  </div>
</div>
