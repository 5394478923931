import { Component, Input } from '@angular/core';
import { Ink } from '@design/typography/ink';

@Component({
  selector: 'app-h4',
  template: '<h4 [ngClass]="ngClass"><ng-content></ng-content></h4>',
  styleUrls: ['./h4.component.scss']
})
export class H4Component {
  @Input() noMargin?: boolean;
  @Input() noWrap?: boolean;
  @Input() center?: boolean;
  @Input() ink: Ink = 'primary';
  @Input() backgroundColor: Ink;
  @Input() usePadding = false;

  get ngClass(): string[] {
    return [
      this.noMargin !== undefined ? 'no-margin' : '',
      this.noWrap !== undefined ? 'no-wrap' : '',
      this.center !== undefined ? 'center' : '',
      this.ink,
      this.backgroundColor ? 'background-' + this.backgroundColor : '',
      this.usePadding ? 'use-padding' : ''
    ];
  }
}
