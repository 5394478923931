import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { DateTime } from 'luxon';
import { CalendarNavigatorStateService } from '../state/calendar-navigator.state-service';

@Component({
  selector: 'app-month-navigator',
  templateUrl: './month-navigator.component.html',
  styleUrls: ['./month-navigator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MonthNavigatorComponent {
  navigatorStateService = inject(CalendarNavigatorStateService);

  formatDate = (date: DateTime) => date.toFormat('LLLL yyyy');
}
