import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertDialogOptions } from './alert-dialog-options';

/**
 * Standard inline replacement for javascript alert().
 * A useful modal dialog in which to ensure a user sees a message.
 * See {@link OnscreenMessagingService#openAlertDialog} for usage
 */
@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss']
})
export class AlertDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: AlertDialogOptions) {}
}
