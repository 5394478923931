import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-handover-modal-confirmation-template',
  templateUrl: './handover-modal-confirmation-template.component.html',
  styleUrls: ['./handover-modal-confirmation-template.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HandoverModalConfirmationTemplateComponent {
  @Input() topTitle: string = null;
  @Input() bottomTitle: string = 'Are you sure?';
  @Output() closeRequest = new EventEmitter<void>();

  onClose() {
    this.closeRequest.emit();
  }
}
