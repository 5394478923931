<div class="app-select" (click)="onContainerClick()">
  <app-form-label [requiredField]="required || showAsRequired" *ngIf="formLabel">{{ formLabel }}</app-form-label>
  <mat-select
    *ngIf="dataset"
    #select
    [placeholder]="label"
    [formControl]="formControl"
    [title]="label"
    [required]="required"
    [multiple]="multiple"
    disableOptionCentering
    disableRipple
    panelClass="app-select-panel mat-elevation-z0"
    [ngClass]="{
      open: select.panelOpen,
      dark: selectTheme === 'dark',
      white: selectTheme === 'white',
      multiple: multiple,
      hideContainer: hideContainer
    }"
    [compareWith]="dataset.compare"
  >
    <mat-select-trigger *ngIf="!hideContainer && multiple && getSelectedValues() as selectedValues">
      <app-compact-values-label
        *ngIf="!hideContainer"
        [values]="selectedValues"
        [maxItems]="maxSelectedItemsToDisplay"
      ></app-compact-values-label>
    </mat-select-trigger>
    <mat-option *ngIf="useEmptyOption">none</mat-option>

    <mat-option *ngFor="let item of dataset.items; let index = index" [value]="dataset.getValue(item)" [class]="getItemClass(index)">
      <span>{{ dataset.getLabel(item) }}</span>
    </mat-option>
  </mat-select>
  <mat-error *ngIf="!hideContainer && formControl.touched && formControl.hasError('required')">Required</mat-error>
</div>
