import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { LoggingService } from '@logging/logging.service';
import { environment } from '@env/environment';
import { PayrollDetails } from './payroll-details';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PayrollDetailsDataProvider {
  constructor(protected httpClient: HttpClient, protected logger: LoggingService) {}

  readDetails$(payrollId: number): Observable<PayrollDetails> {
    const endpoint = `${environment.apiPaths.payrolls}/${payrollId}`;
    this.logger.trace('PayrollDetailsDataProvider: readDetails', endpoint);
    return this.httpClient.get<PayrollDetails>(endpoint);
  }

  applyClutch$(payrollId: number) {
    const endpoint = `${environment.apiPaths.payrolls}/${payrollId}/apply-clutch`;
    return this.httpClient.put(endpoint, null);
  }

  releaseClutch$(payrollId: number) {
    const endpoint = `${environment.apiPaths.payrolls}/${payrollId}/release-clutch`;
    return this.httpClient.put(endpoint, null);
  }

  handoverSubmit$(payrollId: number) {
    const endpoint = `${environment.apiPaths.payrolls}/${payrollId}/handover/submit`;
    return this.httpClient.put(endpoint, null);
  }
}
