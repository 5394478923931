import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, ActivatedRoute } from '@angular/router';

import { Observable } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';

import { LoggingService } from '@logging/logging.service';
import { PayrollLockedDataProvider } from './payroll-locked.data-provider';
import { navigatePayrollLocked } from './payroll-locked-routes';
import { ActiveSecurityContextStateService } from '../../security/active-security/active-security-context.state-service';

/**
 * Route guard to ensure that a payroll-locked-protected route cannot be accessed
 */
@Injectable({ providedIn: 'root' })
export class PayrollLockedRouteGuard {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private securityStateService: ActiveSecurityContextStateService,
    private payrollLockedDataProvider: PayrollLockedDataProvider,
    private logger: LoggingService
  ) {}

  canActivate(next: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    this.logger.trace('PAYROLL LOCKED ROUTE GUARD: authorising: ', next);

    const payrollId = next.params.payrollId;
    return this.securityStateService.fetchingActiveUser$.pipe(
      tap((fetchingRoles) => this.logger.trace('PAYROLL LOCKED ROUTE GUARD: fetchingRoles?', fetchingRoles)),
      filter((fetchingRoles) => fetchingRoles === false),
      take(1),
      switchMap(() =>
        this.payrollLockedDataProvider.payrollLocked$(payrollId).pipe(
          map((locked) => {
            this.logger.trace('PAYROLL LOCKED ROUTE GUARD: locked?', locked);

            if (locked) {
              navigatePayrollLocked(this.router, payrollId).then();
              return false;
            }
            return true;
          })
        )
      )
    );
  }
}
