<mat-slide-toggle
  [disableRipple]="true"
  color="accent"
  [formControl]="formControl"
  (change)="onToggle($event)"
  tabindex="0"
  aria-label="test"
  labelPosition="{{ labelPosition }}"
  [class]="class"
  [disabled]="disabled"
>
  {{ label }}
</mat-slide-toggle>
