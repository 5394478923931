<div class="app-switch form-component" [tid]="tid">
  <app-form-label *ngIf="!isEmptyOrWhitespace(label)" [requiredField]="required">
    {{ label }}
  </app-form-label>

  <ul>
    <li *ngFor="let option of options" (click)="changeValue(option)" [ngClass]="{ active: this.formControl.value === option }">
      <div class="icon-container" *ngIf="this.formControl.value === option">
        <app-icon hoverColor="white" color="white" size="extra-small">check_circle</app-icon>
      </div>
      {{ option }}
    </li>
  </ul>
</div>
