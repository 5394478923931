import { DateTime, Info, Interval, WeekdayNumbers } from 'luxon';
import { AbstractIdentifiable } from '@entity-framework/entity-record/state/abstract-entity';
import { extractDateFromString } from '@utils/date-utils';

/**
 In Luxon, day indexes are: 1 = Monday and 7 = Sunday (=ISO)
 */
export const startOfWeekday: WeekdayNumbers = 1;

export const isWeekend = (day: number): boolean => day == 6 || day == 7;

export const isSunday = (day: number): boolean => day == 7;

export const weekdays = Info.weekdays('short');

export const daySuffix = (days: number) => (days > 1 ? 's' : '');

export const toStandardDateFormat = (isoDate: string) => DateTime.fromISO(isoDate).toFormat('dd MMM yyyy');

export const toTextualDateFormat = (isoDate: string) => DateTime.fromISO(isoDate).toFormat('ccc, dd LLL yyyy');

export const detectTodayBetweenDates = (startDate: string, endDate: string) =>
  new Date() > extractDateFromString(startDate) && (!endDate || new Date() < extractDateFromString(endDate));

export type CalendarViewMode = 'month' | 'year' | 'team';

export enum EventDurationType {
  DateRange = 'Multiple days',
  FullDay = 'Full day',
  HalfDayStartOfShift = 'Half day (start of day)',
  HalfDayEndOfShift = 'Half day (end of day)'
}

export enum EventStatus {
  Requested = 'Requested',
  Approved = 'Approved',
  Declined = 'Declined',
  Cancellation = 'Cancellation',
  NonWorkingDay = 'NonWorkingDay',
  Sickness = 'Sickness',
  ApprovedAbsence = 'ApprovedAbsence',
  AbsenceRequested = 'AbsenceRequested',
  ApprovedAbsenceCancellation = 'ApprovedAbsenceCancellation'
}

export const getEventStatusLabel = (eventStatus: EventStatus) => {
  switch (eventStatus) {
    case EventStatus.Approved:
    case EventStatus.ApprovedAbsence:
      return 'Approved';
    case EventStatus.Requested:
    case EventStatus.AbsenceRequested:
      return 'Requested';
    case EventStatus.Cancellation:
    case EventStatus.ApprovedAbsenceCancellation:
      return 'Requested to cancel';
    case EventStatus.Declined:
      return 'Declined';
  }
};

interface EventStatusOptions {
  label: string;
  imgSrc: string;
}

export const getEventStatusOptions = (eventStatus: EventStatus): EventStatusOptions => {
  switch (eventStatus) {
    case EventStatus.Approved:
      return { label: 'Annual Leave', imgSrc: '/framework/design-system/assets/images/calendar/booked-day.svg' };
    case EventStatus.Requested:
      return { label: 'Annual Leave', imgSrc: '/framework/design-system/assets/images/calendar/requested-day.svg' };
    case EventStatus.Cancellation:
      return { label: 'Annual Leave', imgSrc: '/framework/design-system/assets/images/calendar/cancellation-day.svg' };
    case EventStatus.AbsenceRequested:
      return { label: 'Absence Record', imgSrc: '/framework/design-system/assets/images/calendar/absence-requested-day.svg' };
    case EventStatus.ApprovedAbsence:
      return { label: 'Absence Record', imgSrc: '/framework/design-system/assets/images/calendar/approved-absence-day.svg' };
    case EventStatus.ApprovedAbsenceCancellation:
      return { label: 'Absence Record', imgSrc: '/framework/design-system/assets/images/calendar/absence-cancellation-day.svg' };
    default:
      return { label: 'Annual Leave', imgSrc: '/framework/design-system/assets/images/calendar/requested-day.svg' };
  }
};

export const decorateEvent = (entity: EventModel) => {
  entity.startDate = `${toTextualDateFormat(entity.startDate)} ${entity.periodStartsAtHalfDay ? 'PM' : 'AM'}`;
  entity.endDate = `${toTextualDateFormat(entity.endDate)} ${entity.periodEndsAtHalfDay ? 'AM' : 'PM'}`;
  entity.duration = `Total: ${entity.duration} days` as any;
};

export enum EventType {
  AnnualLeave = 'Annual Leave',
  Sickness = 'Sick Day',
  Weekend = 'Weekend',
  Absence = 'Absence'
}

export const getEventTypeLabel = (eventType: EventType) => {
  switch (eventType) {
    case EventType.Sickness:
      return 'Sickness record';
    case EventType.AnnualLeave:
      return 'Annual leave';
    case EventType.Absence:
      return 'Absence';
  }
};

export interface EventModel extends AbstractIdentifiable {
  id: any;
  startDate: string;
  endDate?: string;
  duration: number;
  periodStartsAtHalfDay?: boolean;
  periodEndsAtHalfDay?: boolean;
  durationType: EventDurationType;
  eventStatus: EventStatus;
  eventType: EventType;
  requestDate?: string;
  personName?: string;
}

export interface CalendarDayEvents {
  fullDayEvent?: CalendarEvent;
  amEvent?: CalendarEvent;
  pmEvent?: CalendarEvent;
  hasEvent: boolean;
  hasMultipleEvents: boolean;
  isHalfDay: boolean;
  isNonWorkingDay: boolean;
  dayOfMonth: number; // 1-31
  month: number; // 1-12
  year: number;
  isToday: boolean;
  date: DateTime;
}

export type CalendarWeekOfDayEvents = (CalendarDayEvents | null)[];
export type CalendarMonthWeeks = CalendarWeekOfDayEvents[];
export type CalendarMonthOfDayEvents = CalendarDayEvents[];

export class CalendarEvent {
  readonly id: any;
  readonly startDateTime: DateTime;
  readonly endDateTime?: DateTime;
  readonly interval?: Interval;
  readonly durationType: EventDurationType;
  readonly eventStatus: EventStatus;
  readonly eventType: EventType;
  readonly periodEndsAtHalfDay?: boolean;
  readonly periodStartsAtHalfDay?: boolean;
  readonly isAMHalfDay: boolean;
  readonly isPMHalfDay: boolean;
  readonly requestDate?: any;

  constructor(eventData: EventModel) {
    this.id = eventData.id;
    this.eventType = eventData.eventType;
    this.eventStatus = eventData.eventStatus;
    this.durationType = eventData.durationType;
    this.periodEndsAtHalfDay = eventData.periodEndsAtHalfDay;
    this.periodStartsAtHalfDay = eventData.periodStartsAtHalfDay;
    this.startDateTime = DateTime.fromISO(eventData.startDate, { zone: 'utc' });
    this.requestDate = eventData.requestDate ? eventData.requestDate : null;

    if (eventData.durationType === EventDurationType.DateRange) {
      this.endDateTime = DateTime.fromISO(eventData.endDate!, { zone: 'utc' });
      this.interval = Interval.fromDateTimes(this.startDateTime, this.endDateTime);
    } else {
      this.isAMHalfDay = this.periodEndsAtHalfDay || this.durationType === EventDurationType.HalfDayStartOfShift;
      this.isPMHalfDay = this.periodStartsAtHalfDay || this.durationType === EventDurationType.HalfDayEndOfShift;
    }
  }

  occursOn = (dateTime: DateTime) => {
    switch (this.durationType) {
      case EventDurationType.DateRange:
        return this.startDateTime <= dateTime.toUTC() && dateTime.toUTC() <= this.endDateTime!;
      default:
        return this.startDateTime.equals(dateTime.toUTC());
    }
  };

  isDateAMHalfDay = (dateTime: DateTime) => {
    if (this.isAMHalfDay && this.startDateTime.equals(dateTime.toUTC())) return true;
    else if (this.periodEndsAtHalfDay && this.endDateTime.equals(dateTime.toUTC())) return true;
    return false;
  };

  isDatePMHalfDay = (dateTime: DateTime) => {
    if (this.isPMHalfDay && this.startDateTime.equals(dateTime.toUTC())) return true;
    else if (this.periodStartsAtHalfDay && this.startDateTime.equals(dateTime.toUTC())) return true;
    return false;
  };
}

export class CalendarEventsList {
  year!: number;
  events!: CalendarEvent[];
  private readonly today = DateTime.utc().startOf('day');

  constructor(events: EventModel[]) {
    this.events = events.map((e) => new CalendarEvent(e));
  }

  private findEvent = (dateTime: DateTime): CalendarEvent | undefined => this.events.find((e) => e.occursOn(dateTime));

  private findAMEvent = (dateTime: DateTime): CalendarEvent | undefined => this.events.find((e) => e.isDateAMHalfDay(dateTime));

  private findPMEvent = (dateTime: DateTime): CalendarEvent | undefined => this.events.find((e) => e.isDatePMHalfDay(dateTime));

  getCalendarDayEvents = (currentDateTime: DateTime): CalendarDayEvents => {
    const events = <Partial<CalendarDayEvents>>{
      amEvent: this.findAMEvent(currentDateTime),
      pmEvent: this.findPMEvent(currentDateTime)
    };

    if (!events.amEvent && !events.pmEvent) events.fullDayEvent = this.findEvent(currentDateTime);

    const hasMultipleEvents = !!events.amEvent && !!events.pmEvent;
    const isHalfDay = !hasMultipleEvents && (!!events.amEvent || !!events.pmEvent);
    const isNonWorkingDay = events.fullDayEvent?.eventStatus === EventStatus.NonWorkingDay;

    const calendarDay: CalendarDayEvents = {
      ...events,
      hasEvent: (!!events.fullDayEvent && !isNonWorkingDay) || !!events.amEvent || !!events.pmEvent,
      hasMultipleEvents,
      isHalfDay,
      isNonWorkingDay,
      dayOfMonth: currentDateTime.day,
      month: currentDateTime.month,
      isToday: this.today.toMillis() == currentDateTime.toMillis(),
      year: currentDateTime.year,
      date: currentDateTime
    };

    return calendarDay;
  };
}
