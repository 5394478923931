import { Injectable, OnDestroy } from '@angular/core';
import { createStore, select, setProps, withProps } from '@ngneat/elf';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { updateInFlight, withInFlight } from '@shared-state';
import { ClutchStatusUpdatedService } from '@shared-update-services';
import { filter, finalize, take } from 'rxjs';
import { PayrollIdentifier } from '../models';
import { ClutchStatusDataProvider } from './clutch-status.data-provider';

export interface ClutchStatusState {
  payrollIdentifier: PayrollIdentifier;
  isPayrollLocked: boolean;
}

@UntilDestroy()
@Injectable()
export class ClutchStatusStateService implements OnDestroy {
  private initialValue: ClutchStatusState = { isPayrollLocked: false, payrollIdentifier: null };

  private store = createStore({ name: 'clutch-status' }, withProps<ClutchStatusState>(this.initialValue), withInFlight(true));

  isPayrollLocked$ = this.store.pipe(
    filter((x) => !x.inFlight),
    select((x) => x.isPayrollLocked)
  );

  clutchStatusState$ = this.store.pipe(filter((x) => !x.inFlight));

  constructor(
    private clutchStatusDataProvider: ClutchStatusDataProvider,
    private clutchStatusUpdatedService: ClutchStatusUpdatedService
  ) {
    this.clutchStatusUpdatedService.updated.pipe(untilDestroyed(this)).subscribe(() => this.reload());
  }

  initialise(payrollIdentifier: PayrollIdentifier) {
    this.store.update(updateInFlight(true));

    this.clutchStatusDataProvider
      .payrollLocked$(payrollIdentifier)
      .pipe(
        take(1),
        finalize(() => this.store.update(updateInFlight(false)))
      )
      .subscribe((isPayrollLocked) => this.store.update(setProps({ payrollIdentifier, isPayrollLocked })));
  }

  reload() {
    const { payrollIdentifier } = this.store.getValue();
    if (payrollIdentifier) {
      this.initialise(payrollIdentifier);
    }
  }

  ngOnDestroy(): void {
    this.store.destroy();
  }
}
