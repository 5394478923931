<app-content class="toggle-panel" [ngClass]="ngClass">
  <div class="title row g-0 align-items-center">
    <div class="col-md-10">
      <app-icon [color]="iconColor" class="me-3">{{ icon }}</app-icon>
      <app-body-sm bold>{{ title }}</app-body-sm>
    </div>

    <div class="toggle col d-flex justify-content-end">
      <mat-icon (click)="toggled = !toggled">
        {{ toggled ? 'keyboard_arrow_right' : 'keyboard_arrow_down' }}
      </mat-icon>
    </div>
  </div>
  <div class="content" [style.display]="toggled ? 'block' : 'none'">
    <ng-content></ng-content>
  </div>
</app-content>
