import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { PayrollContext } from '@data-import/data-import';
import { ButtonComponent } from '@design/buttons/button/button.component';
import { EntityDataset } from '@entity-framework/datasets/entity-dataset';
import { LoggingService } from '@logging/logging.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OnscreenMessagingService } from '@pattern-library/onscreen-messaging/onscreen-messaging.service';
import { BehaviorSubject } from 'rxjs';
import { PayrollLockedDataProvider } from '../payroll-locked/payroll-locked.data-provider';
import { EmployerPayrolls } from './state/employer-payrolls';
import { OrganisationPayrollsDataProvider } from './state/organisation-payrolls.data-provider';
import { OrganisationPayrollsStateService } from './state/organisation-payrolls.state-service';

@UntilDestroy()
@Component({
  selector: 'app-employer-payroll-select',
  templateUrl: './employer-payroll-select.component.html',
  styleUrls: ['./employer-payroll-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [OrganisationPayrollsStateService, OrganisationPayrollsDataProvider]
})
export class EmployerPayrollSelectComponent {
  private employerControl = new UntypedFormControl(null);
  private payrollControl = new UntypedFormControl(null);
  private payrollsSubject = new BehaviorSubject<EntityDataset>(null);

  payrollLocked$ = new BehaviorSubject<boolean>(false);

  @Input() centerContent: boolean;
  @Input() validatePayrollLocked: boolean;
  @Output() payrollSelected = new EventEmitter<PayrollContext>();
  @Output() cancelled = new EventEmitter<void>();

  form = new UntypedFormGroup({
    employer: this.employerControl,
    payrollId: this.payrollControl
  });
  employersDataset$ = this.state.employersDataset$;
  payrollsDataset$ = this.payrollsSubject.asObservable();

  @ViewChild('nextButton') nextButton: ButtonComponent;

  constructor(
    private state: OrganisationPayrollsStateService,
    protected messaging: OnscreenMessagingService,
    protected logger: LoggingService,
    private payrollLockedDataProvider: PayrollLockedDataProvider
  ) {
    this.employerControl.valueChanges.pipe(untilDestroyed(this)).subscribe((employer: EmployerPayrolls) => {
      this.payrollsSubject.next(employer.childDataset);
      if (employer.childDataset.items.length === 1) {
        const payroll = employer.childDataset.items[0];
        this.form.patchValue({ payrollId: payroll.id });
      }
    });

    this.payrollControl.valueChanges.pipe(untilDestroyed(this)).subscribe((id) => {
      this.nextButton.disabled = false;
      this.payrollLocked$.next(false);
    });

    this.employersDataset$.pipe(untilDestroyed(this)).subscribe((org) => {
      if (org.items.length === 1) {
        this.form.patchValue({ employer: org.items[0] });
      }
    });
  }

  next() {
    if (this.validatePayrollLocked) {
      const payrollId = this.form.get('payrollId').value;
      this.payrollLockedDataProvider.payrollLocked$(payrollId).subscribe((locked) => {
        if (locked) {
          this.payrollLocked$.next(true);
        } else {
          this.notifyPayrollSelected();
        }
      });
    } else {
      this.notifyPayrollSelected();
    }
  }

  cancel() {
    this.cancelled.next();
  }

  private notifyPayrollSelected() {
    const employerId = this.form.get('employer').value.id;
    const payrollId = this.form.get('payrollId').value;
    this.payrollSelected.next({ employerId, payrollId });
  }
}
