import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { LoggingService } from '@logging/logging.service';
import { environment } from '@env/environment';
import { EmployerStatistics } from './employer-statistics';

@Injectable()
export class EmployerStatisticsDataProvider {
  constructor(protected httpClient: HttpClient, protected logger: LoggingService) {}

  readMany$(employerId: number): Observable<EmployerStatistics> {
    const endpoint = `${environment.apiPaths.employers}/${employerId}/payroll-statistics`;
    this.logger.trace('EmployerPayrollStatsDataProvider: readMany', endpoint);
    return this.httpClient.get<EmployerStatistics>(endpoint);
  }
}
