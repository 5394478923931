import { InjectionToken } from '@angular/core';
import { HashMap } from '@utils/hash-map';
import { Observable } from 'rxjs';

export type FeatureFlagType = boolean | string | number | undefined;
export type FeatureFlagKey =
  | 'designSystemFeature'
  | 'unitTestFeatureFlag'
  | 'unitTestTargetFlag'
  | 'unitTestFeatureFlagOff'
  | 'underMaintenance'
  | 'testUnderMaintenance'
  | 'underMaintenanceText'
  | 'currentUiVersion'
  | 'none'
  | 'messagingUnderMaintenance'
  | 'showManageEmployeeAccountsTab'
  | 'allowGroupHandover'
  | 'showGroupRecalculationBanner'
  | 'showNewPaySummary'
  | 'groupPayrollPeriodSelector'
  | 'iqWebClientLink'
  | 'iqWebClientLinkText'
  | 'entitlementsGridPanel'
  | 'equifaxOptOut'
  | 'cintraIqInAws'
  | 'dynamicReporting'
  | 'salesCampaignPopup2024'
  | 'salesCampaignPopupWidgetId'
  | 'carryForwardHolidayDays'
  | 'paySpinesNewStarterBulkUpload'
  | 'entitlementSchemeSettings'
  | 'cintraHrLog'
  | 'bureauDataMining'
  | 'bureauBankFiles'
  | 'newGroupEmployeeAssignment'
  | 'manageAbsences';

export type FeatureFlagBooleanValue = 'on' | 'off';

export interface FeatureFlagService {
  getValue$(key: FeatureFlagKey, defaultValue?: FeatureFlagType): Observable<FeatureFlagType>;

  getValue$<T extends FeatureFlagType>(key: FeatureFlagKey, defaultValue?: T): Observable<T>;

  getValue(key: FeatureFlagKey, defaultValue?: FeatureFlagType): Promise<FeatureFlagType>;

  getAllValues$(): Observable<HashMap<FeatureFlagType>>;
}

export const FeatureFlagsServiceInjectionToken = new InjectionToken<FeatureFlagService>('FeatureFlagsService');
