import { Inject, Injectable } from '@angular/core';
import { IdleActivityConfig } from './idle-activity-config';
import { IdleActivityConfigInjectionToken } from './idle-activity-config-injection.token';
import { LoggingService } from '../logging-and-error-handling/logging.service';

@Injectable({ providedIn: 'root' })
export class IdleUtils {
  constructor(@Inject(IdleActivityConfigInjectionToken) private config: IdleActivityConfig, private logger: LoggingService) {}

  trace(message: string, ...args: any[]) {
    if (this.config.trace) {
      this.logger.trace(message, ...args);
    }
  }
}
