import { ChangeDetectionStrategy, Component } from '@angular/core';

/**
 * Simple layout component that has a standard margin-bottom so that content flows with consistent spacing.
 */
@Component({
  selector: 'app-content',
  templateUrl: './content-block.component.html',
  styleUrls: ['./content-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContentBlockComponent {}
