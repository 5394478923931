import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { PatternLibraryModule } from '@pattern-library/pattern-library.module';
import { FeatureFlagModule } from '@feature-flags/feature-flag.module';
import { DesignSystemModule } from '@design/design-system.module';
import { DesignSystemFormsModule } from '@design/forms/design-system-forms.module';
import { EntityFrameworkModule } from '@entity-framework/entity-framework.module';
import { JsonSchemaFormModule } from '@json-schema-form/json-schema-form.module';
import { StepperModule } from '@stepper';
import { ElfsightModule } from '../framework/elfsight/elfsite.module';
import { AuthorisationModule } from '@security/authorization/authorisation.module';

/**
 * Standard import for feature modules.
 */
@NgModule({
  exports: [
    RouterModule,
    PatternLibraryModule,
    DesignSystemModule,
    DesignSystemFormsModule,
    FeatureFlagModule,
    AuthorisationModule,
    JsonSchemaFormModule,
    EntityFrameworkModule,
    StepperModule,
    ElfsightModule
  ]
})
export class SharedModule {}
