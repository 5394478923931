import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DesignSystemModule } from '@design/design-system.module';
import { StepperProgressCountComponent } from './stepper-progress-count/stepper-progress-count.component';
import { StepperProgressPanelComponent } from './stepper-progress-panel/stepper-progress-panel.component';

@NgModule({
  declarations: [StepperProgressPanelComponent, StepperProgressCountComponent],
  providers: [],
  imports: [CommonModule, DesignSystemModule],
  exports: [StepperProgressPanelComponent, StepperProgressCountComponent]
})
export class StepperModule {}
