import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractFormFieldComponent } from '@design/forms/abstract-form-field.component';
import { Validators } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NumberValidators } from '@design/forms/form-validation';
import { MatInput } from '@angular/material/input';

@UntilDestroy()
@Component({
  selector: 'app-input-field',
  templateUrl: './input-field.component.html',
  styleUrls: ['./input-field.component.scss']
})
export class InputFieldComponent extends AbstractFormFieldComponent implements OnInit, AfterViewInit {
  @Input() type = 'text';
  @Input() hint = '';
  @Input() minLength = 2;
  @Input() maxLength = 100;
  @Input() minValue?: number;
  @Input() maxValue?: number;
  @Input() decimalPlaces?: number;
  @Input() step?: number;
  @Input() trimOnBlur = false;
  @Input() class: string;
  @Input() autoFocus: boolean = false;

  @ViewChild(MatInput) inputControl: MatInput;

  ngOnInit(): void {
    super.ngOnInit();

    this.class = this.class + ' field';

    if (this.type !== 'number') {
      if (this.minLength > 0) this.otherValidators.push(Validators.minLength(this.minLength));
      if (this.maxLength > 0) this.otherValidators.push(Validators.maxLength(this.maxLength));
    } else {
      // check if min / max value has been provided, and also check they are valid numbers before applying validators
      if (this.minValue != null && !isNaN(this.minValue)) {
        this.otherValidators.push(Validators.min(Number(this.minValue)));
      }

      if (this.maxValue != null && !isNaN(this.maxValue)) {
        this.otherValidators.push(Validators.max(Number(this.maxValue)));
      }

      if (this.decimalPlaces != null && !isNaN(this.decimalPlaces)) {
        this.otherValidators.push(NumberValidators.maxDecimalPlaces(this.decimalPlaces));
      }
    }
  }

  onBlur() {
    if (!this.trimOnBlur) return;

    const value = this.formControl.value as string;
    if (value) this.formControl.setValue(value.trim());
  }

  ngAfterViewInit(): void {
    /*
      The default behaviour of type 'number' writes the value as a string,
      this logic will write the value as a number instead.
    */
    if (this.type === 'number') {
      this.formControl.valueChanges
        .pipe(
          tap((value) => {
            // don't emit event to prevent valueChanges triggering infinitely from this update
            // input[type="number"] already prevents wrong input, but we shouldn't numberise the string `-`
            if (value.length && !isNaN(value)) {
              this.formControl.setValue(Number(value), { emitEvent: false });
            }
          }),
          untilDestroyed(this)
        )
        .subscribe((v) => v);
    }

    if (this.autoFocus) {
      setTimeout(() => this.inputControl.focus());
    }
  }
}
