import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Summary } from '../summary-tile/summary';

@Component({
  selector: 'app-summary-group',
  templateUrl: './summary-tile-group.component.html',
  styleUrls: ['./summary-tile-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SummaryTileGroupComponent {
  @Input() summaries: Summary[];
}
