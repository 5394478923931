import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-tile-layout',
  templateUrl: './tile-layout.component.html',
  styleUrls: ['./tile-layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TileLayoutComponent {
  maxColsClass = 'max-cols-4';
  @Input() set maxCols(maxCols: number) {
    this.maxColsClass = `max-cols-${maxCols}`;
  }
}
