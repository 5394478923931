import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { distinctUntilChanged } from 'rxjs';
import { ClutchStatusState, ClutchStatusStateService } from '../../clutch-status.state-service';
import { PayrollIdentifier } from '../../models';
import { CalcInProgressModalComponent } from '../calc-in-progress-modal/calc-in-progress-modal.component';
import { RecalculationStateService } from '../recalculation.state-service';
import { NotificationBannerConfig } from '@design/components/notification-banner/notification-banner-config';

@UntilDestroy()
@Component({
  selector: 'app-recalculation-banner',
  templateUrl: './recalculation-banner.component.html',
  styleUrls: ['./recalculation-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RecalculationStateService]
})
export class RecalculationBannerComponent {
  clutchStatus$ = this.clutchStatusStateService.clutchStatusState$;
  inFlight$ = this.calcService.inFlight$;

  private dialogRef: MatDialogRef<CalcInProgressModalComponent>;

  @Input() canRecalculate: boolean;
  bannerConfig: NotificationBannerConfig = {
    icon: 'none'
  };

  constructor(
    private calcService: RecalculationStateService,
    private clutchStatusStateService: ClutchStatusStateService,
    private dialog: MatDialog
  ) {
    this.inFlight$.pipe(untilDestroyed(this), distinctUntilChanged()).subscribe((inFlight) => {
      if (inFlight && !this.dialogRef) {
        this.dialogRef = this.dialog.open(CalcInProgressModalComponent, { disableClose: true });
      } else {
        this.dialogRef?.close();
        this.dialogRef = null;
      }
    });
  }

  getNotice(isPayrollLocked: boolean): string {
    if (!isPayrollLocked && this.canRecalculate) {
      return 'Changes have been made to your payroll, recalculate for your new estimated total.';
    } else {
      return 'Changes have been made to your payroll, the exact amount will be available once your payroll has been finalised.';
    }
  }

  getConfig(clutchStatus: ClutchStatusState): NotificationBannerConfig {
    return {
      ...this.bannerConfig,
      textLeft: `Notice: ${this.getNotice(clutchStatus.isPayrollLocked)}`,
      buttonText: 'Recalculate',
      showButton: this.canRecalculate && !clutchStatus.isPayrollLocked,
      buttonClick: () => this.calcService.recalculate(clutchStatus.payrollIdentifier),
      tid: 'recalculate-payroll'
    };
  }
}
