import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';

import { LoggingService } from '@logging/logging.service';
import { FullscreenModalConfig } from './fullscreen-modal-config';
import { FullscreenModalComponent } from './fullscreen-modal.component';

@Injectable({ providedIn: 'root' })
export class FullscreenModalFactory {
  constructor(private dialog: MatDialog, private logger: LoggingService) {}

  openModal(dialogData: FullscreenModalConfig): MatDialogRef<FullscreenModalComponent> {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.width = dialogConfig.maxWidth = 'calc(100vw - 8rem)';
    dialogConfig.height = 'calc(100vh - 8rem)';
    dialogConfig.data = dialogData;

    this.logger.trace('FullscreenModalFactory: openModal', dialogConfig);

    return this.dialog.open(FullscreenModalComponent, dialogConfig);
  }

  closeModal() {
    this.dialog.closeAll();
  }
}
