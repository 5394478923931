<div [ngClass]="{ 'view-card': !hideCard, 'hide-card': hideCard }" *ngIf="cssBreakpointsService.breakpoints$ | async as breakpoints">
  <div class="card-header" [ngClass]="{ 'cursor-pointer': collapsible }" (click)="showHide()">
    <div class="row">
      <div class="col-10 col-md-6">
        <app-icon *ngIf="collapsible" [color]="'primary-action'" [size]="'extra-small'" class="expand-icon start" [clickable]="true">
          {{ collapsed ? 'expand_more' : 'expand_less' }}
        </app-icon>
        <app-h5 [inline]="true">{{ this.cardTitle }}</app-h5>
        <app-body-sm *ngIf="this.cardSubTitle !== ''" class="ms-4">{{ this.cardSubTitle }}</app-body-sm>

        <div class="inline ms-4" *ngFor="let entry of this.cardTertiaryTitles">
          <app-h5 [inline]="true">{{ entry.title }}</app-h5>
          <app-body-sm class="ms-4">{{ entry.value }}</app-body-sm>
        </div>
      </div>
      <div class="col-2 col-md-6 text-end">
        <ng-container *ngIf="showEdit && breakpoints.isLargeScreen" [ngTemplateOutlet]="editLink"></ng-container>
        <app-icon
          *ngIf="breakpoints.isSmallScreen || (breakpoints.isMediumScreen && !collapsible)"
          [color]="'primary-action'"
          [size]="'extra-small'"
          class="expand-icon"
          [clickable]="true"
        >
          {{ collapsed ? 'expand_more' : 'expand_less' }}
        </app-icon>
      </div>
    </div>
  </div>
  <div *ngIf="!collapsed || (breakpoints.isLargeScreen && !collapsible)" class="card-body">
    <div class="edit-link">
      <ng-container
        *ngIf="showEdit && (breakpoints.isSmallScreen || breakpoints.isMediumScreen)"
        [ngTemplateOutlet]="editLink"
      ></ng-container>
    </div>
    <ng-content></ng-content>
  </div>
</div>

<ng-template #editLink>
  <app-a (click)="edit($event)" [ngClass]="{ 'edit-link': true, disabled: disableEditButton }">
    <app-icon class="pt-3" [clickable]="true" [color]="'primary-action'" [hoverColor]="'primary-action'" [size]="'extra-small'">
      edit
    </app-icon>
    <span *ngIf="editPopoverText.length < 1">EDIT</span>

    <app-popover linkText="EDIT" *ngIf="editPopoverText.length > 0" [onHover]="true">
      <app-p *ngFor="let para of editPopoverText">
        {{ para }}
      </app-p>
    </app-popover>
  </app-a>
</ng-template>
