import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NotificationBannerConfig } from './notification-banner-config';

@Component({
  selector: 'app-notification-banner',
  templateUrl: './notification-banner.component.html',
  styleUrl: './notification-banner.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationBannerComponent {
  @Input() config: NotificationBannerConfig;

  callClick($event): void {
    this.config.buttonClick($event);
  }
}
