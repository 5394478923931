import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AbstractEntityDataProvider } from '../../entity-record/entity-data-provider/abstract-entity.data-provider';

import { EntityListResponse } from '../state/entity.list-response';
import { AbstractIdentifiable } from '../../entity-record/state/abstract-entity';
import { LoggingService } from '@logging/logging.service';

export abstract class AbstractEntityCollectionDataProvider<
  TEntity extends AbstractIdentifiable
> extends AbstractEntityDataProvider<TEntity> {
  protected constructor(protected httpClient: HttpClient, protected logger: LoggingService) {
    super(httpClient, logger);
  }

  readMany$(params?: any): Observable<EntityListResponse<TEntity>> {
    const endpoint = this.getBaseEndpoint();
    this.logger.trace('AbstractEntityCollectionDataProvider: readMany', endpoint);
    return this.httpClient.get<EntityListResponse<TEntity>>(endpoint, { params });
  }
}
