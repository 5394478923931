import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-p',
  template: '<p [ngClass]="ngClass"><ng-content></ng-content></p>',
  styleUrls: ['./p.component.scss']
})
export class PComponent {
  @Input() textCenter: undefined | boolean;

  get ngClass(): string[] {
    return [this.textCenter !== undefined ? 'text-center' : ''];
  }
}
