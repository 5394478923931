<span class="navigator" *ngIf="navigatorStateService.state$ | async as state">
  <button
    [ngClass]="{ disabled: state.previousDisabled }"
    class="month-nav-button previous"
    tid="previous-month"
    *ngIf="state.calendarMode !== 'year'"
    (click)="this.navigatorStateService.incrementActiveDateMonth(-1)"
  >
    <app-icon color="system-purple">chevron_left</app-icon>
  </button>

  <app-body-xl tid="month-navigator-title" bold noMargin>{{ formatDate(state.activeDate) }}</app-body-xl>

  <button
    [ngClass]="{ disabled: state.nextDisabled }"
    class="month-nav-button next"
    tid="next-month"
    *ngIf="state.calendarMode !== 'year'"
    (click)="this.navigatorStateService.incrementActiveDateMonth(1)"
  >
    <app-icon color="system-purple">chevron_right</app-icon>
  </button>
</span>
