<div class="app-select" *ngIf="formControl" (click)="onContainerClick()">
  <app-form-label *ngIf="formLabel">{{ formLabel }}</app-form-label>
  <mat-select
    *ngIf="dataset"
    #select
    [placeholder]="label"
    [formControl]="formControl"
    [required]="required"
    [multiple]="multiple"
    disableOptionCentering
    disableRipple
    panelClass="app-select-panel mat-elevation-z0"
    [ngClass]="{ open: select.panelOpen, dark: selectTheme === 'dark', white: selectTheme === 'white', multiple: multiple }"
    [compareWith]="dataset.compare"
  >
    <mat-select-trigger *ngIf="multiple && getSelectedValues() as selectedValues">
      <app-compact-values-label [values]="selectedValues" [maxItems]="maxSelectedItemsToDisplay"></app-compact-values-label>
    </mat-select-trigger>
    <mat-optgroup *ngFor="let groupItem of dataset.items" [label]="dataset.getLabel(groupItem)">
      <mat-option *ngFor="let item of groupItem.childDataset.items" [value]="groupItem.childDataset.getValue(item)">
        {{ groupItem.childDataset.getLabel(item) }}
      </mat-option>
    </mat-optgroup>
  </mat-select>
  <mat-error *ngIf="formControl.touched && formControl.hasError('required')">Required</mat-error>
</div>
