<app-content>
  <form [formGroup]="form">
    <div class="row" [class.justify-content-center]="centerContent">
      <div class="col-4">
        <app-form-label>Employer</app-form-label>
        <app-content>
          <app-dataset-select
            tid="employer-select"
            label="Select an employer"
            title="Select an employer"
            formControlName="employer"
            *ngIf="employersDataset$ | async as employersDataset"
            [dataset]="employersDataset"
          ></app-dataset-select>
        </app-content>

        <app-content *ngIf="payrollsDataset$ | async as payrollsDataset">
          <app-form-label>Payroll</app-form-label>
          <app-dataset-select
            tid="payroll-select"
            label="Select a payroll"
            title="Select a payroll"
            formControlName="payrollId"
            [dataset]="payrollsDataset"
          ></app-dataset-select>
        </app-content>
      </div>
      <div class="col-12" *ngIf="validatePayrollLocked">
        <div class="row">
          <mat-error *ngIf="payrollLocked$ | async">
            We are currently processing this payroll. You cannot input new starters at this time.
          </mat-error>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <!-- <app-button (click)="cancel()">Cancel</app-button> -->
      </div>
      <div class="col-6 d-flex justify-content-end">
        <app-button tid="next-button" (click)="next()" theme="primary-action" [disabled]="true" #nextButton>Next</app-button>
      </div>
    </div>
  </form>
</app-content>
