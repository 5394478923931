<ngx-file-drop
  dropZoneLabel="Drop files here"
  (onFileDrop)="dropped($event)"
  (onFileOver)="fileOver($event)"
  (onFileLeave)="fileLeave($event)"
  [accept]="acceptedFileExtensions"
  [multiple]="false"
>
  <!-- Optional custom content that replaces the the entire default content. -->
  <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
    <div id="file-area" tid="file-area">
      <app-p textCenter>
        <app-body>
          <mat-icon class="yellow">cloud_upload</mat-icon>
        </app-body>
      </app-p>

      <app-p textCenter class="mb-2 pr-5 pl-5">
        <app-body bold>
          {{ prompt }}
          <app-a (click)="openFileSelector()" routeTo="">browse this device</app-a>
        </app-body>
      </app-p>

      <app-p textCenter class="mb-2">
        <app-footnote>Supports {{ acceptedFileExtensions }}</app-footnote>
      </app-p>

      <app-p textCenter>
        <app-body-sm bold *ngFor="let fileInfo of filesInfos$ | async; let i = index">
          {{ fileInfo.file.name }} ({{ fileInfo.fileSize }})
        </app-body-sm>
      </app-p>

      <br />
    </div>
  </ng-template>
</ngx-file-drop>
