<ng-container *ngFor="let action of actions">
  <app-button
    class="action-button"
    theme="primary-action"
    [tid]="action.tid"
    [type]="action.buttonType || 'button'"
    (click)="action.execute && action.execute()"
    [title]="action.label"
    [disabled]="action.disabled"
  >
    <span>{{ action.label }}</span>
  </app-button>
</ng-container>
