import { Router } from '@angular/router';

export const integrationsRoutes = {
  hub: 'integrations/hub',
  create: (router: Router) => {
    router
      .navigate(['', { outlets: { modal: ['integrations', 'create'] } }], {
        queryParamsHandling: 'preserve'
      })
      .then();
  },
  edit: (router: Router, integrationId: any) => {
    router
      .navigate(['', { outlets: { modal: ['integrations', 'edit', integrationId] } }], {
        queryParamsHandling: 'preserve'
      })
      .then();
  },
  intelliHrSetup: (router: Router, integrationId: any, organisationId: any) => {
    router
      .navigate(['', { outlets: { modal: ['integrations', 'intellihr-setup', integrationId, organisationId] } }], {
        queryParamsHandling: 'preserve'
      })
      .then();
  },
  exportData: (router: Router) => {
    router
      .navigate(['', { outlets: { modal: ['integrations', 'export-data-options'] } }], {
        queryParamsHandling: 'preserve'
      })
      .then();
  },
  exportDataDownloadModal: (router: Router, isEducation: boolean, includeLeaversFrom: string) => {
    router
      .navigate(['', { outlets: { modal: ['integrations', 'export-data-success', isEducation, includeLeaversFrom] } }], {
        queryParamsHandling: 'preserve'
      })
      .then();
  },
  viewErrors: (router: Router, integrationId: string) => {
    router
      .navigate(['', { outlets: { modal: ['integrations', 'event-errors', integrationId] } }], {
        queryParamsHandling: 'preserve'
      })
      .then();
  },
  createAppClient: (router: Router) => {
    router
      .navigate(['', { outlets: { modal: ['integrations', 'create-app-client'] } }], {
        queryParamsHandling: 'preserve'
      })
      .then();
  },
  mapping: (router: Router, integrationId: string, organisationId: any) => {
    router
      .navigate(['', { outlets: { modal: ['integrations', 'mapping', integrationId, organisationId] } }], {
        queryParamsHandling: 'preserve'
      })
      .then();
  }
};
