import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PayrollPeriodDetails } from '@shared-payrolls';

@Component({
  selector: 'app-payroll-period',
  templateUrl: './payroll-period.component.html',
  styleUrls: ['./payroll-period.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PayrollPeriodComponent {
  @Input() payrollPeriod: PayrollPeriodDetails;
}
