import { Component, Input, Output, EventEmitter, ViewChild, AfterViewInit, input } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { CintraThemePalette } from '@design/theme/cintra-theme-palette';
import { IconColor, IconSize } from '@design/buttons/icon/icon-config';

export type ButtonType = 'ng-content-button' | 'icon-button' | 'text-icon-button' | 'tab-button' | 'basic-button';
export type ButtonWidth = 'medium';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent extends MatButton implements AfterViewInit {
  private _loading = false;
  private __disabled: any = false;
  @Input() type = 'button';
  @Input() focussed = false;
  @Input() theme: CintraThemePalette = undefined;
  @Input() icon?: string = undefined;
  @Input() iconLeft: boolean = false;
  @Input() iconSize: IconSize;
  @Input() iconColor?: IconColor;
  @Input() iconOutlined?: boolean;
  @Input() iconHoverColor?: IconColor;
  @Input() buttonText?: string = undefined;
  @Input() elevated = false;
  @Input() tooltip: string = undefined;
  @Input() tabButton?: boolean;
  @Input() hyperlinkButton?: boolean;
  @Input() noPadding?: boolean;
  @Input() basicButton: boolean = false;
  @Input() uppercaseText = true;
  @Input() buttonWidth?: ButtonWidth;
  @Input() shadowed = false;
  @Input() showAsHyperlink: boolean = false;
  @Input() iconImagePath?: string = undefined;
  @Input() visible: boolean;
  @Input() fontColor: CintraThemePalette = undefined;
  @Input() useSymbol?: boolean;
  @Input() badgeCount?: number;

  @Input() set disabled(disabled: boolean) {
    this.__disabled = disabled;
  }
  get disabled(): boolean {
    return this.__disabled || this._loading;
  }

  @Input() set loading(loading: boolean) {
    this._loading = loading;
  }
  get loading(): boolean {
    return this._loading;
  }

  @ViewChild(HTMLButtonElement)
  set button(button: HTMLButtonElement) {
    if (!(button && this.focussed)) return;

    button.querySelector('button').focus();
    this.focussed = false;
  }

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() clickEvent = new EventEmitter<void>();

  get ngClass(): string[] {
    const classes: string[] = [this.buttonType, this.iconLeft === true ? 'icon-left' : 'icon-right'];

    if (this.elevated !== false) classes.push('elevated');

    if (this.noPadding !== undefined) classes.push('no-padding');

    if (this.uppercaseText) classes.push('uppercase-text');

    if (this.showAsHyperlink) classes.push('underline-text-on-hover');

    if (this.buttonWidth === 'medium') classes.push('width-medium');

    if (this.shadowed === true) classes.push('shadowed');

    if (this.__disabled === true) classes.push('button-disabled');

    if (this.fontColor !== undefined) classes.push('text-color-' + this.fontColor);

    return classes;
  }

  get buttonType(): ButtonType {
    if (this.basicButton) return 'basic-button';
    if (this.tabButton !== undefined) return 'tab-button';
    if (this.buttonText !== undefined && this.icon != undefined) return 'text-icon-button';
    if (this.icon != undefined) return 'icon-button';
    return 'ng-content-button';
  }

  callClick(event$) {
    if (this.disabled) return;
    this.clickEvent.emit(event$);
  }
}
