import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ElfsightPopupComponent } from './elfsight-popup/elfsight-popup.component';

@NgModule({
  declarations: [ElfsightPopupComponent],
  imports: [CommonModule],
  exports: [ElfsightPopupComponent]
})
export class ElfsightModule {}
