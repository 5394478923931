import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-compact-values-label',
  templateUrl: './compact-values-label.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompactValuesLabelComponent {
  @Input() values: string[];
  @Input() maxItems: number = 2;
}
