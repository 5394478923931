<div id="header-content" tid="header-content">
  <ng-content select="[header-content]"></ng-content>
  <div id="header-wrapper" tid="header-wrapper" *ngIf="title">
    <app-icon [config]="headerIconOptions" [size]="'medium'" *ngIf="headerIconOptions">
      {{ headerIconOptions?.iconName }}
    </app-icon>
    <app-h5 noMargin *ngIf="subtitle" ink="ink-white">{{ subtitle }}</app-h5>
    <app-h4 noMargin ink="ink-white" [class.offset]="subtitle">{{ title }}</app-h4>
  </div>
  <app-icon class="close-icon" color="white" hoverColor="ink-45" (click)="closeClicked()" tid="close-modal-icon" clickable="true">
    close
  </app-icon>
</div>

<div id="body-content" (scroll)="contentScrolled($event)">
  <ng-content select="[body-content]"></ng-content>
</div>

<div id="action-buttons" *ngIf="displayFooter">
  <ng-content select="[footer-content-left]"></ng-content>

  <ng-content select="[footer-content-right]"></ng-content>
</div>

<div *ngIf="!displayFooter" style="height: 3.2rem"></div>
