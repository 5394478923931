import { Component, Input } from '@angular/core';
import { Ink } from '@design/typography/ink';

@Component({
  selector: 'app-h2',
  template: '<h2 [ngClass]="ngClass"><ng-content></ng-content></h2>',
  styleUrls: ['./h2.component.scss']
})
export class H2Component {
  @Input() noMargin?: boolean;
  @Input() ink: Ink = 'primary';

  get ngClass(): string[] {
    return [this.noMargin !== undefined ? 'no-margin' : '', this.ink];
  }
}
