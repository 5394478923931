<div class="wrapper">
  <input matInput [matDatepicker]="picker" readonly [value]="selectedDate.toISO()" />
  <mat-datepicker-toggle matPrefix [for]="picker" [disableRipple]="true"></mat-datepicker-toggle>
  <mat-datepicker
    #picker
    [calendarHeaderComponent]="customCalendarHeader"
    startView="multi-year"
    (yearSelected)="onYearSelected($event); picker.close()"
  >
  </mat-datepicker>
</div>
