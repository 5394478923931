import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

/**
 * Aids the strategy of locating elements to be asserted on in acceptance tests by a special attribute data-tid-{name}
 * in order to reduce brittleness. This is instead of searching by class/id which change more often.
 * Usage on html elements: <button tid="submit-button" />, creates the attribute 'data-tid-submit-button'.
 */
// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: '[tid]' })
export class TestIdDirective implements AfterViewInit {
  @Input() tid?: string;

  constructor(private el: ElementRef) {}

  ngAfterViewInit() {
    if (!this.tid) return;
    this.el.nativeElement.setAttributeNode(document.createAttribute(`data-tid-${this.tid}`));
  }
}
