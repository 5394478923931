import { PresetKeys } from '@shared-models';

type GridType =
  | 'employees'
  | 'new-starters'
  | 'leavers'
  | 'one-off-payments'
  | 'one-off-payments-archive'
  | 'one-off-payments-by-cost-code'
  | 'one-off-payments-by-heading'
  | 'salary-and-allowances'
  | 'live-reports';

interface GridIdentifier {
  gridType: GridType;
  gridPresetKey: string;
}

export function mapToGridIdentifier(gridPresetKey: string): GridIdentifier {
  switch (gridPresetKey) {
    case PresetKeys.OneOffPayments:
      return { gridType: 'one-off-payments', gridPresetKey };
    case PresetKeys.OneOffPaymentsArchive:
      return { gridType: 'one-off-payments-archive', gridPresetKey };
    case PresetKeys.OneOffPaymentsByCostCode:
      return { gridType: 'one-off-payments-by-cost-code', gridPresetKey };
    case PresetKeys.OneOffPaymentsByHeading:
      return { gridType: 'one-off-payments-by-heading', gridPresetKey };
    case PresetKeys.SalaryAndAllowances:
      return { gridType: 'salary-and-allowances', gridPresetKey };
    case PresetKeys.AllEmployees:
    case PresetKeys.CurrentEmployees:
    case PresetKeys.SelectedPeriodEmployees:
      return { gridType: 'employees', gridPresetKey };
    case PresetKeys.CurrentPeriodLeavers:
    case PresetKeys.SelectedPeriodLeavers:
    case PresetKeys.LeaversAllTime:
      return { gridType: 'leavers', gridPresetKey };
    case PresetKeys.CurrentPeriodNewStarters:
    case PresetKeys.SelectedPeriodNewStarters:
      return { gridType: 'new-starters', gridPresetKey };
    case PresetKeys.LiveReports:
      return { gridType: 'live-reports', gridPresetKey };
    default:
      return { gridType: 'employees', gridPresetKey };
  }
}
