import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-check-mark',
  templateUrl: './check-mark.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckMarkComponent {
  @Input() value: boolean;
  @Input() indeterminate: boolean = false;
}
