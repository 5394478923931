import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Subscription } from 'rxjs';
import { ActiveSecurityContextStateService } from '@security/active-security/active-security-context.state-service';
import { environment } from '@env/environment';
import { ActiveCintraProductService } from '@app/active-cintra-product.service';

interface BeamerConfig {
  user_id: string;
  user_email?: string;
  product_id: string;
  selector: string;
  display: string;
  callback: () => void;
  filter_by_url: boolean;
  filter: string;
}

declare var window: any;

@Injectable()
export class BeamerService {
  private loaded = false;
  private subscription: Subscription;
  private scriptElement: HTMLScriptElement;
  static readonly elementIdForBeamer = 'beamer-button';
  static readonly bureauFilterPrefix = 'Bureau ';

  constructor(
    public activeSecurity: ActiveSecurityContextStateService,
    public productService: ActiveCintraProductService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  initialise() {
    const testingBeamer = localStorage.getItem('testingBeamer') === 'true';
    if ((!testingBeamer && environment.beamerDisabled) || !this.activeSecurity.canAccessCintraPayrolls()) return;

    this.register();
  }

  register() {
    const activeUser = this.activeSecurity.activeUser;

    let bureauProfileName = this.activeSecurity.activeMembership?.bureauProfile?.name ?? '';

    if (bureauProfileName.length > 0) {
      bureauProfileName = BeamerService.bureauFilterPrefix + bureauProfileName.replace(';', '');
    }

    const cfg: BeamerConfig = {
      product_id: 'YPxXeIlT32495',
      selector: BeamerService.elementIdForBeamer,
      display: 'right',
      user_id: activeUser.cognitoId,
      callback: this.onLoaded,
      filter_by_url: true,
      filter: bureauProfileName
    };

    if (activeUser.email.toLowerCase().indexOf('@cintra') > -1) cfg.user_email = activeUser.email;

    window.beamer_config = cfg;

    const element = document.createElement('script');
    element.async = true;
    element.src = 'https://app.getbeamer.com/js/beamer-embed.js';
    const head = document.getElementsByTagName('head')[0];
    this.scriptElement?.remove();
    head.append(element);

    this.scriptElement = element;
  }

  private onLoaded = () => {
    this.loaded = true;
    if (this.subscription) this.subscription.unsubscribe();
    this.subscription = this.productService.activeProduct$.subscribe((p) => {
      if (p === 'Payroll' || p === 'Bureau') {
        this.enable();
      } else {
        this.disable();
      }
    });
  };

  private disable() {
    window.Beamer?.destroy();
  }

  private enable() {
    if (!this.loaded) this.register();
    else {
      window.Beamer.update(window.beamer_config);
      window.Beamer.init();
    }
  }
}
