import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ActionConfig } from '@design/buttons/action-config';
import { ActionConfigService } from '@design/buttons/action-config.service';
import { IconColor } from '@design/buttons/icon/icon-config';
import { CintraThemePalette } from '@design/theme/cintra-theme-palette';
import { Ink } from '@design/typography/ink';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-button-group',
  templateUrl: './button-group.component.html',
  styleUrls: ['./button-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonGroupComponent {
  actions$: Observable<ActionConfig[]>;

  constructor(private actionConfigService: ActionConfigService) {}

  @Input() set actions(actions: ActionConfig[]) {
    this.actions$ = this.resolveVisibility ? this.actionConfigService.resolveVisibility$(actions) : of(actions);
  }

  @Input() resolveVisibility: boolean = true;
  @Input() disabledText: string;
  @Input() iconColor: IconColor = 'primary';
  @Input() iconHoverColor: IconColor = 'black';
  @Input() ink: Ink = 'ink-85';
  @Input() buttonTheme: CintraThemePalette = undefined;
  @Input() uppercaseText = true;
  @Input() showAsHyperlink = false;

  callExecute(action: ActionConfig): void {
    if (!action?.disabled) {
      action?.execute?.();
    }
  }
}
