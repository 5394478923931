import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { filter, map } from 'rxjs/operators';

import { ActionConfig } from '@design/buttons/action-config';
import { LoggingService } from '@logging/logging.service';
import { OnscreenMessagingService } from '@pattern-library/onscreen-messaging/onscreen-messaging.service';
import { AbstractEntityCollectionStateService } from '@entity-framework/entity-collection/state/abstract-entity-collection.state-service';
import { OrganisationPayrollsState } from './organisation-payrolls.state';
import { OrganisationPayrollsDataProvider } from './organisation-payrolls.data-provider';
import { EmployerPayrolls } from './employer-payrolls';
import { OrganisationPayrollsDataset } from './organisation-payrolls-dataset';
import { combineLatest } from 'rxjs';

/**
 * State for the selectors for managing a payroll where organisation (in context) has many employers has many payrolls
 */
@Injectable()
export class OrganisationPayrollsStateService extends AbstractEntityCollectionStateService<EmployerPayrolls, OrganisationPayrollsState> {
  private static storeName = 'organisation-payrolls-models';

  footerActions: ActionConfig[] = [];
  employersDataset$ = combineLatest([
    this.entities$.pipe(
      filter(Boolean),
      map((employerPayrolls: EmployerPayrolls[]) => new OrganisationPayrollsDataset(employerPayrolls))
    ),
    this.inFlight$
  ]).pipe(
    filter(([_, inFlight]) => !inFlight),
    map(([dataset]) => dataset)
  );

  protected initialValue: Partial<OrganisationPayrollsState> = {
    editing: false
  } as any;

  constructor(
    protected dataProvider: OrganisationPayrollsDataProvider,
    protected route: ActivatedRoute,
    protected messaging: OnscreenMessagingService,
    protected logger: LoggingService
  ) {
    super(OrganisationPayrollsStateService.storeName, dataProvider, route, messaging, logger);
  }
}
