import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Component, Inject } from '@angular/core';

import { Member } from '../../users/models/member';
import { AbstractDataset } from '@entity-framework/datasets/abstract-dataset';

class MembershipDataset extends AbstractDataset<Member, Member> {
  getLabel(item: Member): string {
    return item.organisationName;
  }

  getValue(item: Member): Member {
    return item;
  }
}

@Component({
  templateUrl: './active-membership-selector-dialog.component.html',
  styleUrls: ['./active-membership-selector-dialog.component.scss']
})
export class ActiveMembershipSelectorDialogComponent {
  membershipDataset = new MembershipDataset(this.memberships);
  formGroup = new UntypedFormGroup({
    member: new UntypedFormControl()
  });
  constructor(
    private dialogRef: MatDialogRef<ActiveMembershipSelectorDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    private memberships: Member[]
  ) {}

  confirm() {
    if (this.formGroup.invalid) return;
    this.dialogRef.close(this.formGroup.get('member')?.value);
  }
}
