import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { delay, catchError, switchMap } from 'rxjs/operators';
import { LoggingService } from '@logging/logging.service';

@Injectable()
export class ObjectContextExceptionRetryInterceptor implements HttpInterceptor {
  private maxRetries = 3;
  private retryWaitTime = 1500;

  constructor(private logger: LoggingService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.makeRequest(req, next);
  }

  private makeRequest = (req: HttpRequest<any>, next: HttpHandler, retries = 0): Observable<HttpEvent<any>> => {
    req = req.clone({
      setHeaders: {
        'x-attempt': (retries + 1).toString()
      }
    });

    return next.handle(req).pipe(
      catchError((errorResponse: any) => {
        if (errorResponse.error?.type === 'ObjectDisposedException' && retries <= this.maxRetries) {
          retries++;
          this.logger.warn(`Remote object context disposed error. Retry: ${retries} of ${this.maxRetries}`);
          return of(errorResponse).pipe(
            delay(this.retryWaitTime),
            switchMap(() => this.makeRequest(req, next, retries))
          );
        }
        return throwError(errorResponse);
      })
    );
  };
}
