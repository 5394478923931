import { Component, Input } from '@angular/core';
import { Ink } from '@design/typography/ink';

@Component({
  selector: 'app-h5',
  template: '<h5 [ngClass]="ngClass"><ng-content></ng-content></h5>',
  styleUrls: ['./h5.component.scss']
})
export class H5Component {
  @Input() noMargin?: boolean;
  @Input() center?: boolean;
  @Input() ink: Ink = 'ink-85';
  @Input() inline?: boolean;

  get ngClass(): string[] {
    return [
      this.noMargin !== undefined ? 'no-margin' : '',
      this.center !== undefined ? 'center' : '',
      this.ink,
      this.inline !== undefined ? 'inline' : ''
    ];
  }
}
