import { ChangeDetectionStrategy, ViewEncapsulation } from '@angular/core';
import { Component } from '@angular/core';

@Component({
  selector: 'app-horizontal-layout',
  template: '<div class="h-form-container"><ng-content></ng-content></div>',
  styleUrls: ['./horizontal-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class HorizontalLayoutComponent {}
