import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AbstractFormComponent } from '@design/forms/abstract-form-component';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchInputComponent extends AbstractFormComponent {
  @Input() showBorder: boolean = false;
  @Input() autocomplete: string = 'on';
  @Input() standalone: boolean = false;
  @Input() forContextMenu: boolean = false;
}
