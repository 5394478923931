import { Injectable, NgZone } from '@angular/core';

// see javascript include in scripts section of angular.json
const hermes = (window as any).hermes;

/**
 * Wrapper service that allows events to propagate to other tabs from another source tab
 */
@Injectable({ providedIn: 'root' })
export class BroadcastService {
  constructor(private zone: NgZone) {}
  send(topic, data, includeSelf = false) {
    hermes.send(topic, data, includeSelf);
  }

  on(topic: string, callback: (data: any) => void) {
    // todo: need to refactor the hermes javascript into typescript as its not part of the ng app TO-285
    const fn = (data) => {
      // todo: hence the need for zone.run... TO-285
      this.zone.run(() => {
        callback(data);
      });
    };
    hermes.on(topic, fn);
  }

  off(topic: string, callback?: (data: any) => void) {
    if (callback) hermes.off(topic, callback);
    else hermes.off(topic);
  }
}
