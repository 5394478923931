import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { OnscreenMessagingService } from '@pattern-library/onscreen-messaging/onscreen-messaging.service';
import { OrganisationAccessControlDataProvider } from '@security/authorization/organisation-access-control/organisation-access-control-data-provider';

export interface PrivateOrganisationLoginResponse {
  privateDetails?: string;
  cancelLogin: boolean;
}

@Component({
  selector: 'app-private-organisation-login-dialog',
  templateUrl: './private-organisation-login-dialog.component.html',
  styleUrls: ['./private-organisation-login-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrivateOrganisationLoginDialogComponent {
  constructor(
    private dialogRef: MatDialogRef<PrivateOrganisationLoginDialogComponent, PrivateOrganisationLoginResponse>,
    private organisationAccessDataProvider: OrganisationAccessControlDataProvider,
    private messagingService: OnscreenMessagingService
  ) {}

  formGroup = new UntypedFormGroup({
    password: new UntypedFormControl('', [Validators.required])
  });

  login() {
    this.organisationAccessDataProvider.validatePrivate$({ password: this.formGroup.get('password').value }).subscribe((response) => {
      if (response.isValid) {
        this.dialogRef.close({ privateDetails: response.header, cancelLogin: false });
      } else {
        this.messagingService.warn('The database password was not recognised');
      }
    });
  }

  signOut() {
    this.dialogRef.close({ cancelLogin: true });
  }
}
