import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { JsonFormsAngularService, JsonFormsModule } from '@jsonforms/angular';
import { JsonFormsAngularMaterialModule } from '@jsonforms/angular-material';
import { OnscreenMessagingModule } from '@pattern-library/onscreen-messaging/onscreen-messaging.module';
import { DesignSystemModule } from '@design/design-system.module';
import { MaterialModule } from '@pattern-library/material.module';
import { JsonSchemaFormComponent } from './json-schema-form.component';
import { EditRecordStepComponent } from './edit-stepper/edit-record-step.component';
import { FormSideNavigationComponent } from './form-side-navigation/form-side-navigation.component';
import { JsonFormsTextComponent } from './form-controls/text/json-forms-text.component';
import { JsonFormsDateComponent } from './form-controls/date/json-forms-date.component';
import { JsonFormsSelectComponent } from './form-controls/select/json-forms-select.component';
import { JsonFormsNumberComponent } from './form-controls/number/json-forms-number.component';
import { JsonFormsViewComponent } from './form-controls/view/json-forms-view.component';
import { HorizontalLayoutComponent } from './layouts/horizontal-layout/horizontal-layout.component';
import { VerticalLayoutComponent } from './layouts/vertical-layout/vertical-layout.component';
import { NewRecordStepComponent } from './new-record-wizard/new-record-step.component';
import { ErrorsComponent } from './errors/errors.component';
import { BlankComponent } from './blank/blank.component';
import { RecordViewComponent } from './record-view/record-view.component';
import { JsonFormsSlideToggleComponent } from './form-controls/slide-toggle/json-forms-slide-toggle.component';
import { JsonFormsArrayComponent } from './form-controls/array/json-forms-array.component';
import { JsonFormsSwitchComponent } from './form-controls/switch/json-forms-switch.component';
import { GroupLayoutComponent } from '@json-schema-form/layouts/group-layout/group-layout.component';
import { JsonFormsLabelComponent } from '@json-schema-form/form-controls/label/json-forms-label.component';
import { PopoverBuilderComponent } from './form-controls/popover-builder/popover-builder.component';
import { JsonFormsDateViewComponent } from '@json-schema-form/form-controls/date-view/json-forms-date-view.component';
import { StepperModule } from '@stepper';
import { JsonFormModalComponent } from '@json-schema-form/form-modal/json-form-modal.component';
import { DividerLayoutComponent } from './layouts/divider-layout/divider-layout.component';
import { JsonFormsCheckboxSelectComponent } from '@json-schema-form/form-controls/checkbox-select/json-forms-checkbox-select.component';
import { BulletPointsLayoutComponent } from '@json-schema-form/layouts/bullet-points-layout/bullet-points-layout.component';
import { JsonFormsCheckboxComponent } from '@json-schema-form/form-controls/checkbox/json-forms-checkbox.component';
import { PlaceholderLayoutComponent } from '@json-schema-form/layouts/placeholder-layout/placeholder-layout.component';
import { FormGuidanceTextComponent } from './form-controls/shared/form-guidance-text/form-guidance-text.component';
import { InformationBoxComponent } from './form-controls/shared/information-box/information-box.component';
import { DesignSystemFormsModule } from '@design/forms/design-system-forms.module';
import { JsonFormsAutoCompleteComponent } from './form-controls/auto-complete/json-forms-auto-complete.component';
import { JsonFormsDecimalComponent } from '@json-schema-form/form-controls/decimal/json-forms-decimal.component';
import { JsonFormsLookupLabelComponent } from '@json-schema-form/form-controls/lookup-label/json-forms-lookup-label.component';
import { DynamicModule } from 'ng-dynamic-component';
import { JsonFormsLeaderComponent } from '@json-schema-form/form-controls/leader/json-forms-leader.component';
import { FormModalActivationComponent } from '@design/modals/form-modal/form-modal-activation.component';
import { JsonFormsDependantSelectComponent } from '@json-schema-form/form-controls/dependant-select/json-forms-dependant-select.component';
import { JsonFormsRecordPropertyViewComponent } from '@json-schema-form/form-controls/property-view/json-forms-record-property-view.component';
import { JsonFormsPositionsArrayViewComponent } from '@app/employees/new-starters/new-starter-wizard/positions/positions-array-view/json-forms-positions-array-view.component';
import { JsonFormsSearchSelectComponent } from '@json-schema-form/form-controls/search-select/json-forms-search-select.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { JsonFormsNumberSuffixComponent } from '@json-schema-form/form-controls/suffix-text/json-forms-number-suffix.component';
import { JsonFormsLoaderComponent } from '@json-schema-form/form-controls/loader/json-forms-loader.component';
import { LayoutChildrenRenderPropsPipe } from '@json-schema-form/layouts/layout-children-render-props.pipe';
import { JsonFormsTitleComponent } from '@json-schema-form/form-controls/form-title/json-forms-title.component';

@NgModule({
  declarations: [
    JsonSchemaFormComponent,
    EditRecordStepComponent,
    NewRecordStepComponent,
    FormSideNavigationComponent,
    JsonFormsSelectComponent,
    JsonFormsTextComponent,
    JsonFormsNumberComponent,
    JsonFormsDateComponent,
    JsonFormsSlideToggleComponent,
    JsonFormsViewComponent,
    JsonFormsArrayComponent,
    JsonFormsAutoCompleteComponent,
    HorizontalLayoutComponent,
    VerticalLayoutComponent,
    ErrorsComponent,
    BlankComponent,
    RecordViewComponent,
    JsonFormsSwitchComponent,
    JsonFormsLabelComponent,
    GroupLayoutComponent,
    PopoverBuilderComponent,
    JsonFormsDateViewComponent,
    JsonFormModalComponent,
    DividerLayoutComponent,
    JsonFormsCheckboxSelectComponent,
    BulletPointsLayoutComponent,
    JsonFormsCheckboxComponent,
    PlaceholderLayoutComponent,
    FormGuidanceTextComponent,
    InformationBoxComponent,
    JsonFormsDecimalComponent,
    JsonFormsLookupLabelComponent,
    JsonFormsDependantSelectComponent,
    JsonFormsLeaderComponent,
    JsonFormsLoaderComponent,
    FormModalActivationComponent,
    JsonFormsRecordPropertyViewComponent,
    JsonFormsPositionsArrayViewComponent,
    JsonFormsSearchSelectComponent,
    JsonFormsNumberSuffixComponent,
    LayoutChildrenRenderPropsPipe,
    JsonFormsTitleComponent
  ],
  providers: [JsonFormsAngularService],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    OnscreenMessagingModule,
    DesignSystemModule,
    MaterialModule,
    JsonFormsModule,
    JsonFormsAngularMaterialModule,
    StepperModule,
    DesignSystemFormsModule,
    DynamicModule,
    NgxMatSelectSearchModule
  ],
  exports: [
    EditRecordStepComponent,
    NewRecordStepComponent,
    JsonSchemaFormComponent,
    RecordViewComponent,
    ErrorsComponent,
    JsonFormModalComponent,
    PopoverBuilderComponent,
    DividerLayoutComponent,
    JsonFormsSwitchComponent
  ]
})
export class JsonSchemaFormModule {}
