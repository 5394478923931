<div *ngIf="dataset">
  <mat-label>{{ label }}</mat-label>
  <mat-chip-listbox class="cintra" [multiple]="multiple" *ngIf="selectedItems$ | async as selectedItems">
    <mat-chip-option
      [ngClass]="{ 'popover-chip': this.helpTextFunction && !!this.helpTextFunction(item), chip: true }"
      *ngFor="let item of dataset.items"
      [value]="item"
      [selected]="contains(selectedItems, item)"
      [selectable]="this.isItemEnabled(item)"
      [disabled]="!this.isItemEnabled(item)"
      (selectionChange)="onSelectionChange($event)"
      [title]="dataset.getLabel(item)"
    >
      <span class="chip-label"
        >{{ dataset.getLabel(item) }}
        <app-popover
          matIcon="info_outline"
          [onHover]="true"
          [preferDisplayAbove]="true"
          *ngIf="this.helpTextFunction && !!this.helpTextFunction(item)"
          (click)="$event.stopPropagation()"
        >
          <div style="padding: 1rem" class="popover-content">
            <app-body-sm>
              <p [innerHTML]="this.helpTextFunction(item)"></p>
            </app-body-sm>
          </div>
        </app-popover>
      </span>
    </mat-chip-option>
  </mat-chip-listbox>
</div>
