import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MaterialModule } from '@pattern-library/material.module';
import { InputComponent } from './input/input.component';
import { DatePickerComponent } from './date/date-picker.component';
import { SearchInputComponent } from './search-input/search-input.component';
import { ToggleComponent } from './toggle/toggle.component';
import { DesignSystemModule } from '@design/design-system.module';
import { DatasetSelectComponent } from './dataset-select/dataset-select.component';
import { DatasetChipsComponent } from './dataset-chips/dataset-chips.component';
import { DatasetSelectChipsComponent } from './dataset-select-chips/dataset-select-chips.component';
import { GroupedDatasetSelectComponent } from './grouped-dataset-select/grouped-dataset-select.component';
import { EntitySelectComponent } from './entity-select/entity-select.component';
import { LocalFileSelectorComponent } from './local-file-selector/local-file-selector.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { AutoFocusDirective } from './app-auto-focus.directive';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { DatasetSearchAheadComponent } from './dataset-search/dataset-search-ahead.component';
import { DatasetCheckboxesComponent } from './dataset-checkboxes/dataset-checkboxes.component';
import { DatasetButtonsComponent } from './dataset-buttons/dataset-buttons.component';
import { SwitchComponent } from '@design/forms/switch/switch.component';

const declarations = [
  InputComponent,
  DatePickerComponent,
  SearchInputComponent,
  ToggleComponent,
  DatasetSelectComponent,
  DatasetChipsComponent,
  DatasetSelectChipsComponent,
  GroupedDatasetSelectComponent,
  EntitySelectComponent,
  LocalFileSelectorComponent,
  AutoFocusDirective,
  DatasetSearchAheadComponent,
  DatasetCheckboxesComponent,
  DatasetButtonsComponent,
  SwitchComponent
];

@NgModule({
  declarations,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxFileDropModule,
    MaterialModule,
    DesignSystemModule,
    NgxMatSelectSearchModule
  ],
  exports: [...declarations]
})
export class DesignSystemFormsModule {}
