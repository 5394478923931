import { ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractDatasetComponent } from '../abstract-dataset.component';

@Component({
  selector: 'app-dataset-buttons',
  templateUrl: './dataset-buttons.component.html',
  styleUrls: ['./dataset-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatasetButtonsComponent),
      multi: true
    }
  ]
})
export class DatasetButtonsComponent<TItem, TValue> extends AbstractDatasetComponent<TItem, TValue> {
  multiple = false;

  @Input() showTickIcon = false;
  @Output() selectionChanged = new EventEmitter<any>();

  getTickIcon = () => (this.showTickIcon ? 'check_circle_outline' : undefined);

  onSelectionChange(selectedValue: any) {
    this.selectionChanged.emit(selectedValue);
  }
}
