import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { LoggingService } from '@logging/logging.service';
import { environment } from '@env/environment';
import { EmployerPayrolls } from './employer-payrolls';
import { AbstractEntityCollectionDataProvider } from '@entity-framework/entity-collection/collection-data-provider/abstract-entity-collection.data-provider';

@Injectable()
export class OrganisationPayrollsDataProvider extends AbstractEntityCollectionDataProvider<EmployerPayrolls> {
  protected apiRoot = `${environment.apiPaths.employers}/payrolls`;
  constructor(protected httpClient: HttpClient, protected logger: LoggingService) {
    super(httpClient, logger);
  }

  public setParentPath(parentId: string) {
    this.parentPath = parentId;
  }
}
