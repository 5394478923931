import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-info-tile',
  templateUrl: './info-tile.component.html',
  styleUrls: ['./_info-tile.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoTileComponent {
  @Input() routeTo: string;
}
