import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ActionConfig } from '@design/buttons/action-config';

@Component({
  selector: 'app-action-buttons',
  templateUrl: './action-buttons.component.html',
  styleUrls: ['./action-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionButtonsComponent {
  _actions: ActionConfig[] = [];
  @Input() set actions(actions: ActionConfig[]) {
    this._actions = actions;
  }

  get actions(): ActionConfig[] {
    return this._actions;
  }

  constructor() {}
}
