import { Component } from '@angular/core';

@Component({
  selector: 'app-fullscreen-modal',
  templateUrl: './fullscreen-modal.component.html',
  styleUrls: ['./fullscreen-modal.component.scss']
})
export class FullscreenModalComponent {
  constructor() {}
}
