import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BehaviorSubject, map } from 'rxjs';
import { AbstractCalendarEventsComponent } from '../../abstract-calendar-events.component';
import { MonthCalendarComponentStateService } from '../../state/month-calendar-component-state.service';
import { PersonCalendarEvents } from '../../state/team-calendar';
import { CalendarDayEvents, CalendarMonthOfDayEvents } from '../../state/calendar';
import { HorizontalCalendarComponentStateService } from '../../state/horizontal-calendar-component.state-service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[app-person-events-month-row]',
  templateUrl: './person-events-month-row.component.html',
  styleUrls: ['./person-events-month-row.component.scss'],
  providers: [{ provide: MonthCalendarComponentStateService, useClass: HorizontalCalendarComponentStateService }],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PersonEventsMonthRowComponent extends AbstractCalendarEventsComponent {
  calendarMonthDays$ = <BehaviorSubject<CalendarMonthOfDayEvents>>this.componentStateService.calendarMonthEvents$;

  @Input() interactive = true;
  @Input() rowSelected = false;
  personCalendarEvents: PersonCalendarEvents;

  @Input() set personEvents(personCalendarEvents: PersonCalendarEvents) {
    this.personCalendarEvents = personCalendarEvents;
    // nonWorkingDays need to be take precedence here, as leave might span them

    this.eventModels = []
      .concat(personCalendarEvents.nonWorkingDays)
      .concat(personCalendarEvents.requests)
      .concat(personCalendarEvents.sickDays ?? [])
      .concat(personCalendarEvents.approvedLeave ?? [])
      .concat(personCalendarEvents.approvedAbsence ?? [])
      .concat(personCalendarEvents.approvedHoliday ?? [])
      .concat(personCalendarEvents.unapprovedAbsence ?? []);
  }

  getPersonName = () => `${this.personCalendarEvents.forename} ${this.personCalendarEvents.surname}`;

  getDayCss(day: CalendarDayEvents | null): Record<string, boolean> {
    const classes = super.getDayCss(day);
    classes['non-interactive'] = !this.interactive;
    classes['row-selected'] = this.rowSelected;
    return classes;
  }

  getData = (day: any) => {
    day.employeeId = this.personCalendarEvents.employeeId;
    return day;
  };
}
