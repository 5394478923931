import { Injectable } from '@angular/core';
import { createStore, select, setProps, withProps } from '@ngneat/elf';
import { DateTime } from 'luxon';
import { CalendarViewMode } from './calendar';
import { distinctUntilChanged } from 'rxjs/operators';

export interface CalendarNavigatorState {
  calendarMode: CalendarViewMode;
  activeDate: DateTime;
  lockToCurrentYear: boolean;
  nextDisabled: boolean;
  previousDisabled: boolean;
}

@Injectable()
export class CalendarNavigatorStateService {
  private today = DateTime.utc().startOf('day');
  private startMonth: number = 1;

  private readonly store = createStore(
    { name: 'CalendarNavigatorStateStore' },
    withProps<CalendarNavigatorState>({
      calendarMode: 'month',
      activeDate: this.today,
      lockToCurrentYear: true,
      nextDisabled: true,
      previousDisabled: true
    })
  );

  state$ = this.store;
  activeDate$ = this.state$.pipe(select((state) => state.activeDate));
  year$ = this.activeDate$.pipe(
    select((date) => date.year),
    distinctUntilChanged()
  );

  constructor() {
    this.setActiveDate(this.today);
  }

  doNotLockToCurrentYear = () => {
    const lockToCurrentYear = false;
    const nextDisabled = false;
    const previousDisabled = false;
    this.store.update(setProps({ lockToCurrentYear, previousDisabled, nextDisabled }));
  };

  getActiveDate = (): DateTime => {
    return this.store.getValue().activeDate;
  };

  setActiveDate = (activeDate: DateTime) => {
    const lockToCurrentYear = this.store.getValue().lockToCurrentYear;
    const previousDisabled = activeDate.month === this.startMonth && lockToCurrentYear;
    const nextDisabled = activeDate.month === 12 && lockToCurrentYear;

    this.store.update(setProps({ activeDate, previousDisabled, nextDisabled }));
  };

  get calendarMode(): CalendarViewMode {
    return this.store.getValue().calendarMode;
  }

  set calendarMode(calendarView: CalendarViewMode) {
    let previousView = this.store.getValue().calendarMode;
    let activeDate = this.store.getValue().activeDate;

    if (previousView === 'year') activeDate = DateTime.utc().set({ day: 1, month: this.today.month, year: activeDate.year });

    let nextDisabled = activeDate.month === 12 && this.store.getValue().lockToCurrentYear;

    this.store.update(setProps({ calendarMode: calendarView, activeDate, nextDisabled }));
  }

  incrementActiveDateMonth = (increment: number) => {
    const state = this.store.getValue();
    this.setActiveDate(state.activeDate.plus({ months: increment }));
  };

  updateActiveDate = (month: number, year: number) => {
    const state = this.store.getValue();
    this.setActiveDate(state.activeDate.set({ month, year }));
  };

  updateStartMonth = (startMonth: number) => {
    this.startMonth = startMonth;
  };
}
