<ng-container *ngIf="dataset">
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <div class="button-list">
    <ng-container *ngFor="let item of dataset.items">
      <app-button
        *ngIf="isSelected(item)"
        class="selected"
        theme="primary-action"
        buttonWidth="medium"
        [uppercaseText]="false"
        iconLeft="true"
        [icon]="getTickIcon()"
        [buttonText]="dataset.getLabel(item)"
        (clickEvent)="onSelectionChange(item)"
        >{{ dataset.getLabel(item) }}</app-button
      >
      <app-button
        *ngIf="!isSelected(item)"
        theme="button-list"
        buttonWidth="medium"
        [uppercaseText]="false"
        (click)="selectItem(item)"
        (clickEvent)="onSelectionChange(item)"
      >
        {{ dataset.getLabel(item) }}
      </app-button>
    </ng-container>
  </div>
</ng-container>
