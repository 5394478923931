import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared.module';
import { EmployerPayrollSelectComponent } from '../employer-payroll-selector/employer-payroll-select.component';
import { CalcInProgressModalComponent } from './recalculation/calc-in-progress-modal/calc-in-progress-modal.component';
import { RecalculationBannerComponent } from './recalculation/recalculation-banner/recalculation-banner.component';
import { HandoverModalConfirmationTemplateComponent } from '../payroll-handover/modal-template/handover-modal-confirmation-template.component';
import { PayrollPeriodComponent } from './payroll-period/payroll-period.component';
import { PayrollPeriodSelectorComponent } from './payroll-period/selector/payroll-period-selector.component';

const declarations = [
  EmployerPayrollSelectComponent,
  HandoverModalConfirmationTemplateComponent,
  RecalculationBannerComponent,
  CalcInProgressModalComponent,
  PayrollPeriodComponent,
  PayrollPeriodSelectorComponent
];

@NgModule({
  declarations,
  imports: [SharedModule],
  exports: [...declarations]
})
export class PayrollSharedModule {}
