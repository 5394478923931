import { ControlValueAccessor, RequiredValidator } from '@angular/forms';
import { Directive, Optional, Self } from '@angular/core';

import { LoggingService } from '@logging/logging.service';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class AbstractControlValueAccessor<TValue> implements ControlValueAccessor {
  disabled = false;
  value?: TValue;

  constructor(protected logger: LoggingService, @Self() @Optional() protected requiredValidator: RequiredValidator) {}

  /**
   * Call when value has changed programmatically
   */
  onChange(newVal: TValue) {}
  onTouched(_?: any) {}
  /**
   * Model -> View changes
   */
  writeValue(value: TValue): void {
    this.value = value;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  get required(): boolean | string {
    return this.requiredValidator ? this.requiredValidator.required : false;
  }
}
