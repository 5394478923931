import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AuthenticationService } from '@auth-n/authentication.service';

@Component({
  templateUrl: './no-permissions.component.html',
  styleUrls: ['./no-permissions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NoPermissionsComponent {
  constructor(public authenticationService: AuthenticationService) {}
}
