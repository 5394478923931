<div xmlns="http://www.w3.org/1999/html">
  <mat-icon>lock</mat-icon>
  <p>
    Your account has no permissions to access Cintra Cloud.
    <br />
    Please contact your local Cintra Cloud administrator and request a valid role.
    <br />
    If the problem persists please email
    <a href="mailto:support@cintra.co.uk">support&#64;cintra.co.uk</a>
    with a description of the problem.
  </p>
  <br />
  <p>
    <a href="/" (click)="this.authenticationService.signOutAndRedirect()">Sign out</a>
  </p>
</div>
