<ng-container *ngFor="let value of values; index as index; count as count">
  <ng-container *ngIf="index < maxItems">
    <span>{{ value }}</span>
    <span *ngIf="index + 1 < count">,&nbsp;</span>
    <app-popover
      *ngIf="count > maxItems && index === maxItems - 1"
      linkText="+ {{ count - maxItems }} more"
      [onHover]="true"
      [preferDisplayAbove]="true"
    >
      <ul>
        <li *ngFor="let value of values; index as index; count as count">
          {{ value }}
        </li>
      </ul>
    </app-popover>
  </ng-container>
</ng-container>
