import { EventEmitter, inject } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { HotjarService } from '@analytics';
import { OnscreenMessagingService } from '@pattern-library/onscreen-messaging/onscreen-messaging.service';
import { NonStandardModalOptions } from './non-standard-modal/non-standard-modal-options';
import { ModalOptions } from './modal-options';
import { LoggingService } from '@logging/logging.service';

export abstract class AbstractModalService {
  protected dialog = inject(MatDialog);
  protected router = inject(Router);
  private messaging = inject(OnscreenMessagingService);
  private hotjarService = inject(HotjarService);

  protected modalOptions: ModalOptions | NonStandardModalOptions;
  protected dialogRef?: MatDialogRef<any>;
  protected secondaryDialogRef?: MatDialogRef<any>;
  protected tertiaryDialogRef?: MatDialogRef<any>;
  protected selectorDialogRef?: MatDialogRef<any>;
  protected messageDialogRef!: MatDialogRef<any>;

  private logger = inject(LoggingService);

  dialogClosed$ = new EventEmitter();

  protected setDialogRef(dialogRef: MatDialogRef<any>) {
    if (this.modalOptions.modalLevel === 'Secondary') {
      this.secondaryDialogRef = dialogRef;
    } else if (this.modalOptions.modalLevel === 'Tertiary') {
      this.tertiaryDialogRef = dialogRef;
    } else if (this.modalOptions.modalLevel === 'Selector') {
      this.selectorDialogRef = dialogRef;
    } else {
      this.dialogRef = dialogRef;
    }

    this.logger.trace('AbstractModalService - New dialog: ', this.modalOptions.modalLevel, dialogRef);

    if (this.modalOptions.allowKeyboardClose) {
      // subscribed to by the FormModalTemplateComponent to ensure close events are wired up when closing by keyboard (ESC)
      // very specifically the minimum required is that modal router outlets are removed
      const sub = dialogRef.beforeClosed().subscribe((evt) => {
        sub.unsubscribe();
        this.dialogClosed$.emit();
      });
    }
  }

  closeModal() {
    this.router.navigate(['', { outlets: { modal: null, selector: null } }], { queryParamsHandling: 'preserve' }).then();
    this.dialogRef.close();
  }

  closeSecondaryModal() {
    this.router.navigate(['', { outlets: { secondaryModal: null } }], { queryParamsHandling: 'preserve' }).then();
    this.secondaryDialogRef.close();
  }

  closeTertiaryModal() {
    this.router.navigate(['', { outlets: { tertiaryModal: null } }], { queryParamsHandling: 'preserve' }).then();
    this.tertiaryDialogRef.close();
  }

  closeSelectorModal() {
    this.router.navigate(['', { outlets: { selector: null } }], { queryParamsHandling: 'preserve' }).then();
    this.selectorDialogRef.close();
  }

  closeAllModals() {
    this.router.navigate(['', { outlets: { modal: null, message: null, selector: null } }], { queryParamsHandling: 'preserve' }).then();
    this.dialog.closeAll();
  }

  tryCloseModal(recordDirty: boolean, onCloseFunction?: any, closeEvent?: string) {
    if (recordDirty) {
      this.messaging.confirm('Are you sure you want to exit and lose your changes?', () => {
        if (closeEvent !== undefined && closeEvent !== '') {
          this.hotjarService.createEvent(closeEvent);
        }
        this.closeModal();
        if (onCloseFunction) {
          onCloseFunction();
        }
      });
    } else {
      if (onCloseFunction) {
        onCloseFunction();
      }
      this.closeModal();
    }
  }

  tryCloseSecondaryModal(recordDirty: boolean, onCloseFunction?: any, closeEvent?: string) {
    if (recordDirty) {
      this.messaging.confirm('Are you sure you want to exit and lose your changes?', () => {
        if (closeEvent !== undefined && closeEvent !== '') {
          this.hotjarService.createEvent(closeEvent);
        }
        this.closeSecondaryModal();
        if (onCloseFunction) {
          onCloseFunction();
        }
      });
    } else {
      if (onCloseFunction) {
        onCloseFunction();
      }
      this.closeSecondaryModal();
    }
  }

  tryCloseTertiaryModal(recordDirty: boolean, onCloseFunction?: any, closeEvent?: string) {
    if (recordDirty) {
      this.messaging.confirm('Are you sure you want to exit and lose your changes?', () => {
        if (closeEvent !== undefined && closeEvent !== '') {
          this.hotjarService.createEvent(closeEvent);
        }
        this.closeTertiaryModal();
        if (onCloseFunction) {
          onCloseFunction();
        }
      });
    } else {
      if (onCloseFunction) {
        onCloseFunction();
      }
      this.closeTertiaryModal();
    }
  }
}
