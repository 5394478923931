import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../../environments/environment';
import { AbstractEntityDataProvider } from '@entity-framework/entity-record/entity-data-provider/abstract-entity.data-provider';
import { LoggingService } from '@logging/logging.service';
import { MyAccountModel } from './my-account.model';

@Injectable()
export class MyAccountDataProvider extends AbstractEntityDataProvider<MyAccountModel> {
  protected apiRoot = `${environment.apiPaths.myAccount}`;

  constructor(protected httpClient: HttpClient, protected logger: LoggingService) {
    super(httpClient, logger);
  }

  public setParentPath(parentId?: any) {
    this.parentPath = parentId;
  }
}
