import { Input, Optional, Self, OnInit, Directive, ElementRef } from '@angular/core';
import { ControlValueAccessor, EmailValidator, UntypedFormControl, NgControl, RequiredValidator, ValidatorFn } from '@angular/forms';

import { LoggingService } from '@logging/logging.service';
import { toKebabCase } from '@utils/string-utils';

/**
 * extended by custom ui-components so that they can be part of Reactive Forms model binding and validation
 * a custom reactive form control is required to implement ControlValueAccessor which here is a loop-back to the original FormControl
 */
@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class AbstractFormFieldComponent implements ControlValueAccessor, OnInit {
  private value: any;
  private _formControl = new UntypedFormControl();
  protected otherValidators: ValidatorFn[] = [];

  @Input() tid = '';
  @Input() label = '';
  @Input() placeholder?: string;

  onChange = (value: any) => {};

  constructor(
    private elementRef: ElementRef,
    protected logger: LoggingService,
    @Self() @Optional() private ngControl: NgControl,
    @Self() @Optional() private requiredValidator?: RequiredValidator,
    @Self() @Optional() private emailValidator?: EmailValidator
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    if (!this.placeholder) this.placeholder = `Enter ${this.label.toLowerCase()} here`;

    if (this.ngControl) {
      /**
       * get a handle on the FormControl that was created in the last Reactive FormGroup in the component injection hierarchy
       * so that it can be bound to the input in our Custom Component
       * this ensures input value binding to model + explicit validation is bound
       * e.g. new FormGroup({ titleId: new FormControl(personalDetails.titleId, Validators.required) } =>
       *    <input [formControl]="this.formControl"
       * otherwise you will have to do that manually for evey single control on every single form
       * which is obviously a lot of repeating yourself
       */

      this.formControl = this.ngControl.control as UntypedFormControl;

      if (this.otherValidators.length) {
        const validators = [...this.otherValidators];
        if (this.formControl.validator) validators.push(this.formControl.validator);
        this.formControl.setValidators(validators);
      }
      this.logger.trace(
        'Custom FormControl (AbstractFormFieldComponent): Binding to Reactive Form',
        this.ngControl,
        this.ngControl.control
      );
    }
    this.createIdentificationAttribute();
  }

  createIdentificationAttribute() {
    const id = toKebabCase(this.tid);
    this.elementRef.nativeElement.setAttributeNode(document.createAttribute(`data-tid-${id}-field`));
  }

  get formControl(): UntypedFormControl {
    return this._formControl;
  }
  set formControl(formControl: UntypedFormControl) {
    this._formControl = formControl;
  }

  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: (value: any) => void): void {}

  writeValue(value: any): void {
    this.value = value;
  }

  get required(): boolean | string {
    return this.requiredValidator ? this.requiredValidator.required : false;
  }

  get email(): boolean | string {
    return this.emailValidator ? this.emailValidator.email : false;
  }
}
