import { Injectable } from '@angular/core';
import { NavigationEnd, Router, Scroll } from '@angular/router';
import { filter, map, switchMap, tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NavigationRoutingService {
  navigationEvents$ = this.router.events.pipe(
    map((evt) => {
      if (evt instanceof NavigationEnd) return evt;
      else if (evt instanceof Scroll && (evt as Scroll).routerEvent instanceof NavigationEnd) return (evt as Scroll).routerEvent; // seems to be the case on a full browser load
      return undefined;
    }),
    filter(Boolean)
  );

  constructor(private router: Router) {}

  getRouteData = () => {
    let route = this.router.routerState.root;
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route.snapshot.data;
  };
}
