import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

@Component({
  selector: 'app-count-status-button',
  templateUrl: './count-status-button.html',
  styleUrls: ['./count-status-button.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CountStatusButtonComponent {
  @Output() clickWhenEnabled = new EventEmitter<void>();
  @Input() text: string;
  @Input() number: number;
  @Input() numberColor: 'system-green' | 'system-red' | 'ink-45';
  @Input() tooltipItems: string[] = [];
  @Input() disabled: boolean = false;

  @HostBinding('class.disabled') private get isDisabled() {
    return this.disabled;
  }

  get ngClass(): string[] {
    return [this.numberColor];
  }

  onClick() {
    if (!this.disabled) {
      this.clickWhenEnabled.emit();
    }
  }
}
