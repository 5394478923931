import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CintraThemePalette } from '@design/theme/cintra-theme-palette';

/**
 * Container-component with a max-width of 1140px
 */
@Component({
  selector: 'app-workarea',
  templateUrl: './workarea.component.html',
  styleUrls: ['./workarea.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkareaComponent {
  @Input() showStaticHeader = false;
  @Input() hideTop?: boolean;
  @Input() hideBottom?: boolean;
  @Input() noTopPadBottom?: boolean;
  @Input() noTopPadTop?: boolean;
  @Input() colspan = 12;
  @Input() bottomPaddingTop = '3.2rem';
  @Input() topBackground: CintraThemePalette = 'ink-02';
  @Input() fillTop?: boolean;

  @Output() scrollEvent = new EventEmitter<any>();

  cols(): string {
    switch (this.colspan) {
      case 10:
        return 'col-md-10 offset-md-1';
      default:
        return 'col';
    }
  }

  onScroll(event: any) {
    this.scrollEvent.emit(event);
  }

  get topNgClass(): string[] {
    return [
      `top-bg-${this.topBackground}`,
      this.noTopPadBottom ? 'no-top-pad-bottom' : '',
      this.noTopPadTop ? 'no-top-pad-top' : '',
      this.fillTop !== undefined ? 'fill-top' : ''
    ];
  }
}
