import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs';
import { TopNavigationLink } from '../navigation-bar';
import { ActiveFeatureService } from '@app/active-feature.service';

@Component({
  selector: 'app-feature-menu-item',
  templateUrl: './feature-menu-item.component.html',
  styleUrls: ['./feature-menu-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeatureMenuItemComponent {
  isActiveRoute$ = this.activeFeatureService.activeFeature$.pipe(map((f) => this.featureLink.feature === f));
  sectionMenuActive = false;
  @Input() featureLink: TopNavigationLink;

  constructor(private router: Router, private activeFeatureService: ActiveFeatureService) {}

  navigateFeature = () => {
    if (this.featureLink.sectionLinks?.length) return;

    if (this.featureLink.execute) {
      this.featureLink.execute();
      return;
    }
    this.router.navigateByUrl(this.featureLink.route).then();
  };

  navigateSection = (link: string) => this.router.navigateByUrl(link).then();
}
