import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-progress-bar-overlay',
  templateUrl: './progress-bar-overlay.component.html',
  styleUrls: ['./progress-bar-overlay.component.scss']
})
export class ProgressBarOverlayComponent {
  @Input() inFlight = false;
}
